.startNavbar {
  width: 100%;
  height: auto;
  padding: 0 50px;
  padding-top: 5px;
}

.startContainerLogin {
  width: 100%;
  padding: 0 50px;
}

.startTypes {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

/* Log music section */

.logMusicContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.logMusicFirst {
  width: 50%;
}

.logMusicFirst > div {
  margin-bottom: 20px;
}

.logMusicSecond {
  width: 50%;
}

.logMusicForm {
  margin-left: auto;
}

.loginFormContainer {
  margin: 0;
}

.logMusicCheckbox {
  position: absolute;
  top: 0;
  left: 0;
}

.btnDisable {
  background-color: #ccc !important;
}

@media screen and (max-width: 1100px) {
  .logMusicContainer {
    flex-direction: column;
  }

  .logMusicSecond {
    margin-top: 40px;
  }

  .logMusicFirst,
  .logMusicSecond {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .logMusicForm {
    margin-left: 0;
  }
}

@media screen and (max-width: 800px) {
  .startContainerLogin,
  .startNavbar,
  .logMusicFirst,
  .logMusicSecond {
    padding: 0 20px;
  }

  .startClose {
    position: absolute;
    top: 25px;
    right: 10px;
  }

  .startHeader {
    font-size: 34px !important;
    line-height: 50px !important;
  }

  .startSubHeader {
    font-size: 26px !important;
  }

  .logMusicFirst h2 {
    font-size: 24px !important;
    line-height: 34px;
  }

  .logMusicFirst {
    padding: 20px;
  }

  .logMusicForm {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 576px) {
  .logMusicForm {
    width: 100%;
    height: auto;
    padding: 30px 10px;
  }

  .loginFormContainer,
  .loginFormContainer > form {
    width: 100%;
    margin: 0;
    padding: 20px;
  }

  .loginFormBar {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .loginFormItem {
    width: 100%;
    margin: 0;
  }

  .loginFormItem:first-child {
    margin-bottom: 20px;
  }

  .loginFormItem > input,
  .loginFormItem > select,
  .loginFormItem > .input,
  div.loginFormItem input {
    width: calc(100% - 15px) !important;
  }
}