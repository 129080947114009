.searchbar{
    width: 100%;
    min-width: 13rem;
    border-radius: 1.5rem;
    background: #FFFFFF1F;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}
.searchbar input{
    width: 95%;
    margin: 0 auto!important;
    height: 100%;
    padding: 0 2rem!important;
    border: none!important;
    border-radius: 1.5rem;
    color: gray;
} 
.searchbar input:focus{
    box-shadow: none!important;
    -webkit-box-shadow:none!important;
} 
.searchbar input::placeholder{
    font-size: 1rem;
    font-weight: 500;
    text-align: left;
    color: #FFFFFFA3;
} 

.searchicon{
    width: 1.5rem;
    height: 1.5rem;
    color: #FFFFFFA3;
    position: absolute;
    right: 1.5rem
}