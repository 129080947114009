.file-download-card{
    width: 100%;
    padding: 0.8rem;
    border-radius: 1rem ;
    background: #1D1D1D;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.file-download-card-info{
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.file-download-card-icoo{
    display: flex;
    gap: 0.5rem;
}

.file-download-card-ico{
    width: 3.2rem;
}

.file-download-card-text{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-content: center;
    align-items: start;
}

.file-download-card-titles{
    margin-top: 10px;
    width: 90%;
}

.file-download-card-hr{
    height: 0.15rem;
    border: none;
    background: gray;
}

.file-download-card-title{
    font-weight: 600;
    font-size: 0.9rem;
    color: white;
    
}

.file-download-card-titlee{
    font-weight: 600;
    font-size: 0.7rem;
    color: gray;
}

.file-download-card-titles-2{
    display: flex;
    justify-content: start;
    align-content: center;
    align-items: center;
    gap: 0.5rem;
}

.file-download-card-titlees-2{
    display: flex;
    gap: 0.2rem;
}

.file-download-card-title-2{
    font-weight: 600;
    font-size: 0.9rem;
    color: gray;
}

.file-download-card-titlee-2{
    font-weight: 600;
    font-size: 0.9rem;
    color: gray;
}

.file-download-card-dot{
    width: 0.4rem;
}

.file-download-card-title-3{
    font-weight: 600;
    font-size: 0.8rem;
    color: white;
}

.file-download-card-closee{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
}

.file-download-card-close{
    width: 1rem;
}

.file-download-card-bar{
    width: 100%;
    height: 0.3rem;
}

.file-download-card-bar-1{
    height: 0.2rem;
    border-radius: 0.5rem;
    background: #8E3A1F;
}
.file-download-card-bar-3{
    height: 0.2rem;
    border-radius: 0.5rem;
    background: #31bf50;
}
.file-download-card-bar-4{
    height: 0.2rem;
    border-radius: 0.5rem;
    background: #f6212c;
}

.file-download-card-bar-2{
    width: 100%;
    height: 0.2rem;
    border-radius: 0.5rem;
    background: #FF5620;
}

