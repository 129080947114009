.custominput-container,
.custominput-container:disabled {
    width: 100%;
    display: flex;
    gap: 0.2rem;
    border: none;
    align-content: center;
    align-items: center;
}

.custominput-label,
.custominput-label:disabled {
    color: gray;
    font-size: 1.1rem;
}

.custominput-required,
.custominput-required:disabled {
    color: white;
}

.custominput-field-container,
.custominput-field-container:disabled {
    margin-left: 0.5rem;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.custominput-field,
.custominput-field:disabled {
    width: 100%;
    font-size: 16px;
    line-height: 27px;
    letter-spacing: 0em;
    font-weight: 300;
    text-align: left;
    color: #5A5953;
    text-decoration: none;
    border: none;
    border-bottom: 0.1px solid gray;
    outline: none;
    box-shadow: none !important;
    padding-bottom: -1rem;
}
