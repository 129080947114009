.container{
    width: 100%;
    padding-right: 10rem;
    padding-left: 10rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
}

.info{
    width: 100%;
    display: flex;
    gap: 1rem;
    align-items: center;
    padding-right: 1rem;
    padding-left: 1rem;
}

.input{
    width: 100%;
    border: 0.1rem solid #FB5828;
    padding: 0.5rem;
    border-radius: 0.5rem;
    font-size: 1.5rem;
    color: #FB5828;
}


.submit_button{
    background-color: transparent;
    border: 0.1rem solid #FB5828;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    color: #FB5828;
    border-radius: 0.5rem;
    padding: 0.5rem;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    cursor: pointer;
}


.msg{
    color: #28fb33;
    font-size: 1.5rem;
    text-align: center;
    font-weight: bold;
}