.services {
    width: 90%;
    margin: 25px 0 0 auto;
    padding: 100px 0;

    @media screen and (max-width: 990px) {
        width: 100%;
        background: url('../../assets/illustrations/services_bg.png') left/contain no-repeat;
    }
    section.texts {
        // background-color: green;
        width: 765px;
        height: 517px;
        @media screen and (max-width: 768px) {
            height: auto;
            width: 90%;
        }

        h2 {
            font-weight: 700;
            font-size: 48px;
            line-height: 60px;
            color: white;
            @media screen and (max-width: 768px) {
                text-align: center;
                font-size: 32px;
                line-height: 43.2px;
            }
        }

        p {
            font-weight: 400;
            font-size: 24px;
            line-height: 36px;
            color: rgba(255, 255, 255, 0.8);
            @media screen and (max-width: 768px) {
                text-align: center;
                margin-top: 20px;
                font-size: 20px;
                line-height: 30px;
            }

        }

        div.sub-texts {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 48px;
            margin-top: 48px;
            @media screen and (max-width: 768px) {
                text-align: center;
                grid-template-columns: 1fr;
                margin-top: 60px;
            }
            div {
                width: 309px;
                @media screen and (max-width: 768px) {
                    width: 70%;
                    margin: 0 auto;
                }

                h3 {
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 30px;
                    color: white;
                    @media screen and (max-width: 768px) {
                        font-size: 20px;
                        line-height: 25px;
                    }

                }

                p {
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 25px;
                    color: rgba(255, 255, 255, 0.64);
                    margin-top: 20px;
                    @media screen and (max-width: 768px) {
                        font-size: 16px;
                        line-height: 20px;
                    }
                }   
            }
        }
    }

    section.image {
        // background-color: green;
        width: 50%;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 10rem;

        @media screen and (max-width: 990px) {
            display: none;
        }
        img {
            object-fit: cover;
        }
    }
}