.selected-option {
    color: gray;
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.selected-option-placeholder{
    max-width: 70%;
    max-lines: 1 !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 0.5rem;
}

.options {
    list-style: none;
    padding: 0;
    margin: 0;
    position: absolute;
    background-color: #1D1D1D;
    border: 0.1rem solid gray;
    border-radius: 1rem;
    top: 100%;
    left: 0;
    right: 0;
    box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.1);
    display: none;
    opacity: 0;
    transition: opacity 0.3s ease;
    color: white;
    overflow: auto;
    z-index: 2;
}

.options.active {
    display: block;
    opacity: 1;
}

.options li {
    padding: 0.5rem;
    transition: background-color 0.3s ease;
}

.options li:hover {
    background-color: black;
}

.dropdown-icon{
    transition: transform 0.3s ease-in-out;
}

.rotate{
    transform: rotate(180deg);
    transition: transform 0.3s ease-in-out;
}