aside.left-bar-data-3 {
    width: 95%;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 24px;
    background-color: rgba(255, 255, 255, 0.12);
    color: white;
    margin: 10px auto;

    section {
        div {
            p.gray {
                color: rgba(255, 255, 255, 0.64);
            }
        }
    }
}