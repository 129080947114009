.exitmodal{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: #1F1F1FA1;
    gap: 3rem;
}

.exitmodal__text{
    font-size: 1rem;
    color: white;
}

.exitmodal__actions{
    width: 70%;
    display: flex;
    justify-content: center;
    padding-left: 1rem;
    padding-right: 1rem;
}

.exitmodal__actions__cancel{
    background-color: #260909;
    border: 0.1rem solid #FB5828;
    color: #FB5828;
    border-radius: 1rem;
    padding: 1rem;
    cursor: pointer;
}

.exitmodal__actions__logout{
    background-color: transparent;
    border: 0.1rem solid #FB5828;
    color: #FB5828;
    border-radius: 0.5rem;
    padding: 0.5rem;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    cursor: pointer;
}

.exitmodal__actions__logout:hover{
    background-color: #260909;
    transition: 0.1s ease-out;
}