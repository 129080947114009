.respondModal{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background: #1F1F1FA1;
    gap: 1rem;
}

.commentArea{
    border: 1px solid grey;
    border-radius: 0.5rem;
    width: 30rem;
    min-height: 8rem;
    padding: 0.5rem;
}

.commentArea2{
    border: 1px solid grey;
    border-radius: 0.5rem;
    width: 30rem;
    min-height: 8rem;
    padding: 0.5rem;
}

.sendComment{
    color: white;
    font-size: 1rem;
    border: 0.1rem solid white;
    border-radius: 0.5rem;
    padding: 0.5rem;
    padding-right: 2rem;
    padding-left: 2rem;
    cursor: pointer;
}

.sendComment:hover{
    color: #1F1F1FA1;
    font-size: 1rem;
    font-weight: bold;
    background: white;
    border-radius: 5rem;
    padding: 0.5rem;
    padding-right: 2rem;
    padding-left: 2rem;
    cursor: pointer;
    transition: 0.5s all ease-in-out;
}

/*.sendComment{*/
/*    color: white;*/
/*    width: 2rem;*/
/*    height: 2rem;*/
/*    border: 1px solid grey;*/
/*    border-radius: 100%;*/
/*    padding: 0.2rem;*/
/*    cursor: pointer;*/
/*    margin-left: -2.5rem;*/
/*}*/

/*.sendComment:hover{*/
/*    color: white;*/
/*    width: 2rem;*/
/*    height: 2rem;*/
/*    background: grey;*/
/*    border-radius: 100%;*/
/*    padding: 0.2rem;*/
/*    cursor: pointer;*/
/*}*/

.CommentContaier{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: #1F1F1FA1;
    gap: 1rem;
}

.CommentHeader{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    background: #1F1F1FA1;
    gap: 2rem;
}

.CommentProfile{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 3rem;
    background: #1F1F1FA1;
    border-radius: 100%;
    overflow: hidden;
}

.CommentProfileImg{
    object-fit: cover;
}

.CommentName{
    color: white;
    font-size: 1.2rem;
    font-weight: bold;
}

.CommentContent{
    color: white;
    font-size: 1rem;
    font-weight: 400;
    width: 30rem;
    text-align: justify;
}