aside.left-bar-data-5 {
    width: 100%;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 24px;
    background-color: rgba(255, 255, 255, 0.12);
    color: white;

    div.intro {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        border: 1px solid #FF5620;
        background-color: #FF56200A;
        padding: 16px;
        gap: 16px;
        border-radius: 16px;

        p {
            font-size: 16px;
            line-height: 20px;
            font-weight: 400;
            color: white;

            span {
                font-size: 10px;
                line-height: 12.5px;

            }
        }
    }

    h3 {
        font-size: 16px;
        line-height: 18px;
        font-weight: 700;
        color: white;
        margin: 28px 0;

    }

    div.messages {
        display: flex;
        flex-direction: column;
        gap: 20px;

        aside {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            border-radius: 12px;
            padding: 12px;
            gap: 22px;
            background-color: rgba(128, 128, 128, 0.562);

            div {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 10px;

                p {
                    &:nth-child(1) {

                        font-size: 18px;
                        line-height: 22.5px;
                        font-weight: 700;
                        color: white;
                    }

                    &:nth-child(2) {

                        font-size: 16px;
                        line-height: 20px;
                        font-weight: 400;
                        color: rgba(255, 255, 255, 0.8);
                    }
                }
            }
        }
    }
}