.navbar {
    background-color: transparent !important;
    top: 0;
    /* Position from the top */
    left: 50%;
    /* Position from the left */
    transform: translate(-50%, 0);
    /* Adjusts the element to be centered */
    max-width: 900px;

    nav.web-nav {
        box-shadow: none;

        @media screen and (max-width: 990px) {
            display: none;
        }
    }

    a.download {
        @media screen and (max-width: 990px) {
            display: none;
        }
    }

    div.toggler {

        @media screen and (min-width: 991px) {
            display: none;
        }

        img {
            cursor: pointer;
            transition: all 0.3s ease-in-out;

            &:hover {
                transform: scale(1.1);
            }

        }
    }
}

nav.mobile-nav {
    background-color: #161616;
    position: fixed;
    padding: 25px 20px;
    overflow-y: scroll;

    @media screen and (min-width: 991px) {
        display: none;
    }

    img {
        cursor: pointer;
        transition: all 0.3s ease-in-out;

        &:hover {
            transform: scale(1.1);
        }

    }

    div.auth {
        height: auto;
        border-bottom: 1px solid white;
        gap: 6.09px;

        a {
            font-weight: 700;
            font-size: 24px;
            line-height: 32.4px;
            padding: 16px 0;
            color: white;

            &:last-child {
                margin-bottom: 10px;
            }
        }

    }

    div.links {
        width: 100%;
        height: auto;
        gap: 6.09px;

        a {
            font-weight: 400;
            font-size: 20px;
            line-height: 27px;
            color: white;
            padding: 16px 0;
        }
    }
}