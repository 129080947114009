.posts {
    margin-top: 15px;

    .mobile-left-bar-data-3 {
        display: none;

        @media screen and (max-width: 990px) {
            display: flex;
        }
    }


    .datas {
        margin-top: 0;
        margin-bottom: 50px;

        .toggler {
            display: flex;
            gap: 24px;
            width: 100%;
            align-items: center;
            justify-content: flex-start;
            margin-top: 30px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.22);

            h2 {
                color: rgba(255, 255, 255, 0.8);
                margin-top: 5px;
                font-weight: 400;
                font-size: 24px;
                line-height: 36px;
                padding-bottom: 5px;
                cursor: pointer;
                transition: all 0.3s step-in;

                &.active {
                    border-bottom: 1px solid #fff;
                }
            }
        }

        .main-body {
            margin-top: 10px;
            display: flex;
            flex-wrap: wrap;
            gap: 12px;

            div.card {
                min-width: 200px;
                min-height: 200px;
                flex: 1;
                position: relative;
                background-color: rgba(255, 255, 255, 0.12);
                border-radius: 16.5px;

                aside {
                    width: 100%;
                    height: 100%;
                    background-color: rgba(255, 255, 255, 0.12);
                    border-radius: 16.5px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .middle {
                        border-radius: 8px;
                        background-color: rgba(255, 255, 255, 0.12);
                        width: calc(100% - 20px);
                        padding: 8px;
                        gap: 12px;

                        p {
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 17.5px;
                            color: #fff;
                        }

                        p.certif {
                            color: rgba(255, 255, 255, 0.64);
                        }
                    }
                }

                .top {
                    position: absolute;
                    top: 10px;
                    left: 10px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 0 16px;
                    width: calc(100% - 20px);

                    div {
                        display: flex;
                        gap: 4px;
                        border-radius: 8px;
                        padding: 4px 8px;
                        background-color: rgba(255, 255, 255, 0.12);
                        border: 1px solid rgba(255, 255, 255, 0.32);

                        &.type {
                            p {
                                font-size: 8px;
                                font-weight: 400;
                                line-height: 16.87px;
                                letter-spacing: 0.4px;
                                color: #fff;
                            }
                        }

                        p {
                            font-size: 8px;
                            font-weight: 500;
                            line-height: 16.87px;
                            color: #fff;
                        }
                    }
                }

                .bottom {
                    position: absolute;
                    bottom: 10px;
                    left: 10px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 16px;
                    padding: 0 16px;
                    width: calc(100% - 20px);

                    div {
                        display: flex;
                        align-items: center;
                        gap: 4px;
                        color: rgba(255, 255, 255, 0.64);

                        p {
                            font-size: 12px;
                            line-height: 16.2px;
                            font-weight: 400;
                        }
                    }
                }
            }
        }
    }
}