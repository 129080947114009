.listelt{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 10fr 1fr 1fr 1fr;
    gap: 1rem;
    align-items: center;
}

.songname{
    color: white;
    font-size: 1rem;
}

.songduration{
    color: white;
    font-size: 1rem;
}

.songadd{
    cursor:pointer;
    width: 1.2rem;
    height: 1.2rem;
}

.songmore{
    cursor: pointer;
    width: 1.2rem;
    height: 1.2rem;
}

.songliked{
    cursor: pointer;
    width: 1.2rem;
    height: 1.2rem;
    color: white;
}

.songindex{
    cursor: pointer;
    color: white;
    font-weight: 700;
}