.promotion {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    background-color: black;
    z-index: 100;
    // margin: 0;
    // padding: 50px 50px;
    display: flex;

    h1 {
        color: white;
        font-size: 32px;
        line-height: 40px;
        font-weight: 700;
    }

    p {
        color: rgba(255, 255, 255, 0.64);
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        margin: 0;
        &.total{
            font-weight: 700;
            color: white;
            span{
                margin-right: 14px;
                font-weight: 400;
            }
            
        }
    }

    h2 {
        color: white;
        font-weight: 700;
        font-size: 18px;
        line-height: 22.5px;
    }

    h3 {
        color: rgba(255, 255, 255, 0.64);
        font-weight: 400;
        font-size: 16px;
        line-height: 21.6px;
    }

    h4 {
        color: rgba(255, 255, 255, 0.64);
        font-weight: 500;
        font-size: 12px;
        line-height: 21.6px;
    }

    .checks-container {
        display: flex;
        flex-direction: column;
        gap: 32px;

        .checks {
            display: flex;
            flex-direction: column;
            gap: 12px;

            .check {
                background-color: rgba(255, 255, 255, 0.16);
                padding: 12px;
                padding-bottom: 24px;
                border-radius: 12px;
                display: flex;
                gap: 16px;

                &.active {
                    background-color: #FF562029;
                }

                .check-option {
                    width: 15px;
                    height: 15px;
                    border: 2px solid white;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: 6.5px;
                    cursor: pointer;
                    transition: all 0.2s ease-in-out;

                    &.active {
                        border-color: #FF5620;
                    }

                    span {
                        width: 60%;
                        height: 60%;
                        border-radius: 50%;
                        background-color: transparent;

                        &.active {
                            background-color: #FF5620;
                        }
                    }

                }

                label {
                    color: white;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 18px;

                    &.active {
                        color: #FF5620;
                    }
                }

                p {
                    color: rgba(255, 255, 255, 0.64);
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 21.6px;
                }
            }
        }
    }
    .payment {
        display: flex;
        flex-direction: column;
        gap: 16px;

        div.music {
            display: flex;
            background-color: rgba(255, 255, 255, 0.12);
            padding: 8px;
            gap: 24px;
            border-radius: 12px;

            img.artist {
                border-radius: 12px;
            }
        }

        div.period {
            display: flex;
            flex-direction: column;
            gap: 16px;

            .dates {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 16px;

                input {
                    border-radius: 16px;
                    padding: 8px 12px;
                    background-color: rgba(255, 255, 255, 0.04);
                    border: 1px solid rgba(255, 255, 255, 0.12);
                    color: white;
                    color-scheme: dark;
                }
            }

            .amount {
                display: flex;
                justify-content: space-between;
                align-items: center;

                div {
                    gap: 16px;
                }

                input {
                    width: 115px;
                    height: 24px !important;
                    background-color: rgba(255, 255, 255, 0.04);
                    border: 1px solid rgba(255, 255, 255, 0.12);
                    border-radius: 16px;
                    padding: 8px 12px;

                    &:focus {
                        border: 1px solid #FF5620;
                        border-bottom: 1px solid #FF5620 !important;
                    }
                }
            }

            .payment-method {
                background-color: rgba(255, 255, 255, 0.12);
                display: flex;
                flex-direction: column;
                gap: 24px;
                padding: 16px;
                border-radius: 12px;

                .service {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    div {
                        display: flex;

                        padding: 8px;
                        border-radius: 12px;
                        gap: 12px;
                        background-color: rgba(255, 255, 255, 0.08);

                        &:nth-child(1) {
                            width: 200px;
                        }

                        &:nth-child(2) {
                            width: 100px;
                            align-items: center;
                            justify-content: center;
                            border-radius: 54px;
                        }
                    }
                }

                p {
                    font-size: 12px;
                    line-height: 15px;
                    font-weight: 400;
                    color: rgba(255, 255, 255, 0.64);
                }
            }
        }
    }
    
    .promotion-holder {
        display: grid;
        grid-template-columns: 2fr 1fr ;
        min-height: auto;
        // height: 100%;
        overflow-y: auto;
        // padding: 50px 0;

        @media screen and (max-width: 990px) {
            display: none;
        }


        .informations {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 40px;
            // width: 70%;
            padding: 50px;
            // background-color: red;
            height: auto;
            .top {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                border: 0;

                div {
                    display: flex;
                    flex-direction: column;
                    // gap: 10px;
                }

                img {
                    margin-top: 10px;
                    transition: all 0.2s ease-in-out;
                    cursor: pointer;

                    &:hover {
                        transform: scale(1.1);
                    }
                }

            }

            .main-content {
                background-color: rgba(255, 255, 255, 0.08);
                border-top-left-radius: 12px;
                border-top-right-radius: 12px;

                .form {
                    border-top-left-radius: 12px;
                    border-top-right-radius: 12px;
                    display: flex;
                    gap: 32px;
                    padding: 32px 16px;

                    div {
                        width: 100%;


                        &.checks-container {
                            display: flex;
                            flex-direction: column;
                            gap: 32px;

                            .checks {
                                display: flex;
                                flex-direction: column;
                                gap: 12px;

                                .check {
                                    background-color: rgba(255, 255, 255, 0.16);
                                    padding: 12px;
                                    padding-bottom: 24px;
                                    border-radius: 12px;
                                    display: flex;
                                    gap: 16px;

                                    &.active {
                                        background-color: #FF562029;
                                    }

                                    .check-option {
                                        width: 15px;
                                        height: 15px;
                                        border: 2px solid white;
                                        border-radius: 50%;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        margin-top: 6.5px;
                                        cursor: pointer;
                                        transition: all 0.2s ease-in-out;

                                        &.active {
                                            border-color: #FF5620;
                                        }

                                        span {
                                            width: 60%;
                                            height: 60%;
                                            border-radius: 50%;
                                            background-color: transparent;

                                            &.active {
                                                background-color: #FF5620;
                                            }
                                        }

                                    }

                                    label {
                                        color: white;
                                        font-weight: 700;
                                        font-size: 16px;
                                        line-height: 18px;

                                        &.active {
                                            color: #FF5620;
                                        }
                                    }

                                    p {
                                        color: rgba(255, 255, 255, 0.64);
                                        font-weight: 400;
                                        font-size: 16px;
                                        line-height: 21.6px;
                                    }
                                }
                            }
                        }

                        &.payment {
                            display: flex;
                            flex-direction: column;
                            gap: 16px;

                            div.music {
                                display: flex;
                                background-color: rgba(255, 255, 255, 0.12);
                                padding: 8px;
                                gap: 24px;
                                border-radius: 12px;

                                img.artist {
                                    border-radius: 12px;
                                }
                            }

                            div.period {
                                display: flex;
                                flex-direction: column;
                                gap: 16px;

                                .dates {
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    gap: 16px;

                                    input {
                                        border-radius: 16px;
                                        padding: 8px 12px;
                                        background-color: rgba(255, 255, 255, 0.04);
                                        border: 1px solid rgba(255, 255, 255, 0.12);
                                        color: white;
                                        color-scheme: dark;
                                    }
                                }

                                .amount {
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;

                                    div {
                                        gap: 16px;
                                    }

                                    input {
                                        width: 115px;
                                        height: 24px !important;
                                        background-color: rgba(255, 255, 255, 0.04);
                                        border: 1px solid rgba(255, 255, 255, 0.12);
                                        border-radius: 16px;
                                        padding: 8px 12px;

                                        &:focus {
                                            border: 1px solid #FF5620;
                                            border-bottom: 1px solid #FF5620 !important;
                                        }
                                    }
                                }

                                .payment-method {
                                    background-color: rgba(255, 255, 255, 0.12);
                                    display: flex;
                                    flex-direction: column;
                                    gap: 24px;
                                    padding: 16px;
                                    border-radius: 12px;

                                    .service {
                                        display: flex;
                                        justify-content: space-between;
                                        align-items: center;

                                        div {
                                            display: flex;

                                            padding: 8px;
                                            border-radius: 12px;
                                            gap: 12px;
                                            background-color: rgba(255, 255, 255, 0.08);

                                            &:nth-child(1) {
                                                width: 200px;
                                            }

                                            &:nth-child(2) {
                                                width: 100px;
                                                align-items: center;
                                                justify-content: center;
                                                border-radius: 54px;
                                            }
                                        }
                                    }

                                    p {
                                        font-size: 12px;
                                        line-height: 15px;
                                        font-weight: 400;
                                        color: rgba(255, 255, 255, 0.64);
                                    }
                                }
                            }
                        }
                    }

                }

                .action {
                    background-color: rgba(255, 255, 255, 0.16);
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 16px;
                    border-bottom-left-radius: 12px;
                    border-bottom-right-radius: 12px;
                }

            }
        }

        .preview {
            // width: 30%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 24px;
            background-color: rgba(255, 255, 255, 0.08);
            border-left: 1px solid #FF562052;
            padding: 20px;
            height: 100%;

            h1 {
                color: white;
                font-size: 24px;
                line-height: 30px;
                font-weight: 700;
            }

            .recap {
                display: flex;
                flex-direction: column;
                gap: 8px;
                padding: 16px;
                border-radius: 8px;
                background-color: rgba(255, 255, 255, 0.16);

                h5 {
                    color: rgba(255, 255, 255, 0.64);
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 25.6px;
                    margin: 0;
                }

                p {
                    color: white;
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 18px;
                    margin: 0;
                }
            }

            .artist {
                display: flex;
                gap: 12px;

                img {
                    border-radius: 15px;
                }
            }

            .planify {
                display: flex;
                flex-direction: column;
                gap: 16px;
            }

            .values {
                display: flex;
                flex-direction: column;
                gap: 12px;
            }

            .result {
                display: flex;
                flex-direction: column;
                gap: 24px;
                padding: 16px;
                border-radius: 12px;
                background-color: rgba(255, 255, 255, 0.12);
            }
        }
    }
    .mobile-promotion{
        width: 95%;
        margin: 0 auto;
        gap: 40px;
        display: flex;
        flex-direction: column;
        overflow: auto;
        padding-bottom: 50px;
        @media screen and (min-width: 990px) {
            display: none;
        }
        .preview {
            width: 95%;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            gap: 12px;

            h1 {
                color: white;
                font-size: 24px;
                line-height: 30px;
                font-weight: 700;
            }

            .recap {
                display: flex;
                flex-direction: column;
                gap: 8px;
                padding: 16px;
                border-radius: 8px;
                background-color: rgba(255, 255, 255, 0.16);

                h5 {
                    color: rgba(255, 255, 255, 0.64);
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 25.6px;
                    margin: 0;
                }

                p {
                    color: white;
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 18px;
                    margin: 0;
                }
            }

            .artist {
                display: flex;
                gap: 12px;

                img {
                    border-radius: 15px;
                }
            }

            .planify {
                display: flex;
                flex-direction: column;
                gap: 16px;
            }

            .values {
                display: flex;
                flex-direction: column;
                gap: 12px;
            }

            .result {
                display: flex;
                flex-direction: column;
                gap: 24px;
                padding: 16px;
                border-radius: 12px;
                background-color: rgba(255, 255, 255, 0.12);
            }
        }
    }
}