.home{
    background-color: black;
    section.divider{
        background-color: red;
        height: 241.09px;
        width: 100%;
        background-color: transparent;
        @media screen and (max-width: 990px) {
            height: 132.63px;
        }
        img{
            width: 100%;
            height: 100%;
        }
    }
}