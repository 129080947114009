.contact-us {
    background: url('../../assets/illustrations/contact_bg.png') center/cover no-repeat;
    height: 474px;
    width: 100%;
    margin-top: 100px;
    // border-bottom: 1px solid gray;

    @media screen and (max-width: 768px) {
        height: 296.48px;
        background: url('../../assets/illustrations/contact_bg.png') left/cover no-repeat;
    }

    h4 {
        font-weight: 700;
        font-size: 48px;
        line-height: 60px;
        color: white;
        text-align: center;

        @media screen and (max-width: 768px) {
            width: 90%;
            font-size: 24px;
            line-height: 32.4px;
        }

    }

    p {
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        color: rgba(255, 255, 255, 0.8);
        text-align: center;

        @media screen and (max-width: 768px) {
            width: 90%;
            font-size: 20px;
            line-height: 30px;
            margin-top: 10px;
        }

        // margin-top: 20px;
    }

    a {
        margin-top: 25px;
    }
}