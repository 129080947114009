.profileContainer {
  width: 100%;
  height: calc(100vh - 120px);
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 20px 50px;
}

.profileTop {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: start;
}

.profilePicContainer {
  position: relative;
  width: 150px;
  height: 150px;
  margin-right: 30px;
  cursor: pointer;
}

.profilePic {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
}

.profilEditIcon {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  box-shadow: 0 2px 5px #888;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(0);
  transition: transform 0.3s ease-in-out;
}

.profilEditIconTranslate {
  transform: scale(1);
}

.profilInfo {
  display: flex;
  flex-direction: column;
  justify-content: start;
  color: #1b0410;
  align-items: start;
  align-self: center;
}

.profileName {
  font-size: 35px;
  color: #85134e;
  font-weight: bold;
}

.profilePhoneNumber {
  font-size: 20px;
  color: #888;
}

.profileEdit {
  position: absolute;
  top: 50px;
  right: 50px;
  padding: 5px 10px;
  border-radius: 3px;
  background-color: #85134e;
  color: #fff;
}

.profileBottom {
  margin-top: 50px;
  width: 100%;
  height: auto;
}

.profileInfoItem {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  margin-bottom: 20px;
}

.profileInfoTitle {
  margin-left: 10px;
  font-size: 18px;
}