.datas {
    margin-bottom: 50px;

    .summaries {
        .summaries_container {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            margin: 20px 0;
            gap: 15px;
        }
    }
    .mobile-left-bar-data-5{
        display: none;
        @media screen and (max-width: 990px) {
            display: flex;
        }
    }
    .advitisements {
        background-color: transparent;

        .advitisements_container {
            border: 1px solid rgba(255, 255, 255, 0.12);
            border-radius: 24px;
            padding: 0 24px;
            // gap: 48px;
            margin: 20px 0 40px 0;
        }
    }
}