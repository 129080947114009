.favoriteNotifContainer{
    width: 100%;
    display: flex;
    gap: 2rem;
}

.favoriteNotifIcon{
    width: 1.5rem;
    height: 1.5rem;
    color: #FF5620;
    margin-top: 0.2rem;
}

.favoriteNotifContent{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.favoriteNotifContentTop{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.favoriteNotifContentUserProfileContainer{
    width: 2rem;
    height: 2rem;
    border-radius: 2rem;
    background: #D9D9D9;
    overflow: hidden;
}

.favoriteNotifContentUserProfile{
    width: 100%;
    height: 100%;
    object-fit: cover;
}



.favoriteNotifContentRespond{
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #FFFFFFCC;
    padding-right: 1rem;
    cursor: pointer;
}

.favoriteNotifContentUserNameContainer{
    width: 100%;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #FFFFFFCC;
}

.favoriteNotifContentUserName{
    font-size: 1rem;
    font-weight: 700;
    line-height: 18px;
    text-align: left;
    color: white;
}

.favoriteNotifContentComment{
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #FFFFFF;
    max-lines: 2;
    overflow: hidden;
    text-overflow: ellipsis;
}

.favoriteNotifContentPostTime{
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #FFFFFFCC;
}