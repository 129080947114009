.content-type-select{
    width: 100%;
    height: 100vh;
    position: relative;
    background-color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
}

.content-type-select-background{
    width: 30%;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: url("../assets/new_images/blurright.svg");
    background-repeat: no-repeat;
    background-position: left;
    background-size: contain;
}

.content-type-select-background2{
    width: 40%;
    height: 100%;
    position: absolute;
    bottom: 0;
    right: 0;
    background: url("../assets/new_images/blurleft.svg");
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
}

.content-type-select-background3{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background: url("../assets/new_images/noise.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.content-type-select-top{
    width: 100%;
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    padding-left: 6rem;
    padding-right: 6rem;
}

.content-type-select-logo{
    width: 10rem;
    height: 10rem;
    cursor: pointer;
}

.content-type-select-close{
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}

.content-type-select-title-text{
    color: white;
    font-size: 1.5rem;
    font-weight: 500;
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
    line-height: 2rem;
    padding: 0.5rem;
    position: relative;
}
@media screen and (max-width: 500px){
    .content-type-select-title-text{
        width: 70%;
    }
}
.content-type-select-select{
    margin-top: 2rem;
    width: 80%;
    height: 40%;
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 1rem;
}

@media screen and (max-width: 600px){
    .content-type-select-select{
        width: 90%;
    }
}
.content-type-select-elt{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    cursor: pointer;
    border-radius: 1rem;
    width: 40%;
    height: 100%;
    padding: 1rem;
    border: 2px solid #FFFFFF1F;
    transition: 0.5s all ease-in-out;
}

.content-type-select-elt:hover{
    background: #FFFFFF1F;
    border: none;
    transition: 0.5s all ease-in-out;
}

@media screen and (max-width: 600px){
    .content-type-select-elt{
        gap: 0;
        grid-gap: 0;
    }
}
.content-type-select-elt-img{
    width: 10rem !important;
    height: 10rem !important;
    font-size: 12rem !important;
    color: white !important;
}

@media screen and (max-width: 600px){
    .content-type-select-elt-img{
        width: 5rem !important;
        height: 5rem !important;
    }
}
.content-type-select-elt-txt{
    color: white;
    font-size: 2.5rem;
    font-weight: bold;
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
    line-height: 2rem;
    padding: 0.5rem;
}

@media screen and (max-width: 600px){
    .content-type-select-elt-txt{
        font-size: 1.5rem;
    }
}