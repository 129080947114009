* {
  box-sizing: border-box;
}

:root {
  --radius-1: 70%;
  --radius-2: 30%;
  --radius-3: 30%;
  --radius-4: 70%;
  --radius-5: 60%;
  --radius-6: 40%;
  --radius-7: 60%;
  --radius-8: 40%;
}

a {
  text-decoration: none;
}

.bodyy {
  background: black;
  width: 100vw;
}

.blob {
  width: 520px;
  height: 520px;
  border-radius: var(--radius-1) var(--radius-2) var(--radius-3) var(--radius-4) / var(--radius-5) var(--radius-6) var(--radius-7) var(--radius-8);
  background-color: #21D4FD;
  background-image: linear-gradient(-10deg, #FF1751 0%, #FF9F03 75%, #22F642 100%);
  box-shadow: 0 0 10px rgba(0, 0, 0, .3);
  position: absolute;
  top: -80px;
  right: -116px;
  /*z-index:1;*/
}

.b2 {
  width: 520px;
  height: 520px;
  border-radius: var(--radius-1) var(--radius-2) var(--radius-3) var(--radius-4) / var(--radius-5) var(--radius-6) var(--radius-7) var(--radius-8);
  background-color: #21D4FD;
  background-image: linear-gradient(-10deg, #FF1751 0%, #FF9F03 75%, #22F642 100%);
  box-shadow: 0 0 10px rgba(0, 0, 0, .3);
  position: absolute;
  top: 302px;
  left: -456px;
  transform: rotate(45deg);
  /*z-index:1;*/
}

.main-row {
  width: 93vw;
  height: 80vh;
  justify-content: center;
  align-content: center;
  display: flex;
  gap: 4em;
}

.comming-soon {
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-content: center;
  display: flex;
  gap: 1em;
  flex-direction: column;
  align-items: center;
  color: white;
  font-size: 2em;
  font-weight: 700;
  text-align: center;
  text-shadow: 0 0 10px rgba(0, 0, 0, .3);
}


.comming-soon-title {
  position: relative;
  color: white;
  font-size: 2em;
  font-weight: 700;
  text-align: center;
  text-shadow: 0 0 10px rgba(0, 0, 0, .3);

}

.comming-soon-btn {
  position: relative;

  color: white;
  font-size: 1.5em;
  font-weight: 700;
  text-align: center;
  text-shadow: 0 0 10px rgba(0, 0, 0, .3);

  border: 2px solid white;
  border-radius: 10px;
  padding: 0.5em 1em;
  background-color: transparent;
  cursor: pointer;

}

.mob {
  display: none;
}

.first-box {
  width: 50%;
  height: 100%;
  background: url("../assets/new_images/immg.jpg");
  border-radius: 50px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  position: relative;
  overflow: hidden;
}

.cornerlight {
  width: 1000px;
  height: 1000px;
  background: url("../assets/new_images/cornerlight.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  position: absolute;
  top: 0;
  right: 0;
}

.viewport {
  margin: 0 0 0 0;
  width: 100vw;
  max-width: 100vw;
  overflow-x: hidden;
  position: fixed;
}

.device-grid-container {
  display: grid;
  align-content: start;
  position: relative;
  padding-top: 3em;
  padding-right: 8em;
  padding-left: 8em;
  justify-content: center;
  width: 100vw;
  /*height: 100vh;*/
  background-color: black;
  overflow: hidden;
}

.device-grid {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 50px;
  background: #121212;
  padding-left: 4%;
}

.infinite-carousel {}

.carousel-item {
  /* Adjust the background color as needed */
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes slide {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(calc(-100% * var(--item-count, 5)));
    /* Default count is 5, adjust as needed */
  }
}


.device-subgrid {
  color: rgba(255, 255, 255, 0.80);
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  /*font-family: "Satoshi Variable";*/
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 27px */
  display: flex;
  width: 638px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  /*display: grid;*/
  /*grid-template-columns: repeat(3, 33.333%);*/
  /*grid-template-rows: repeat(3, 33.333%);*/
  /*max-width: 1200px;*/
  /*z-index: 1000;*/
  margin-top: -280px;

}

.device1 {
  width: 600px;
  margin: 296px 0 0 -16px;
}

.device2 {
  width: 200px;
  height: 200px;
  margin: 20px 0 0 0;
}

.playful {
  position: absolute;
  top: 110px;
  right: 100px;
  width: 490px;
  height: 490px;
  border-radius: 50%;
  border: 5px solid #c4c4c4;
  border-style: dotted;
  /*z-index: 3;*/
}

.playit {
  position: absolute;
  top: 110px;
  right: 88px;
  width: 440px;
  height: 440px;
  border-radius: 50%;
  /*z-index: 3;*/
  overflow: visible;
}

.device3 {
  width: 1000px;
  height: 718px;
  margin: 200px 0 0 0;
}

.phone {
  margin-top: 110%;
  z-index: 3;
  max-width: 150%;
  margin-left: -84%;
}

.mac {
  margin-top: 21%;
  z-index: 3;
  max-width: 60%;
  margin-left: 33%;
}

.hero-container {
  padding: 0 88px 88px 88px;
  height: 100vmin;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #000;
}

.intro1 {
  position: absolute;
  height: 918px;
  width: 1200px;
  margin: 200px -200px 0 0;
}

.intro2 {
  height: 978px;
  width: 600px;
  position: absolute;
  margin: -400px 0 0 0;
}

.nav-bar {
  /*position: relative;*/
  background-color: transparent;
  color: white;
  width: 100%;
  position: fixed;
  z-index: 2000;
  /*background-color: #000000;*/
  /*color: #FFFFFF;*/
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  padding: 48px;
}

.nav-bar .nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: white;
  z-index: 5;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;

  display: none;
  opacity: 0;
}

/*.nav-elts{
  list-style: none;
  
  padding-top: 16px;
}
.nav-elts li{
  font-size: 16px;
  font-weight: 648;
  padding-left: 40px;
  display: inline-block;
  cursor: pointer;
} */


.lsdro1 {
  border: none;
  display: block;
  background-color: transparent;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  width: 85px;
  margin-right: 5px;
  color: white;
  outline: none;
  padding: 0px;

}

.lsdrp {
  border: none;
  display: block;
  background-color: transparent;
  outline: none;
  padding: 0px;
  font-size: 26px;
  font-weight: 600;
  margin-top: 16px;
  margin-left: 20px;
}

/* .nav-bar{*/
/*  width: 100%;*/
/*  position: fixed;*/
/*  z-index: 5;*/
/*  background-color: #000000;*/
/*  color: #FFFFFF;*/
/*   font-size: 16px;*/
/*   font-style: normal;*/
/*   font-weight: 400;*/
/*!*box-shadow: -1px 6px 6px 0px rgba(0,0,0,0.1);*!*/
/*!*-webkit-box-shadow: -1px 6px 6px 0px rgba(0,0,0,0.1);*!*/
/*!*-moz-box-shadow: -1px 6px 6px 0px rgba(0,0,0,0.1);*!*/
/*} */
.nav-elts {
  list-style-type: none;
  float: left;
  right: 48px;
  position: fixed;
  margin-right: 10%;
  color: white;
  z-index: 8 !important;
}

.nav-elts li {
  color: var(--White, #FFF);
  leading-trim: both;
  text-edge: cap;
  /*font-family: "Satoshi Variable", serif;*/
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 5em;
  z-index: 8 !important;
  display: inline-block;
  cursor: pointer;
}

.links4 {
  width: 28px;
  height: 28px;
  cursor: pointer;
}

.links1 {
  width: 35px;
  height: 35px;
  cursor: pointer;
}

.links {
  width: 35px;
  height: 35px;
  cursor: pointer;
}

.links5 {
  width: 90px;
  height: 90px;
  cursor: pointer;
}

.links3 {
  width: 60px;
  height: 60px;
  cursor: pointer;
}

.nav-logoo {
  float: left;
  display: block;
  margin-left: 5%;
  z-index: 8;
  margin-top: 25px;
}

.nav-bar img {
  width: 135.36px;
  flex-shrink: 0;
}

.auth-cta {
  list-style-type: none;
  display: flex;
  margin-right: -8px;
}

.hero-title {
  width: 80%;
  letter-spacing: -0.41px;
  /*z-index: 2;*/
  color: var(--White, #FFF);
  leading-trim: both;
  text-edge: cap;
  /*font-family: "Satoshi Variable";*/
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
  /* 60px */
}

.btn-disc {
  display: flex;
  padding: 20px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 54px;
  border: 1px solid var(--White, #FFF);
  color: var(--White, #FFF);
  leading-trim: both;
  text-edge: cap;
  font-size: 30px;
  font-style: normal;
  background-color: transparent;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  text-decoration: none;
}

.btn-disc2 {
  display: flex;
  padding: 20px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 54px;
  margin-top: 1em;
  border: 1px solid var(--White, #FFF);
  color: var(--White, #FFF);
  leading-trim: both;
  text-edge: cap;
  font-size: 24px;
  font-style: normal;
  background-color: transparent;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}

.btn-disc3 {
  display: flex;
  padding: 20px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 54px;
  margin-top: 1em;
  border: 1px solid var(--White, #FFF);
  color: var(--White, #FFF);
  leading-trim: both;
  text-edge: cap;
  font-size: 24px;
  font-style: normal;
  background-color: transparent;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  position: relative;
}


.imgbtn {
  width: 30px;
  height: 30px;
}

.row {
  display: flex;
  gap: 30px;
  z-index: 1;
  margin-left: 0;
  margin-top: 3em;
}

.row::after {
  display: none;
}

.btn-freedown {
  display: flex;
  padding: 20px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: 0px;
  border-radius: 54px;
  background: #FF5620;
  color: var(--White, #FFF);
  leading-trim: both;
  text-edge: cap;
  /*font-family: "Satoshi Variable";*/
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: relative;
  cursor: pointer;
  text-decoration: none;
}

.botsect1 {
  width: 100%;
  padding-top: 2em;
  position: relative;
  /*justify-content: center;*/
  overflow: hidden;
  /*z-index: 4;*/
}

.phone_in_hand {
  position: relative;
  /*justify-self: center;*/
  /*align-self: center;*/
  left: 40%;
  /*flex-shrink: 0;*/
}

.discover {
  position: absolute;
  top: 50%;
  left: 30%;
}

.disk {
  position: absolute;
  top: 50%;
  left: 0;
}

.playlist {
  position: absolute;
  right: 10%;
  top: 40%;
}

.song1 {
  position: absolute;
  top: 72%;
  right: 5%;
}

.song2 {
  position: absolute;
  top: 25%;
  left: 15%;
}

.listening1 {
  position: absolute;
  right: 5%;
  top: 2%;
}

.listening2 {
  position: absolute;
  top: 2%;
  left: 15%;
}

.event {
  position: absolute;
  top: 2%;
  left: 35%;
}


.hero-subtitle {
  color: rgba(255, 255, 255, 0.80);
  leading-trim: both;
  text-edge: cap;
  /*font-family: "Satoshi Variable";*/
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 27px */
  width: 65%;
  letter-spacing: 1.5;
  margin-top: 2em;
  z-index: 2;
}


.hero-subtitle2 {
  width: 50%;
  color: rgba(255, 255, 255, 0.80);
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  margin-top: -2em;
  /*font-family: "Satoshi Variable";*/
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 27px */
}

.hero-subtitlee2 {
  width: 30%;
  color: rgba(255, 255, 255, 0.80);
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  margin-top: -2em;
  /*font-family: "Satoshi Variable";*/
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 27px */
}

.hero-subtitle3 {
  color: #CCC;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  /*font-family: "Satoshi Variable";*/
  font-size: 16px;
  font-style: normal;
  margin-top: -2em;
  font-weight: 500;
  width: 40%;
  line-height: 150%;
  /* 24px */
}

.hero-subtitle6 {
  color: #CCC;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  /*font-family: "Satoshi Variable";*/
  font-size: 16px;
  font-style: normal;
  margin-top: -2em;
  font-weight: 500;
  width: 40%;
  line-height: 150%;
  /* 24px */
}

.hero-subtitlee6 {
  color: #CCC;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  /*font-family: "Satoshi Variable";*/
  font-size: 25px;
  font-style: normal;
  margin-top: -2em;
  font-weight: 500;
  width: 50%;
  line-height: 150%;
  /* 24px */
}

.cta-container {
  display: flex;
  align-items: start;
  justify-content: start;
  margin-top: -8px;
}

.cta-container a {
  text-decoration: none;
}

.hero-cta {
  /* max-width: 220px; */
  width: auto;
  padding: 12px;
  font-size: 13pt;
  font-weight: 400;
  color: #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #f2f2f2;
  border-radius: 8px;
  cursor: pointer;
  z-index: 3;
  margin-right: 12px;
}

.hero-ctah {
  cursor: pointer;
  max-width: 220px;
  padding: 14px;
  font-size: 13pt;
  font-weight: 500;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  background: linear-gradient(to right, #FF2447, #FF9D04);
  border-radius: 8px;

  z-index: -4;
}

.logo-container {
  width: 600px;
  height: 600px;
  margin-left: 24px;
  object-fit: cover;
  z-index: 3;
}

.tcon {
  display: block;

}

.why-head {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 60px;
}

.fulll {
  background: black;
  width: 100%;
}

.why-head1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2em;
  margin: auto;
}

.carous {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: -8em;
  overflow-x: scroll;
  position: relative;
  width: 100%;
  gap: 5em;
  /*background: url("../assets/new_images/back5.png");*/
  /*background-repeat: no-repeat;*/
  /*background-position: center;*/
  /*background-size: cover;*/
}

/* Hide the scrollbar */
.carous::-webkit-scrollbar {
  display: none;
  /* Hide the scrollbar for WebKit browsers (Chrome, Safari, etc.) */
}

/* Optional: Style the scrollbar track and thumb */
.carous::-webkit-scrollbar-track {
  background: transparent;
  /* Set the background color of the scrollbar track */
}

.carous::-webkit-scrollbar-thumb {
  background: #888;
  /* Set the color of the scrollbar thumb */
  border-radius: 10px;
  /* Set the border radius of the scrollbar thumb */
}


.carous2 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-bottom: -1.5em;
  width: 60%;

  gap: 5em;
}

.carous3 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 100px;
  padding-top: 4em;
  margin-bottom: -1.5em;
  gap: 5em;
}

.carousimgprime {
  height: 500px;
  width: 280px;
}

.carous4 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5em;
}


.carous-elt {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1em;
}

.carous-elt1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 0 0 auto;
  align-items: center;
  justify-items: center;
  text-align: center;
  gap: 1em;
}

.carousspan1 {
  width: 50px;
  text-align: center;
}

.subimg {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 2em;
  border-radius: 100%;
  background: rgba(255, 255, 255, 0.12);
  box-shadow: 0px 5.045px 21.019px 0px rgba(30, 215, 96, 0.08);
}

.subimgp {
  width: 30px;
  height: 30px;
}

.subtil {
  color: var(--White, #FFF);
  text-align: center;
  /*font-family: "Satoshi Variable";*/
  font-size: 18.497px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  /* 27.746px */
  align-self: stretch;
}

.subsubtil {
  width: 268px;
  color: rgba(255, 255, 255, 0.80);
  text-align: center;
  /*font-family: "Satoshi Variable";*/
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 21px */
  align-self: stretch;
}

.back5 {
  position: absolute;
  bottom: 0;
  left: 19em;
  width: 70%;
}

.why-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /*margin: 0 88px 0 88px;*/
  padding: 75px 15px 75px 15px;
  background-color: black;
}

.why-title {
  color: var(--White, #FFF);
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  /*font-family: "Satoshi Variable";*/
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
  /* 60px */
  /*font-size: 24px;*/
  /*font-weight: 600;*/
  /*line-height: 33.6px;*/
  /*text-align: center;*/
  width: 80%;
  margin: 0 0 16px 0;
  margin-top: 3em;
}

.why-titlee {
  color: var(--White, #FFF);
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  /*font-family: "Satoshi Variable";*/
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
  /* 60px */
  /*font-size: 24px;*/
  /*font-weight: 600;*/
  /*line-height: 33.6px;*/
  /*text-align: center;*/
  width: 80%;
  margin: 0 0 16px 0;
  margin-top: 3em;
}

.sub-why-title {
  color: #FFFFFFCC;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  /*font-family: "Satoshi Variable";*/
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  /* 60px */
  /*font-size: 24px;*/
  /*font-weight: 600;*/
  /*line-height: 33.6px;*/
  /*text-align: center;*/
  width: 80%;
  margin: 0 0 16px 0;
}

.sub-why-title2 {
  color: #FFFFFFCC;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  /*font-family: "Satoshi Variable";*/
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  /* 60px */
  /*font-size: 24px;*/
  /*font-weight: 600;*/
  /*line-height: 33.6px;*/
  /*text-align: center;*/
  width: 22%;
  margin: 0 0 16px 0;
}

.why-title1 {
  color: var(--White, #FFF);
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  /*font-family: "Satoshi Variable";*/
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
  /* 60px */
  width: 60%;
}

.why-title2 {
  color: var(--White, #FF5620);
  leading-trim: both;
  text-edge: cap;
  /*font-family: "Satoshi Variable";*/
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
}

.why-title3 {
  color: var(--White, #FFF);
  leading-trim: both;
  text-edge: cap;
  /*font-family: "Satoshi Variable";*/
  font-size: 48px;
  margin-top: 4em;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
}

.why-subtitle {
  font-size: 20px;
  font-weight: 400;
  line-height: 40px;
  text-align: center;
  padding-bottom: 24px;
}

.why-con {
  display: flex;
  align-items: center;
  justify-content: center;
}

.texpart {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
}

.visualpart {
  width: 356px;
  height: auto;
  margin-top: 92px;
  margin-left: 20px;
}

.servi1 {
  font-size: 19px;
  font-weight: 700;
  letter-spacing: -0.19px;
  line-height: 21.85px;
  color: #d1541f;
  margin-bottom: -36px;
  text-align: left;
}

.carou {
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  padding-left: 20em;
  gap: 5em
}

.placeholder {
  width: 100%;
  height: 80%;
  position: relative;
}

.largecircle {
  position: absolute;
  border: 1px black solid;
  border-radius: 50%;
  width: 500px;
  height: 500px;
  top: 0;
  margin-top: -5em;
  mask-image: linear-gradient(to top, transparent, black);
  transform: translateX(-15%);
}

.backg {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  mask-image: linear-gradient(to top, transparent, black);
  background: #FC263D;
}

.bigcircle {
  position: absolute;
  border: 1px black solid;
  border-radius: 50%;
  width: 400px;
  height: 400px;
  top: 0;
  margin-top: -4em;
  mask-image: linear-gradient(to top, transparent, black);
  transform: translateX(-5%);
}

.mediumcircle {
  position: absolute;
  border: 1px black solid;
  border-radius: 50%;
  width: 300px;
  height: 300px;
  top: 0;
  mask-image: linear-gradient(to top, transparent, black);
  transform: translateX(10%);
  margin-top: -1em;
}

.smallcircle {
  position: absolute;
  border: 1px black solid;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  top: 0;
  transform: translateX(40%);
  mask-image: linear-gradient(to top, transparent, black);
  margin-top: 1.5em;
}

.carouimg {
  width: 100%;
  height: auto;
  /*background: red;*/
  display: block;
  object-fit: cover;
  mask-image: linear-gradient(to top, transparent, black);
}


.carouimg2 {
  width: 100%;
  height: 100%;
  background: #482A22;
  padding-top: 1em;
  padding-right: 1em;
  padding-left: 1em;
  display: block;
  object-fit: fill;
  mask-image: linear-gradient(to top, transparent, black);
}

.carouimg22 {
  height: 100%;
  width: 800px;
  overflow: hidden;
  background: #1F1F1F;
  display: block;
  object-fit: fill;
  mask-image: linear-gradient(to top, transparent, black);
}


.carouimg3 {
  width: 800px;
  overflow: hidden;
  background: #1F1F1F;
  display: block;
  object-fit: fill;
  mask-image: linear-gradient(to top, transparent, black);
}

.carouspan {
  color: var(--White, #FFF);
  leading-trim: both;
  text-edge: cap;
  /*font-family: "Satoshi Variable";*/
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
  /* 30px */
  align-self: baseline;
}

.car-elt {
  border-radius: 32px;
  background: linear-gradient(180deg, rgba(20, 20, 20, 0.00) 42.86%, #141414 78.4%);
  width: 360px;
  height: 568px;
  flex-shrink: 0;
  padding-bottom: 2em;
  overflow: hidden;
  text-align: start;
}

.car-div {
  width: 60px;
  background: transparent;
  height: 30px;
}

.carouspan1 {
  color: var(--White, #FFF);
  /*font-family: "Satoshi Variable";*/
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
  /* 30px */
  align-self: start;
  padding-left: 5%;
}

.subcarouspan1 {
  color: #FFFFFFCC;
  /*font-family: "Satoshi Variable";*/
  font-size: 16px;
  font-style: normal;
  padding-top: 10px;
  font-weight: 400;
  line-height: 125%;
  /* 30px */
  padding-left: 5%;
  align-self: baseline;
}

.servi2 {
  font-size: 19px;
  font-weight: 700;
  letter-spacing: -0.19px;
  line-height: 21.85px;
  color: #3aa374;
  margin-bottom: -36px;
}

.servi3 {
  font-size: 19px;
  font-weight: 700;
  letter-spacing: -0.19px;
  line-height: 21.85px;
  color: #f7ca06;
  margin-bottom: -36px;
}

.servcta1 {
  max-width: 280px;
  background-color: #d1541f;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  padding: 16px;
  border: none;
  cursor: pointer;
  border-radius: 8px;
}

.servcta2 {
  max-width: 280px;
  background-color: #3aa374;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  padding: 16px;
  border: none;
  cursor: pointer;
  border-radius: 8px;
}

.servcta3 {
  max-width: 280px;
  background-color: #f7ca06;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  padding: 16px;
  cursor: pointer;
  border: none;
  border-radius: 8px;
}

.servih2 {
  font-size: 56px;
  font-weight: 700;
  letter-spacing: -0.56px;
  line-height: 64.4px;
  color: #1f2024;
}

.servdes {
  max-width: 592px;
  font-size: 20px;
  line-height: 28px;
  color: #626366;
}

.servcon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: -120px;
}

.servbo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.servbo span {
  max-width: 400px;
  text-align: center;
  margin: 28px;
}

.servcon span {
  width: 88px;
  height: 36px;
  border-radius: 8px;
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ff9f03;
  color: #fff;
}

.isl2 {
  max-width: 206px;
  max-height: 627px;
}

.why-ec {
  padding-top: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  list-style-type: none;
  padding-bottom: 48px;
}

.why-ec li {
  width: 294px;
  height: 294px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 2px 160px 2px;
  margin-right: 28px;
  cursor: pointer;
}

.why-ec li img {
  width: 200px;
  height: 200px;
  border-radius: 100px;
  margin: 8px 0 8px 0;
  transition: .3s ease-in-out;
  cursor: pointer;
}

.why-ec li:hover img {
  transform: scale(1.06);
}

.why-ec span {
  font-size: 21px;
  line-height: 16.46px;
  font-weight: 600;
  letter-spacing: -1.5;
  margin: 20px 0 2px 0;
}

.why-ec p {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5;
  text-align: center;
  width: 80%;
}

.freedown {
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  border-radius: 50px;
  border: 0.609px solid rgba(255, 255, 255, 0.16);
  background: #FF5620;
  color: white;
  z-index: 1000;
  cursor: pointer;
}

.premium-cta {
  position: relative;
  width: 100vw;
  /*display: flex;*/
  justify-content: center;
  align-items: center;
  padding: 60px 28px;
  color: #fff;
  background: black;
  text-align: center;
}

.premium-cta h2 {
  width: 496px;
  text-align: center;
  font-size: 44px;
  font-weight: 700;
  letter-spacing: -0.41px;
  line-height: 47.96px;
  margin-top: 12px;
}

.mock {
  margin-left: -100px;
  margin-right: 12px;
  margin-top: 10px;
}

.descri {
  width: 440px;
  text-align: left;
  font-size: 18px;
  line-height: 24.92px;
  font-weight: 400;
  margin: -8px 16px 56px 16px;
}

.rdiv {
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 28px;
  margin-right: 40px;
}

.pcta {
  display: flex;
  flex-direction: row;
}

.events-cta {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 88px 15px 75px 15px;
  background: black;
}

.events-cta2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 15px 75px 15px;
}

.partner-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 88px 15px 75px 15px;
}

.events-cta h3 {
  text-align: center;
  /* font-size: 35px; */
  line-height: 45px;
  font-weight: 700;
}

.events-cta p {
  width: 50%;
  text-align: center;
  font-size: 24px;
  font-weight: normal;
}

.isl {
  max-width: 346px;
  max-height: 667px;
  padding-left: 156px;
}

.txts {
  display: block;
}

.slh {
  display: flex;
  align-items: center;
  justify-content: start;
}

.roic {
  display: flex;
  width: 80px;
  height: 80px;
  margin-right: 28px;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  background-color: #120301;
}

.slh h3 {
  max-width: 420px;
}

.prh {
  text-align: center;
}

.prh h2 {
  font-size: 36px;
  font-weight: 700;
}

.prh span {
  max-width: 140px;
}

.prc {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 384px;
  height: 440px;
  border-radius: 8px;
  border-image: linear-gradient(45deg, #FF2447, #FF9D04);
  margin-right: 20px;
  box-shadow: 0px 5px 25px #00000033;
}

.prctit {
  font-size: 25px;
  font-weight: normal;
  margin: 8px 0 6px 0;
  color: #3A170D;
}

.prche {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.prcsub {
  font-size: 20px;
  font-weight: 600;
  margin-top: 6px;
}

.prc ul {
  list-style-type: none;
  margin-top: 2px;
  margin-bottom: 32px;
}

.prc ul li {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.pric {
  display: flex;
  align-items: baseline;
  justify-content: center;
  color: #fff;
  font-size: 14px;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background: linear-gradient(45deg, #FF2447, #FF9D04);
  margin-right: 24px;
}

.prcta {
  width: 120px;
  padding: 12px;
  border-radius: 6px;
  background: linear-gradient(45deg, #FF2447, #FF9D04);
  font-size: 16px;
  font-weight: 600;
  color: #fff;
}

.prcta2 {
  text-align: center;
  width: 120px;
  padding: 8px;
  border-radius: 6px;
  background: linear-gradient(45deg, #FF2447, #FF9D04);
  font-size: 20px;
  font-weight: 400;
  color: #fff;
}

.prb {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 120px;
}

.psl {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.psli {
  max-width: 48px;
  max-height: 56px;
  margin-right: 8px;
}


.partner-container h2 {
  width: 45%;
  text-align: center;
  font-size: 44px;
  font-weight: 700;
}

.slide-container {
  position: relative;
  overflow: hidden;
  padding: 48px 0 20px 0;
}

.prnt-lg {
  filter: grayscale(100%);
  transition: .3s ease-out;
  margin-right: 24px;
  cursor: pointer;
  max-width: 250px;
  max-height: 100px;
}

.prnt-lga {
  filter: grayscale(100%);
  transition: .3s ease-out;
  margin-right: 24px;
  cursor: pointer;
  max-width: 136px;
  max-height: 146px;
}


.prnt-lg:hover {
  filter: grayscale(0%);
}


.foooter {
  position: relative;
  width: 100%;
  /* background: black; */
  color: #fff;
  padding-bottom: 1em;
}

.footer1 {
  position: relative;
  bottom: 0;
  width: 90%;
  margin: auto;
  /*padding: 28px 250px 70px 150px;*/
  display: flex;

  align-items: start;
  justify-content: space-between;
  gap: 0.1em;
  color: #fff;
}

.copywright {
  width: 90%;
  margin: 1rem auto 0 auto;
  font-weight: 400;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  position: relative;
}

.langg {
  width: 2em;
  height: 2em;
  flex-shrink: 0;
  display: flex;
  border-radius: 1000px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
}

.langgimg {
  width: 60px;
  height: 60px;
}

.legals {
  display: flex;
  justify-content: space-around;
  width: 100%;
  color: rgba(255, 255, 255, 0.80);
  leading-trim: both;
  text-edge: cap;
  /*font-family: "Satoshi Variable";*/
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  align-items: center;
  line-height: 150%;
  /* 24px */
}

.legals a {
  text-decoration: underline !important;
}

.languages {
  display: flex;
  align-items: center;
  gap: 1em;
  padding: 0.5em;
  border-radius: 1000px;
  background: rgba(255, 255, 255, 0.12);
}

.languages select {
  -webkit-appearance: menulist-button;
  /*appearance: none;*/
  background: transparent;
}

.languages img {
  max-width: none;
}

.languages span {
  color: var(--White, #FFF);
  leading-trim: both;
  text-edge: cap;
  /*font-family: "Satoshi Variable";*/
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  /* 24px */
}

.ftr-logoo {
  font-size: 20px;
  font-weight: 600;
  margin-left: 1em;
  padding-right: 70px;
}

.ftr-logoo-img {
  width: 148px;
}

.ftr-contentt {
  width: auto;
  position: sticky;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
}

.ftr-contentt2 {
  width: auto;
  position: sticky;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;

}

.cont {
  color: rgba(255, 255, 255, 0.80);
  leading-trim: both;
  text-edge: cap;
  /*font-family: "Satoshi Variable";*/
  font-size: 15px;
  font-style: normal;
  font-weight: 800;
  line-height: 150%;
  /* 21px */
  margin-bottom: 1em;
}

.ftr-contentt2 h3 {
  width: 100%;
  /*margin-left: 22px;*/
  color: var(--White, #FFF);
  text-align: left;
  leading-trim: both;
  text-edge: cap;
  /*font-family: "Satoshi Variable";*/
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.ftr-contentt2 ul {
  margin: 0px;
  padding: 0px;
}

.ftr-contentt2 ul li {
  color: rgba(255, 255, 255, 0.80);
  text-align: left;
  leading-trim: both;
  text-edge: cap;
  /*font-family: "Satoshi Variable";*/
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
  /* 24px */
  /*margin: 6px;*/
  width: 200px;
  cursor: pointer;
  list-style-type: none;
  /*font-size: 17px;*/
  margin: 0 8px 8px 0;
  /*font-weight: 500;*/
}


.ftr-contentt h3 {
  width: 100%;
  /*margin-left: 22px;*/
  color: var(--White, #FFF);
  text-align: left;
  leading-trim: both;
  text-edge: cap;
  /*font-family: "Satoshi Variable";*/
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0.5em;
}

.ftr-contentt ul {
  margin: 0px;
  padding: 0px;
}

.ftr-contentt ul li {
  color: rgba(255, 255, 255, 0.80);
  text-align: left;
  leading-trim: both;
  text-edge: cap;
  /*font-family: "Satoshi Variable";*/
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
  /* 24px */
  /*margin: 6px;*/
  width: 135px;
  cursor: pointer;
  list-style-type: none;
  /*font-size: 17px;*/
  margin: 0 8px 8px 0;
  /*font-weight: 500;*/
}

.follow {
  display: flex;
  gap: 0.25em;
  margin-left: -0.5em;

}

.follow a {
  text-decoration: none;
  color: white;
}

.footer1 a {
  text-decoration: none;
  color: #fff;
}

.foooter a {
  text-decoration: none;
  color: #fff;
}

.downbtn {
  display: flex;
  gap: 1em;
}

.ftr-contentt3 {
  width: 120px;
  position: sticky;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 1em;
  margin-bottom: 5em;
}

.btn-icon {
  filter: invert(100%);
  margin-right: 8px;
}

.lastt {
  display: flex;
}

.color-text {
  background-image: -webkit-linear-gradient(-92deg, #d11c4c, #feab3a);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: hue 8s infinite cubic-bezier(.23, 1, .32, 1);
}

.serv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 56px;
}

.servimg {
  width: 240px;
  height: 240px;
  border-radius: 120px;
  background-color: #f5db20;
}

.servtitle {
  font-size: 24px;
  font-weight: 700;
  margin: 16px;
}

.servtext {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  max-width: 260px;
  text-align: center;
}

.fpc {
  width: 1191px;
  height: 880px;
  padding: 0 64px 80px 64px;
  background-color: #120301;
  border-radius: 8px;
}

.fph {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fph h3 {
  /* font-size: 84px; */
  font-weight: 700;
  line-height: 77, 56px;
  letter-spacing: -0.84px;
  color: #fff;
}

.fph span {
  width: 190px;
  height: 64px;
  padding: 14px;
  cursor: pointer;
  background-color: #21D4FD;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  border-radius: 6px;
}

.fph a {
  text-decoration: none;
}

.fpb {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -24px;
}

.scd {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
}

.sc {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sci {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 39px;
  font-weight: 700;
  color: #fff;
  width: 72px;
  height: 72px;
  border-radius: 50%;
  background-color: #4d4d51;
  margin-right: 24px;
}

.scdc {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
}

.scdc h5 {
  font-size: 26px;
  font-weight: 700;
  line-height: 30px;
  color: #fff;
  margin-bottom: -2px;
}

.scdc p {
  text-align: start;
  width: 420px;
  font-size: 20px;
  line-height: 28px;
  color: #e5e7eb;
}

.fpgif {
  width: 645px;
  height: 470px;
}

.fw {
  margin-bottom: -16px;
}

.plbc {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.plbcv {
  width: 90px;
  height: 90px;
  border-radius: 50%;
}

.plb {
  width: 68px;
  height: 68px;
  border-radius: 50%;
  max-width: 100%;
  max-height: 100%;
  border: 0px solid #eee;
}

.plbv {
  width: 68px;
  height: 68px;
  border-radius: 50%;
  max-width: 100%;
  max-height: 100%;
  border: 0px solid #eee;
  font-size: 17px;
  font-weight: 500;
}

.linkerr {
  color: white;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  text-decoration: none;
  z-index: 1000;
  padding: 0px;
  cursor: pointer;
}

.fond {
  position: absolute;
  top: 0;
  /*z-index: 3;*/
  width: 100vw !important;
  height: 100%;
  background: url("../assets/new_images/noise.svg");
  background-size: cover;
  background-position: center;
  flex-shrink: 0;
}

.fond2 {
  position: absolute;
  top: 0;
  width: 100vw !important;
  background: url("../assets/new_images/light_from_scy.svg");
  background-position: center;
  height: 100%;
  background-size: cover;
  /*z-index: 3;*/
  flex-shrink: 0;
}

.fond3 {
  background: url("../assets/new_images/bacck1.svg");
  position: absolute;
  background-size: contain;
  width: 759px;
  height: 642px;
  top: 10em;
  left: 4em;
  background-position: left;
  background-repeat: no-repeat;
  flex-shrink: 0;
}

.fond5 {
  position: absolute;
  background: url("../assets/new_images/bacck2.svg");
  width: 700px;
  height: 903px;
  top: -20em;
  right: 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
}

.fond4 {
  position: absolute;
  background: url("../assets/new_images/noise.svg");
  top: 0;
  left: 0;
  width: 100vw !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 95%;
  flex-shrink: 0;
}

.fond6 {
  background: url("../assets/new_images/last.svg") bottom/contain no-repeat;
  width: 100% !important;
  height: 100%;
  /*display: block;*/

}

@media screen and (max-width: 555px) {

  .fond6 {
    background: url("../assets/illustrations/mobile_footer.png") center/contain no-repeat;

  }
}

.pslb {
  position: absolute;
  border-radius: 50%;
}

.pl1 {
  top: 20%;
  left: 25%;
  width: 68px;
  height: 68px;
}

.pl2 {
  top: 60%;
  left: 20%;
  width: 68px;
  height: 68px;
}

.pl3 {
  top: 60%;
  left: 60%;
  width: 68px;
  height: 68px;
}

.pl4 {
  top: 20%;
  left: 60%;
  width: 68px;
  height: 68px;
}

.pl5 {
  top: 40%;
  left: 4%;
  width: 68px;
  height: 68px;
}

.pl6 {
  top: 80%;
  left: 40%;
  width: 68px;
  height: 68px;
}

.pl7 {
  top: 40%;
  left: 78%;
  width: 68px;
  height: 68px;
}

.pl8 {
  left: 86%;
  width: 68px;
  height: 68px;
}

.pl9 {
  padding-top: 280px;
  left: 68%;
  width: 68px;
  height: 68px;
}

.pl10 {
  padding-bottom: 100px;
  left: 85%;
  width: 68px;
  height: 68px;
}

.pl11 {
  padding-top: 280px;
  right: 10%;
  width: 68px;
  height: 68px;
}

.lph {
  position: absolute;
  top: -4%
}

.prevCard {
  left: 0;
  opacity: 0;
  z-index: -1;
}

.activeCard {
  left: 50%;
  transform: translateX(-50%);
}

.nextCard {
  left: 100%;
  transform: translateX(-100%);
  opacity: 0;
  z-index: -1;
}

.flex-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

}

.mt-4 {
  cursor: pointer;
  margin-top: 4px;
}

.leftBtn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: -0.4rem;
  width: 48px;
  height: 48px;
  background-color: #fff;
  border-radius: 24px;
  box-shadow: 0px 5px 25px #00000033;
  z-index: 5;
}

.rightBtn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -0.4rem;
  width: 48px;
  height: 48px;
  background-color: #fff;
  border-radius: 24px;
  box-shadow: 0px 5px 25px #00000033;
  z-index: 5;
}

.card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 396px;
  height: 360px;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

article {
  position: absolute;
  width: 280px;
  height: 80%;

  transition: all 0.8s ease-in-out;
}

.paginator {
  display: flex;
  justify-content: space-between;
  align-items: center;

  position: absolute;
  bottom: 0.4%;
  left: 50%;
  transform: translateX(-50%);

  width: 35%;
  max-width: 30rem;
  height: 2rem;
}

.hr {
  position: absolute;

  width: 0%;
  height: 2px;

  background-color: #eee;
}

.dot {
  height: 14px;
  width: 14px;

  border-radius: 50%;

  background-color: #bebebe;
  z-index: 1;

  transition: all 1s;
}

.dot:hover {
  cursor: pointer;
}

.dot.active {
  box-shadow: 0 0 0 4px #bebebe;
  background-color: white;
}

.mb-12 {
  margin-top: -12px;
}

/* for desktop */
.whatsapp_float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 95px;
  right: 20px;
  background-color: #25d366;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  z-index: 14;
}

.whatsapp-icon {
  margin-top: 16px;
}


@media only screen and (max-width: 1600px) {


  .hero-title {
    width: 80%;
    letter-spacing: -0.41px;
    color: var(--White, #FFF);
    font-size: 3rem;
    font-style: normal;
    font-weight: 700;
  }


  .hero-subtitle {
    color: rgba(255, 255, 255, 0.80);
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    width: 65%;
    margin-top: 2em;
    z-index: 2;
  }

  .btn-freedown {
    display: flex;
    padding: 1rem 2rem;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border: 0px;
    border-radius: 10rem;
    background: #FF5620;
    color: var(--White, #FFF);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    position: relative;
    cursor: pointer;
    text-decoration: none;
  }

  .btn-disc {
    display: flex;
    padding: 1rem 2rem;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 10rem;
    border: 1px solid var(--White, #FFF);
    color: var(--White, #FFF);
    font-size: 1rem;
    font-style: normal;
    background-color: transparent;
    font-weight: 400;
    cursor: pointer;
    text-decoration: none;
  }


}

@media only screen and (max-width: 1500px) {


  .first-box {
    width: 50%;
    height: 100%;
    background: url("../assets/new_images/immg.jpg");
    border-radius: 50px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    position: relative;
    overflow: hidden;
  }

  .device-grid {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 50px;
    background: #121212;
    padding-left: 4%;
  }

  .main-row {
    width: 93vw;
    height: 60vh;
    justify-content: center;
    align-content: center;
    display: flex;
    gap: 4em;
  }

}

/* for mobile */
@media only screen and (max-width: 1300px) {

  .carouspan {
    color: var(--White, #FFF);
    leading-trim: both;
    text-edge: cap;
    /*font-family: "Satoshi Variable";*/
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%;
    /* 30px */
    align-self: baseline;
  }

  .imgbtn {
    width: 30px;
    height: 30px;
    position: absolute;
    right: 0;
    margin-right: 0.5em;
  }

  .btn-freedown {
    display: flex;
    width: 250px;
    position: relative;
    height: 60px;
  }

  .btn-disc {
    position: relative;
    display: flex;
    width: 200px;
    height: 60px;
  }

  .btntext {
    position: absolute;
    left: 0;
    margin-left: 0.5em;
    font-size: 18px;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    text-decoration: none;
  }

}

/* for mobile */
@media only screen and (max-width: 1100px) {

  .nav-logoo {
    float: left;
    display: block;
    margin-left: 40px;
    z-index: 8;
    margin-top: -1.7em;
  }

  .hero-subtitle2 {
    width: 50%;
    color: rgba(255, 255, 255, 0.80);
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    margin-top: -2em;
    /*font-family: "Satoshi Variable";*/
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 27px */
  }

  .device-grid-container {
    padding-top: 3em;
  }

  .legals {
    gap: 1em;
    flex-wrap: wrap;
  }

  .carou {
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    padding-left: 10em;
    gap: 1em
  }

  .car-elt {
    border-radius: 32px;
    background: linear-gradient(180deg, rgba(20, 20, 20, 0.00) 42.86%, #141414 78.4%);
    width: 240px;
    height: 360px;
    flex-shrink: 0;
    padding-bottom: 3.5em;
    overflow: hidden;
    text-align: center;
  }

  .carouspan1 {
    color: var(--White, #FFF);
    /*font-family: "Satoshi Variable";*/
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%;
    /* 30px */
    align-self: baseline;
  }

  .carous2 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    margin-bottom: -1.5em;
    gap: 0.1em;
  }

  .whatsapp-icon {
    margin-top: 10px;
  }

  .botsect1 {
    width: 100%;
    padding-top: 2em;
    position: relative;
    /*justify-content: center;*/
    overflow: hidden;
    /*z-index: 4;*/
  }

  .phone_in_hand {
    position: relative;
    /*justify-self: center;*/
    /*align-self: center;*/
    left: 40%;
    margin-bottom: -1em;
    /*flex-shrink: 0;*/
  }

  .discover {
    position: absolute;
    top: 50%;
    left: 30%;
  }

  .disk {
    position: absolute;
    /*display: none;*/
    top: 50%;
    left: 0;
  }

  .playlist {
    position: absolute;
    right: 10%;
    display: none;
    top: 40%;
  }

  .song1 {
    position: absolute;
    top: 72%;
    display: none;
    right: 5%;
  }

  .song2 {
    position: absolute;
    top: 25%;
    display: none;
    left: 15%;
  }

  .listening1 {
    display: none;
    position: absolute;
    right: 5%;
    top: 2%;
  }

  .listening2 {
    display: none;
    position: absolute;
    top: 2%;
    left: 15%;
  }

  .event {
    display: none;
    position: absolute;
    top: 2%;
    left: 35%;
  }


  .whatsapp_float {
    width: 40px;
    height: 40px;
    bottom: 6px;
    right: 10px;
    font-size: 22px;
  }

  .freedown {
    padding: 10px 18px;
    justify-content: center;
    margin-right: 1%;
    align-items: center;
    border-radius: 24px;
    border: 0.609px solid rgba(255, 255, 255, 0.16);
    background: #FF5620;
    color: white;
    position: relative;
    top: -4.5em;
    z-index: 1000;
  }

  .hero-title {
    padding-top: 1em;
    width: 85%;
    margin: auto;
    font-size: 30px;
    letter-spacing: -0.41px;
    line-height: 48.76px;
  }

  .hero-subtitle {
    width: 70%;
    margin: auto;
    margin-top: -2em;
    font-size: 18px;

  }

  .premium-cta {
    width: 100%;
    margin-left: 20px;
  }

  .fpd {
    width: 80px;
    text-align: left;
    text-overflow: unset;
  }

  .fph h3 {

    font-size: 40px;
  }

  .sc {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .scdc h5 {
    width: 100%;
    text-align: center;
  }

  .gifco {
    position: relative;
  }
}

.mr-20 {
  margin-right: 20px;
}

@keyframes hue {
  from {
    -webkit-filter: hue-rotate(0deg);
  }

  to {
    -webkit-filter: hue-rotate(-360deg);
  }
}


@keyframes float {
  0% {
    transform: translatey(0px);
  }

  50% {
    transform: translatey(-25px);
  }

  100% {

    transform: translatey(0px);
  }
}

@-webkit-keyframes rotating

/* Safari and Chrome */
  {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-250px * 7));
  }
}

.rotating {
  -webkit-animation: rotating 20s linear infinite;
  -moz-animation: rotating 20s linear infinite;
  -ms-animation: rotating 20s linear infinite;
  -o-animation: rotating 20s linear infinite;
  animation: rotating 20s linear infinite;
}

.slide-track {
  animation: scroll 40s linear infinite;
  display: flex;
  width: calc(250px * 18);
}

.slide {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 100px;
  margin: 0 4px 0 4px;
}

.fadel {
  width: 24px;
  height: 24px;
  position: absolute;
  left: 2;
  top: 0;
  background: linear-gradient(to left, red 100%, transparent 0%);
}

@media only screen and (max-width: 1000px) {
  .carous2 {
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    align-items: center;
    justify-content: center;
    margin: auto;
    margin-bottom: -1.5em;
    gap: 0.1em;
  }
}

@media only screen and (max-width: 500px) {


  .subcarouspan1 {
    color: #FFFFFFCC;
    /*font-family: "Satoshi Variable";*/
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 30px */
    align-self: start;
    text-align: left;
  }

  .device-grid {
    display: none;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    border-radius: 50px;
    background: #121212;
    padding-left: 4%;
  }

  .first-box {
    width: 100vw;
    height: 100%;
    background: url("../assets/new_images/immg.webp");
    mask-image: linear-gradient(to top, transparent, black);
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 0;
    background-position: top;
    position: relative;
    overflow: hidden;
  }

  .mob {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin-top: -10em;
  }

  .main-row {
    width: 93vw;
    height: 80vh;
    justify-content: center;
    align-content: center;
    display: flex;
    margin-top: -3em;
  }

  /* .fond6 {
    display: none;
  } */

  .carous2 {
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    align-items: center;
    justify-content: center;
    margin: auto;
    margin-top: -1.5em;
    margin-bottom: -1.5em;
    gap: 0.1em;
  }

  .sub-why-title {
    color: #FFFFFFCC;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
    width: 95%;
    margin-top: -3.5em;
  }

  .carous-elt {
    gap: 0.5em;
  }

  .subimg {
    flex-shrink: 0;
    display: flex;
    gap: 0.1em;
    justify-content: center;
    align-content: center;
    padding: 1em;
  }

  .subimgp {
    width: 24px;
    height: 24px;
  }

  .subtil {
    color: var(--White, #FFF);
    text-align: center;
    /*font-family: "Satoshi Variable";*/
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    /* 27.746px */
    align-self: stretch;
  }

  .subsubtil {
    width: 140px;
    color: rgba(255, 255, 255, 0.80);
    text-align: center;
    /*font-family: "Satoshi Variable";*/
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 21px */
    align-self: stretch;
  }

  .premium-cta {
    width: 100%;
  }

  .btn-freedown {
    display: flex;
    padding: 20px 32px;
    justify-content: center;
    align-items: center;
    border-radius: 54px;
    background: #FF5620;
    color: var(--White, #FFF);
    leading-trim: both;
    text-edge: cap;
    /*font-family: "Satoshi Variable";*/
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    position: relative;
    font-size: 14px;
    width: 195px;
    height: 40px;
  }

  .hero-title {
    width: 60%;
    margin: auto;
    font-size: 24px;
    letter-spacing: -0.41px;
    line-height: 30px;
    align-content: center;
    text-align: center;
    margin-bottom: -1em;
  }

  .hero-subtitle {
    width: 88%;
    margin: auto;
    text-align: center;
    margin-top: 4em;
    font-size: 14px;
    line-height: 21px;
  }


  /*.nav-logoo{*/
  /*  margin-top: 1em;*/
  /*  position: absolute;*/
  /*  top: 0;*/
  /*  left: 1em;*/
  /*}*/

  .device-grid-container {
    overflow: hidden;
    height: fit-content;
  }

  .row {
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    margin-top: 2em;
    gap: 1em;
  }

  .imgbtn {
    width: 16px;
    height: 16px;
    position: absolute;
    right: 1em;
  }

  .btn-disc {
    display: flex;
    font-size: 14px;
    position: relative;
    width: 162px;
    height: 40px;
  }

  .btn-disc3 {
    display: flex;
    font-size: 14px;
    position: relative;
    width: 195px;
    height: 40px;
  }

  .btn-disc2 {
    display: flex;
    font-size: 14px;
    position: relative;
    width: 110px;
    height: 40px;
    cursor: pointer;
  }

  .btntext {
    position: absolute;
    left: 1em;
    font-size: 14px;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    text-decoration: none;
  }

  .btntext2 {
    position: absolute;
    left: 1em;
    font-size: 14px;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
  }

  .btntext3 {
    position: absolute;
    font-size: 14px;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
  }

  .carous {
    display: flex;
    margin-bottom: -3em;
    margin-top: 2em;
    gap: 1em;
    background: transparent;
    overflow-x: auto;
    width: 530px;
    right: 0;
    margin-left: -7em;
    position: relative;
  }

  .carous3 {
    display: none;
    /*align-items: center;*/
    /*justify-content: center;*/
    /*width: 99%;*/
    /*margin: auto;*/
    /*margin-top: 2.5em;*/
    /*margin-bottom: -1.5em;*/
    /*gap: 2em;*/
  }

  .carousimgprime {
    padding-bottom: 2em;
    height: 300px;
    width: 180px;
  }

  .back5 {
    position: absolute;
    bottom: 0;
    left: -15em;
    width: 1000px;
  }

  .carousimg {
    width: 60px;
  }

  .carousspan {
    font-size: 12px;
  }

  .carouimg2 {
    width: 100%;
    height: 100%;
    background: #25370E;
    display: block;
    object-fit: fill;
    mask-image: linear-gradient(to top, transparent, black);
  }

  .carouimg22 {
    width: 100%;
    height: 100%;
    background: #3B3DD9;
    display: block;
    object-fit: fill;
    mask-image: linear-gradient(to top, transparent, black);
  }

  .imgbtn {
    width: 18px;
    height: 18px;
  }

  .hero-subtitle2 {
    width: 100%;
    margin-top: -3.5em;
    font-size: 15px;
  }

  .hero-subtitlee2 {
    width: 100%;
    margin-top: -3.5em;
    font-size: 15px;
  }

  .hero-subtitle3 {
    margin-top: -2em;
    font-size: 14px;
    width: 95%;
  }

  .hero-subtitle6 {
    margin-top: 1em;
    font-size: 16px;
    font-weight: 400;
    width: 90%;
  }

  .hero-subtitlee6 {
    margin-top: 1em;
    font-size: 16px;
    font-weight: 400;
    width: 95%;
  }

  .why-head {
    align-items: center;
    justify-content: center;
    margin: auto;
  }

  .why-titlee {
    font-size: 26px;
    width: 95%;
    margin-bottom: 3em;
    margin-top: 4em;
  }

  .why-head1 {
    width: 100%;
    margin-top: -2em;
  }

  .why-title {
    font-size: 24px;
    width: 95%;
    margin-bottom: 3em;
    margin-top: 4em;
  }

  .why-title1 {
    color: var(--White, #FFF);
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    /*font-family: "Satoshi Variable";*/
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%;
    /* 60px */
    width: 95%;
  }

  .why-title2 {
    color: var(--White, #FF5620);
    leading-trim: both;
    text-edge: cap;
    /*font-family: "Satoshi Variable";*/
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%;
    width: 95%;
  }

  .why-title3 {
    color: var(--White, #FFF);
    leading-trim: both;
    text-edge: cap;
    /*font-family: "Satoshi Variable";*/
    font-size: 24px;
    margin-top: 5em;
    font-style: normal;
    font-weight: 700;
    line-height: 125%;
    width: 95%;
  }

  .why-subtitle {
    font-size: 20px;
    font-weight: 400;
    line-height: 40px;
    text-align: center;
    padding-bottom: 24px;
  }

  .why-subtitlee {
    font-size: 20px;
    font-weight: 400;
    line-height: 40px;
    text-align: center;
    padding-bottom: 24px;
  }

  .carou {
    display: flex;
    flex-wrap: nowrap;
    margin-left: 11em;
  }

  .carouspan1 {
    width: 100%;
    text-align: left;
    font-size: 13px;
  }

  .car-elt {
    width: 171px;
    height: 269px;
    border-radius: 20px;
    text-align: left;
  }

  .carous-elt1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-items: center;
    text-align: center;
    gap: 0.5em;
  }

  .placeholder {
    width: 100%;
    height: 80%;
    position: relative;
  }

  .placeholder1 {
    width: 100%;
    height: 80%;
    position: relative;
  }

  .ftr-content3 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1em;
    margin-top: 1em;
    margin-bottom: 0;
    margin-left: -1em;
  }

  .legals {
    padding: 1em;
    width: 80%;
    margin: auto;
  }

  .freedown {
    height: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    justify-content: center;
    margin-right: 1em;
    font-size: 13px;
    margin-top: -1em;
  }

  .botsect1 {
    width: 100%;
    padding-top: 2em;
    position: relative;
    /*justify-content: center;*/
    overflow: hidden;
    /*z-index: 4;*/
  }

  .phone_in_hand {
    position: relative;
    /*justify-self: center;*/
    /*align-self: center;*/
    left: 26%;
    /*flex-shrink: 0;*/
    width: 343.32px;
    height: 487.99px;
  }

  .discover {
    display: none;
  }

  .disk {
    position: absolute;
    /*display: none;*/
    bottom: 3em;
    left: 1em;
    width: 110px;
    height: 110px;
  }

  .playlist {
    position: absolute;
    right: 12%;
    display: block;
    top: 25%;
    width: 80px;
    height: 80px;
  }

  .song1 {
    position: absolute;
    bottom: 0;
    right: 5em;
  }

  .song2 {
    position: absolute;
    top: 25%;
    display: none;
    left: 15%;
  }

  .listening1 {
    display: block;
    position: absolute;
    right: 5%;
    top: 2%;
    width: 70px;
    height: 70px;
  }

  .listening2 {
    display: block;
    position: absolute;
    top: 2%;
    left: 10%;
    width: 70px;
    height: 70px;
  }

  .event {
    display: block;
    position: absolute;
    top: 15%;
    left: 28%;
    height: 50px;
    width: 50px;
  }

  .ftr-logo {
    font-size: 14px;
    font-weight: 600;
    position: absolute;
    top: 1em;
    left: 3em;
  }

  .ftr-logo-img {
    width: 100px;
  }

  .links {
    width: 30px;
    height: 30px;
  }

  .links5 {
    width: 30px;
    height: 30px;
  }

  .links3 {
    width: 50px;
    height: 50px;
  }

  .links1 {
    width: 30px;
    height: 30px;
  }

  .links4 {
    width: 25px;
    height: 25px;
  }


  .follow {
    /* gap: 0.1em; */
    margin-left: -0.5em;
  }

  /*---------------------*/

  .copywright {
    width: 100%;
    font-weight: 400;
    font-size: 22px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background: #313131;
    margin-bottom: -2em;
  }

  .langg {
    width: 1em;
    height: 1em;
    flex-shrink: 0;
    background: #3aa374;
    border-radius: 1000px;
  }

  .legals {
    width: 100%;
    display: flex;
    gap: 0.5em;
    color: rgba(255, 255, 255, 0.80);
    leading-trim: both;
    text-edge: cap;
    /*font-family: "Satoshi Variable";*/
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 24px */
  }

  .cpwright {
    font-size: 17px;
  }

  .legals a {
    text-decoration: underline !important;
  }

  .languages {
    display: flex;
    align-items: center;
    gap: 0.2em;
    padding: 0.3em;
    border-radius: 1000px;
    background: rgba(255, 255, 255, 0.12);
    height: 30px;
    width: 110px;
  }

  .languages span {
    color: var(--White, #FFF);
    leading-trim: both;
    text-edge: cap;
    /*font-family: "Satoshi Variable";*/
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    /* 24px */
  }


  .fond3 {
    background: url("../assets/new_images/bacck1.svg");
    position: absolute;
    background-size: contain;
    width: 900px;
    height: 480px;
    margin-left: -2em;
    left: -3em;
    top: 1em;
    background-position: left;
    background-repeat: no-repeat;
    flex-shrink: 0;
  }

  .fond5 {
    position: absolute;
    background: url("../assets/new_images/bacck2.svg");
    width: 100vw !important;
    height: 1090px;
    bottom: 0;
    display: none;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top;
  }

  .fond4 {
    position: absolute;
    background: url("../assets/new_images/noise.svg");
    top: 0;
    width: 100vw !important;
    background-repeat: no-repeat;
    background-size: cover;
    display: none;
    background-position: center;
    height: 1090px;
    flex-shrink: 0;
  }


  .lsdro1 {
    border: none;
    background-color: transparent;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    margin-right: 2px;
    color: white;
    outline: none;
    padding: 0px;
  }

  .lsdrp {
    border: none;
    background-color: transparent;
    outline: none;
    padding: 0px;
    font-size: 14px;
    font-weight: 600;
    margin-top: 16px;
    margin-left: 20px;
  }

  .carouimg3 {
    width: 400px;
    overflow: hidden;
    background: red;
    display: block;
    object-fit: contain;
    mask-image: linear-gradient(to top, transparent, black);
  }

  .car-div {
    width: 30px;
    background: transparent;
    height: 30px;
  }


  .footer1 {
    position: relative;
    width: 90%;
    margin: auto;
    /*padding: 28px 250px 70px 150px;*/
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.1em;
    color: #fff;
    padding-bottom: 1em;
  }

  .foooter {
    position: relative;
    width: 100%;
    /* background: #141414; */
    color: #fff;
  }

  .cont {
    font-weight: 500;
  }

  .ftr-contentt2 h3 {
    font-weight: 700;
  }

  .ftr-contentt2 ul li {
    font-weight: 100;
  }


  .ftr-contentt h3 {
    font-weight: 700;
  }

  .ftr-contentt ul li {
    font-weight: 100;
  }

}


@media only screen and (max-width: 500px) {

  .nav-logoo {
    position: absolute;
    top: 1.8em;
    left: 1em;
  }

  .ftr-logoo {
    width: 50%;
    font-size: 14px;
    font-weight: 600;
    /* position: absolute;
    top: 1.5em;
    left: 1.5em; */
  }

}

@media only screen and (max-width: 390px) {

  .ftr-logoo {
    font-size: 14px;
    font-weight: 600;
    /* position: absolute;
    top: 1.5em;
    left: 1em; */
  }

  .first-box {
    width: 100vw;
    height: 100%;
    background: url("../assets/new_images/immg.webp");
    mask-image: linear-gradient(to top, transparent, black);
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 0px;
    background-position: top;
    position: relative;
    overflow: hidden;
  }

  .main-row {
    width: 93vw;
    height: 80vh;
    justify-content: center;
    align-content: center;
    display: flex;
    margin-top: -3em;
  }


  .nav-logoo {
    margin-top: -0.3em;
    position: absolute;
    top: 0;
  }

  .freedown {
    height: 35px;
    text-align: center;
    display: flex;
    justify-content: center;
    margin-right: 2em;
    margin-top: -4em;
    font-size: 11px;
  }

  .fond {
    height: 100%;
  }

  .fond2 {
    height: 100%;
  }

  .carou {
    display: flex;
    flex-wrap: nowrap;
    margin-left: 15em;
  }

  .btn-freedown {
    display: flex;
    padding: 20px 32px;
    justify-content: center;
    align-items: center;
    border-radius: 54px;
    background: #FF5620;
    color: var(--White, #FFF);
    leading-trim: both;
    text-edge: cap;
    /*font-family: "Satoshi Variable";*/
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    position: relative;
    font-size: 10px;
    width: 165px;
    height: 30px;
  }

  .btntext {
    position: absolute;
    left: 0.5em;
    font-size: 12px;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    text-decoration: none;
  }

  .imgbtn {
    width: 14px;
    height: 14px;
    margin-right: 0;
  }

  .btn-disc {
    display: flex;
    font-size: 14px;
    position: relative;
    width: 140px;
    height: 30px;
  }

}

.active-dash-link{
  color: white;
}
.not-active-dash-link{
  color:#808080;
}