.artsect{
    display: flex;
    width: 90%;
    position: relative;
    align-items: center;
    margin: auto;
    margin-top: 0.5em;
}

.userprofile{
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    border-radius: 60px;
}

.arttyp{
    display: flex;
    flex-direction: column;
    margin-left: 1em;
}

.arttitle{
    color: gray;
    letter-spacing: 5px;
}

.artname{
    color: white;
    font-size: 18px;
    font-weight: 700;
    display: flex;
}

.certif{
    margin-left: 0.2em;
}

.dropd{
    display: flex;
    justify-content: center;
    position: absolute;
    top: 30%;
    right: 0;
    cursor: pointer;
}

.colart{
    margin-top: 2em;
    width: 100%;
    display: flex;
    justify-content: center;
}

.collogo{
    width: 55px;
}

.coltyp{
    margin-left: .2em;
    font-size: 16x;
    color: white;
}

.menuP{
    margin-top: 3em;
    color: gray;
    letter-spacing: 4px;
    font-size: 10px;
    width: 100%;
    justify-content: center;
    text-align: left;
    margin-left: 1.5em;
    margin-bottom: 1em;
}