.footerContainer {
  width: 100%;
  margin-top: 5px;
  padding-top: 50px;
  border-top: 1px solid gray;
}
.footerContainer1 {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
  overflow: auto;
  align-items: start;
}

.footerBlock {
  display: flex;
  flex-direction: row;
  gap: 1em;
  margin: 10px auto;
}

@media screen and (max-width: 1250px) {
  .footerBlock {
    flex-direction: column;
    flex-wrap:wrap;
  }
  .footerContainer1 {
    flex-direction: row;
    align-items: start;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 600px) {
  .footerContainer1 {
    flex-wrap: wrap;
    overflow: hidden;
    align-items: start;
    justify-content: end;
    margin-right: 4em;
  }

  .footerBlock {
    width: 160px;
    align-items: start;
    flex-direction: revert;
    flex-wrap: wrap;
    margin-right: 0.5em;
  }

  .footerBlock:first-child{
    width: 43%;
    display: block;
    order: -1;
  }

  .footerBlock:last-child{
    order: -1;
  }

  .footerContent {
    width: fit-content;
  }
}

@media screen and (max-width: 390px) {
  .footerContainer1 {
    flex-wrap: wrap;
    overflow: auto;
    align-items: start;
    justify-content: end;
    margin-right: 1em;
  }
}

@media screen and (max-width: 360px) {
  .footerBlock {
    width: 150px;
    align-items: start;
    flex-direction: revert;
    flex-wrap: wrap;
    margin-right: 0.5em;
  }
}