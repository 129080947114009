.main-dash{
  width: 100%;
  height: 100%;
}

.main-dash::-webkit-scrollbar{
  display: none;
}

.search-utils{
  width: 100%;
  margin-top: 1em;
  padding-left: 1em;
  padding-right: 1em;
  display: flex;
  justify-content: space-between;
}

.search{
  width: 30%;
  border-radius: 16px;
  height: 48px;
  background: #FFFFFF1F;
  padding: 12px;
}

.search-text{
  font-weight: 200;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFFA3;
}

.search-ico{
  width: 24px;
  height: 24px;
}

.utils{
  display: flex;
}

.notif{

}

.notif-img{

}

.notif-num{

}


/* Area chart */

.areaChartContainer {
  height: auto;
  width: 100%;
}

.areaChartFilterContainer {
  width: 100%;
  padding: 10px 20px;
  margin-bottom: 30px;
}

.areaChartSelect {
  margin-left: 20px;
  width: 180px;
  padding: 5px;
  padding-right: 10px;
  border-radius: 5px;
  background-color: #f5f5f5;
  border: 0;
}

.areaChartSelect:focus {
  outline: none;
}