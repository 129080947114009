.dashboard {
    margin-bottom: 50px;
    // h2 {
    //     color: rgba(255, 255, 255, 0.8);
    //     width: 80%;
    //     margin-top: 5px;
    // }

    .table {
        background-color: transparent;
        width: 100%;

        h4 {
            width: 95%;
            margin: 0 auto;

        }

        .table_container {
            display: flex;
            flex-direction: column;
            border: 1px solid rgba(255, 255, 255, 0.12);
            border-radius: 24px;
            padding: 24px;
            gap: 48px;
            width: 100%;
            margin: 10px auto 40px auto;

            div.head {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                border-bottom: 1px solid rgba(255, 255, 255, 0.12);

                h3 {
                    padding-bottom: 25px;

                    border-bottom: 1px solid rgba(255, 255, 255);
                }

            }

            table {
                color: #808080;

                tbody {
                    @media screen and (min-width: 990px) and (max-width: 1200px) {
                        overflow: scroll;
                    }

                    tr {
                        // @media screen and (min-width: 990px) and (max-width: 1200px) {
                        //     display: flex;
                        //     flex-wrap: wrap;

                        // }
                        td {
                            span {
                                padding: 2px 4px;
                                border-radius: 4px;
                                font-size: 12px;
                                line-height: 18px;
                                font-weight: 700;
                                color: white;

                                &.person {
                                    color: rgba(255, 255, 255, 0.64)!important;

                                    @media screen and (min-width: 990px) and (max-width: 1200px) {
                                        flex-wrap: wrap;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        div.stats {
            display: flex;
            flex-wrap: wrap;
            gap: 32px;

            aside.group {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                color: white;
                gap: 20px;
                flex: 1;
                margin-bottom: 25px;

                h4 {
                    width: 95%;
                    margin: 0 auto;

                }

                aside {
                    border: 1px solid rgba(255, 255, 255, 0.12);
                    border-radius: 24px;
                    padding: 24px;
                    gap: 48px;
                    width: 100%;

                    @media screen and (max-width: 990px) {
                        width: 95%;
                        margin: 0 auto;

                    }

                    div.head {
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-start;
                        width: 100%;

                        div {
                            display: flex;
                            flex-direction: column;

                            p.stats {
                                font-size: 13.7px;
                                line-height: 17.5px;
                                font-weight: 400;
                                color: rgba(255, 255, 255, 0.7);
                                display: flex;
                                justify-content: flex-end;
                                text-align: right;
                            }
                        }

                        span.date {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            min-width: 100px;
                            border-radius: 16px;
                            padding: 8px;
                            gap: 10px;
                            background-color: rgba(255, 255, 255, 0.12);
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 18.2px;
                            color: white;
                            margin-left: 20px;
                        }
                    }

                    div.search-cont {
                        display: flex;
                        // justify-content: space-between;
                        align-items: center;
                        margin-left: 0;
                        // flex: 1; 

                        span.order {
                            min-width: 60px;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 16.2px;
                            color: #808080;
                        }

                        .input-container {
                            width: auto;
                            min-width: 150px;
                            min-height: 30px;
                            padding: 6px 12px;
                            border-radius: 16px;
                            border: 0;
                            height: auto;

                            .selected-option {
                                flex-direction: row;
                            }

                            .selected-option-placeholder {
                                color: white;
                            }

                            ul {
                                li {
                                    color: white;

                                }
                            }
                        }
                    }

                    section.main-body {
                        margin-top: 35px;
                        border: 0;
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        gap: 24px;

                        div.head {
                            display: flex;
                            justify-content: space-between;
                            align-items: flex-end;

                            .toggler {
                                display: flex;
                                flex-direction: row;
                                gap: 10px;

                                span {
                                    font-size: 14px;
                                    line-height: 18.9px;
                                    font-weight: 400;
                                    color: rgba(255, 255, 255, 0.64);
                                    cursor: pointer;
                                    padding-bottom: 5px;

                                    &.active {
                                        color: white;
                                        font-size: 16px;
                                        line-height: 21.6px;
                                        font-weight: 700;
                                        border-bottom: 1px solid white;
                                    }
                                }

                            }

                            h3 {
                                padding-bottom: 25px;

                                border-bottom: 1px solid rgba(255, 255, 255);
                            }

                        }

                        section {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 100%;
                            gap: 12px;

                            .user-data {

                                @media screen and (min-width: 990px) and (max-width: 1200px) {
                                    flex-wrap: wrap;
                                }
                            }
                        }

                    }

                    section.tail {
                        margin-top: 24px;
                        border-top: 1px solid rgba(255, 255, 255, 0.12);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 10px;
                        padding-top: 20px;
                    }
                }
            }
        }
    }
}