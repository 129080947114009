.counter-container{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.counter-value{
    font-size: 1rem;
    font-weight: 600;
    color: gray;
}

.counter-options{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
}

.counter-down{
    cursor: pointer;
    position: relative;
}

.counter-up{
    cursor: pointer;
    position: relative;
}

/* styles/CounterInput.css */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}