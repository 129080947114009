.hide{
    display: none;
    transition: 0.5s;
}

.musicinput_container{
    width: 100%;
    padding: 1rem;
    background: #1F1E1E;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    transition: 0.5s;
}

.musicinput_header{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}

.musicinput_header_title{
    display: flex;
    gap: 0.5rem;
    align-items: center;
    align-content: center;
}

.musicinput_header_title__icon{
    width: 1.5rem;
    height: 1.5rem;
}

.musicinput_header_title__text{
    color: white;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
    letter-spacing: 0em;
    text-align: left;
}

.musicinput_header_info{
    display: flex;
    gap: 0.5rem;
    align-items: center;
    align-content: center;
}

.musicinput_header_info__size{
    color: gray;
    font-size: 0.85rem;
    font-weight: 400;
    line-height: 0.75rem;
    letter-spacing: 0em;
    text-align: left;
}

.musicinput_header_info__time{
    color: gray;
    font-size: 0.85rem;
    font-weight: 400;
    line-height: 0.75rem;
    letter-spacing: 0em;
    text-align: left;
}

hr{
    border: 0.9px solid gray;
}

.musicinput_header__icon{
    width: 1.3rem;
    height: 1.3rem;
    cursor: pointer;
}


.rotate{
    transform: rotate(180deg);
    transition: transform 0.3s ease-in-out;
}

.musicinput_dropdown_art_tit{
    display: flex;
    gap: 2rem;
}

.musicinput_dropdown_art_descrp{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.musicinput_dropdown_art_prod_gender{
    width: 100%;
    display: flex;
    gap: 3rem;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}
@media screen and (max-width: 768px) {
    .musicinput_dropdown_art_prod_gender{
        flex-wrap: wrap;
        margin: 40px 0;
        gap: 20px;
    }
}
.musicinput_dropdown_art_prod_gender_single{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin: 10px 0;
    align-items: center;
    align-content: center;
}

.musicinput_dropdown_art_prod_gender_div{
    width: 100%;
}

.musicinput_dropdown_expl{
    width: 100%;
    display: flex;
    gap: 3rem;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}
@media screen and (max-width: 768px) {
    .musicinput_dropdown_expl{
        flex-wrap: wrap;
        margin: 40px 0;
        gap: 10px;
    }
}

.musicinput_dropdown_art_prod_gender_select{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 1.2rem;
    color: gray;
}

.musicinput_dropdown_expl__text{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 1.2rem;
    color: gray;
}

.musicinput_dropdown_pagination{
    display: flex;
    width: 100%;
    gap: 0.5rem;
    align-items: center;
    align-content: center;
    color: gray;
    font-size: 1.1rem;
}

.musicinput_dropdown_pagination__underline{
    border-bottom: 0.1rem solid gray;
    padding-bottom: 1rem;
    width: 100%;
}

.musicinput_dropdown_expl__text--title{
    font-size: 1.2rem;
    color: gray;
    width: 170px;
}

.musicinput_dropdown_expl__text--title_single{
    font-size: 1.2rem;
    color: gray;
}

.musicinput_dropdown_expl__text--required{
    color: red;
}

.musicinput_dropdown_expl__select{
    width: 120px;
}