[type="radio"]:not(:checked), [type="radio"]:checked{
  position: relative !important;
  opacity: 1 !important;
}

.custom-btn{
  background-color: #6a0f3e;
  color: white;
  font-size: 30px;
  padding: 10px;
  border-radius: 10px;
  margin: 20px;
}

/*=============================*/
.pf-card{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-between;
  padding-right: 60px;
  padding-left: 60px;
}
.pf-cardo{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding: 40px 0 0 60px;
}
.pf-ssc{
  width: 100%;
  height: calc(100vh - 12.5vh);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.lcd{
  width: 100%;
  margin: auto;
  height: calc(100vh - 12.5vh);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.dyn-form{
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  float: left;
}
.sta-form{
position: sticky;
height: calc(100vh - 9.67vh);
width: 428px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
/*float: right;*/
background-color: #1b0410;
}
.upss{
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: black;
  color: white;
  }
  .ziz{
    z-index: 0;
    width: 100%;
    height: 100vh;
  }
  .body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: #111;
  }
  .upss .fcov{
    width: 200px;
    height: 172px;
    background-color: #888;
    margin:  0 0 36px 0;
  }
  .upss .rcov{
    width: 300px;
    height: 300px;
    border-radius: 10px;
    margin:  0 0 36px 0;
  }
  .upss .rcov  img {
    object-fit: fill;
    width: 100%;
    border-radius: 10px;
    height: 100%;
  }
  .upss .psttl{
    font-size: 40px;
    letter-spacing: 0.40px;
    font-weight: 700;
    line-height: 38px;
    margin-bottom: 8px;
    border-radius: 50px;
    color: white;
  }
  .upss .psauth{
    font-size: 16px;
    letter-spacing: 0.16px;
    font-weight: 400;
    line-height: 20px;
    border-radius: 50px;
    color: #f2f2f2;
    opacity: 0.7;
  }

.sta-form .fcov{
  width: 200px;
  height: 172px;
  border-radius: 10px;
  background-color: #888;
  margin:  0 0 36px 0;
}
.sta-form .rcov{
  width: 200px;
  height: 172px;
  overflow: hidden;
  border-radius: 10px !important;
  margin:  0 0 36px 0;
}
.sta-form .rcov  img {
  object-fit: fill;
  border-radius: 10px !important;
  width: 100%;
  height: 100%;
}

.mybg{
  background-color: #6a0f3e;
}

.rcov{
  overflow: hidden;
  border-radius: 10px !important;
}

.sta-form .psttl{
  font-size: 28px;
  letter-spacing: 0.28px;
  font-weight: 600;
  line-height: 26px;
  margin-bottom: 8px;
  color: #f2f2f2;
}
.sta-form .psauth{
  font-size: 16px;
  letter-spacing: 0.16px;
  font-weight: 400;
  line-height: 20px;
  color: #f2f2f2;
  opacity: 0.7;
}
.sta-form .ftit{
  width: 220px;
  height: 12px;
  background-color: #888;
  margin:  0 0 12px 0;
}
.sta-form .fart{
  width: 160px;
  height: 12px;
  background-color: #888;
  margin:  0 0 12px 0;
}
.sta-form .rlp{
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  bottom: 0;
  width: 100%;
  height: 20px;
  background-color: #51464c;
  font-size: 14px;
  letter-spacing: 0.14px;
  color: #f2f2f2;
}
.logo-conp img{
 padding: 20px 56px 32px 32px;
    max-width: 280px;
    max-height: 240px;
}
.cover-i{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 500;
  color: #6a0f3e;
  width: 176px;
  height: 176px;
  border-radius: 10%;
  border: 1.8px dashed #6a0f3e;
  margin: 0 0 20px 0;
  cursor: pointer;
}
.cover-iu{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  color: #6a0f3e;
  width: 176px;
  height: 176px;
  border-radius: 10%;
  border: 1.8px dashed #6a0f3e;
  margin: 0 0 20px 0;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: wrap;
  text-align: center;
}
.cover-con{
  position: relative;
  width: 176px;
  height: 176px;
  border-radius: 20px;
  margin: 0 0 20px 0;
  cursor: pointer;
}
.cover-web{
  position: relative;
  width: 172px;
  height: 172px;
  margin: -32px 180px 20px 180px;
  cursor: pointer;
}
.cover-web img {
  object-fit: fill;
  width: 100%;
  height: 100%;
  border-radius: 20px;
}
.cover-con img {
  object-fit: fill;
  width: 100%;
  height: 100%;
  border-radius: 20px;

}
.close-btn{
  position: absolute;
  top: -12px;
  right: -28px;
}
.imgl{
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  margin-left: 6px;
}
.mcp{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.mtml{
  display: flex;
  align-items: center;
  justify-content: center;
}

.mtml span{
  color: #f2f2f2;
  font-size: 11px;
  font-weight: 400;
  letter-spacing: 0.11px;
}
.mtml .span1{
  text-align: center;
margin-right: 6px;
}
.mtml .span2{
  text-align: right;
margin-left: 6px;
}


.slider {
  position: relative;
  width: 235px;
  height: 4px;
  border-radius: 2px;  
  background: #b3b3b3;
  outline: none;
  opacity: 1;
  -webkit-transition: .2s;
  transition: opacity .2s;
  margin-top: 2.8px;
}
.slider:hover {
  opacity: 1;
}
.cntrl{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24px 0 0 0;
}
.sidi{
  position: absolute;
  top: -3.8px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #fff;
}
.cibut{
  width: 44px;
  height: 44px;
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: not-allowed;
}
.ppbut{
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background-color: #6a0f3e;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0 0 6px 0;
}

.slider:before {
content: '';
  width: 14px;
  height: 14px;
  border-radius: 50%; 
  background: #04AA6D;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #04AA6D;
  cursor: pointer;
}

.input_text{
  color: white !important;
}

.select_text{
  background-color: #6a0f3e;
  color: white !important;
  width: 100% !important;
  height: 60px !important;
}

select_tex2{
  color: #6a0f3e !important;
  background-color: white;
  width: 100% !important;
  height: 80px !important;
}

.date_text{
  width: 100% !important;
  color: white !important;
}

.pad_text{
  margin-left: 5px !important;
}

.constraint{
  max-height: 700px;
}

.form-lbp{
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: start;
}
.form-barp{
  display: flex;
  align-items: start;
  margin: 16px 0 16px 0;
}

.form-lbp label{
    font-size: 17px;
    line-height: 24px;
    font-weight: 400;
  margin-bottom: 8px;
  color: #202124;
}
.form-lbp input:not([type=radio]) {
  width: 316px;
  height: 44px;
  border-radius: 3px;
  margin: 2px;
  padding: 6px 8px 6px 8px;
    font-size: 19px;
    line-height: 24px;
    font-weight: 500;
  border: 1.8px solid #bebebe;
  color: #6a0f3e;
}
.dsn{
  display: none;
}
.form-lbp .sm-input {
  width: 241px;
  height: 32px;
  border-radius: 3px;
  margin: 2px;
  padding: 6px 8px 6px 8px;
    font-size: 19px;
    line-height: 24px;
    font-weight: 500;
  border: 2px solid #bebebe;
  color: #6a0f3e;
}

.sm-input {
  height: 50px !important;
}

.my_date_input{
  width: 93% !important;
  padding: 0.8rem !important;
  padding-top: 0.4rem !important;
  padding-bottom: 0.4rem !important;
  border: none !important;
  background: #1D1D1D !important;
  border-radius: 1rem !important;
  outline: none !important;
  box-shadow: none !important;
}

.form-lbp select {
  width: 241px;
  height: 44px;
  border-radius: 3px;
  margin: 2px;
  padding: 6px 6px 6px 6px;
    font-size: 19px;
    line-height: 24px;
    font-weight: 500;
  border: 1.8px solid #bebebe;
  color: #6a0f3e;
}
.form-lbp input:not([type=radio]):focus{
  border: 1.8px solid transparent;
  outline: 1.8px solid #781146;
}

.form-lbp .fbv{
    cursor: pointer;
    border-radius: 6px;
    border: none;
    width: 482px;
    padding: 14px;
    background-color: #6a0f3e;
    color: #f2f2f2;
    font-size: 16px;
    font-weight: 600;
}
.form-lbp .fbvdi{
  cursor: pointer;
  border-radius: 6px;
  border: none;
  width: 482px;
  padding: 14px;
  background-color: #bebebe;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
}
.snbv{
    cursor: pointer;
    border-radius: 6px;
    border: none;
    width: 120px;
    padding: 14px;
    background-color: #6a0f3e;
    color: #f2f2f2;
    font-size: 16px;
    font-weight: 600;
}
.snbvdi{
  cursor: pointer;
  border-radius: 6px;
  border: none;
  width: 120px;
  padding: 14px;
  background-color: #bebebe;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
}
.sbbv{
    cursor: pointer;
    border-radius: 6px;
    border: none;
    padding: 11px;
    background-color: #121212;
    color: #f2f2f2;
    font-size: 16px;
    font-weight: 600;
}
.psttl{
  font-size: 10px;
  font-weight: 600;
  line-height: 26px;
  margin-bottom: 8px;
}
.psauth{
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 12px;
}

.previewTitle{
  max-height: 300px;
  overflow: auto;
}

.sm-check {
  width: 20px;
  height: 20px;
}

input[type="radio"]{
  -webkit-appearance: radio;
}

input[type="radio"]:focus{
  background-color:#bfb;
  border-color: #4c4;
}

.p-8{
  padding: 12pt;
}