.container{
    width: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    align-items: center;
}

.covers{
    width: 100%;
    display: flex;
    gap: 10rem;
    align-items: center;
    padding-right: 1rem;
    padding-left: 1rem;
}

.cover{
    width: 10rem;
    height: 10rem;
    border-radius: 10rem;
    object-fit: cover;
}

.coverPreview{
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.inputContainer{
    border: 0.1rem dashed #FB5828;
    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: none !important;
    position: relative;
}

.file{
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
}

.fileIcon{
    position: absolute;
    width: 4rem;
    height: 4rem;
    color: #FB5828;
}

.submit_button{
    background-color: transparent;
    border: 0.1rem solid #FB5828;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    color: #FB5828;
    border-radius: 0.5rem;
    padding: 0.5rem;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    cursor: pointer;
}

.msg{
    color: #28fb33;
    font-size: 1.5rem;
    text-align: center;
    font-weight: bold;
}


.msgContainer{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: center;
}

.reload{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    color: #FB5828;
    cursor: pointer;
    border: 0.1rem solid #FB5828;
    border-radius: 0.5rem;
    padding: 0.5rem;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
}

.reloadIcon{
    width: 1.5rem;
    height: 1.5rem;
    color: #FB5828;
}