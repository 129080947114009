main.hero {
    background: url('../../assets/illustrations/herobg.png') center/cover no-repeat;
    padding-top: 140px;

    main.container {
        section.intro {
            width: 70%;
            margin: 0 auto;

            @media screen and (max-width: 768px) {
                background: url('../../assets/illustrations/herobg_mobile.png') top/contain no-repeat;

                height: 680px;
            }

            h1 {
                font-weight: 700;
                font-size: 56px;
                line-height: 70px;
                color: white;
                text-align: center;

                @media screen and (max-width: 768px) {
                    font-size: 32px;
                    line-height: 43.2px;
                }
            }

            p {
                width: 70%;
                font-weight: 400;
                font-size: 24px;
                line-height: 36px;
                color: rgba(255, 255, 255, 0.8);
                text-align: center;

                @media screen and (max-width: 768px) {
                    width: 100%;
                    font-size: 20px;
                    line-height: 30px;
                }
            }

            div.buttons {
                display: flex;
                margin: 0 25px;

                @media screen and (max-width: 768px) {
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }

                a {
                    margin: 10px;

                    &:nth-child(1) {
                        width: 230px;
                    }

                    &:nth-child(2) {
                        width: 193px;
                    }
                }
            }

        }

        section.image {

            // background-color: red;
            @media screen and (max-width: 768px) {
                display: none;

            }
        }

    }
}