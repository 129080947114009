.modalContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  border-radius: 10px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  background: #1F1F1F;
}

.modalHeader{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modalHeaderTitle,
.modalMain,
.modalFooter {
  width: 100%;
  color: white;
}

.modalHeaderTitle {
  font-size: 24px;
  font-weight: bold;
  color: white;
}

.modalHeaderTitle {
  margin-top: 0;
  color: white;
}

.modalMain {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 20px;
  /* overflow-y: scroll; */
}

.modalContent {
  width: 100%;
  height: auto;
}

.formBtn {
  width: 1.8rem;
  height: 1.8rem;
  font-weight: bolder;
  cursor: pointer;
}

@media screen and (max-width: 900px) {
  .modalContainer {
    width: calc(100% - 100px);
    height: 80%;
  }
}

@media screen and (max-width: 576px) {
  .modalContainer {
    border-radius: 0;
    width: calc(100% - 40px);
    height: calc(100% - 38px);
  }
}
