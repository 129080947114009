.commentsContainner{
    width: 95%;
    background: #141414;
    border: 1px solid #FFFFFF1F;
    padding: 1em;
    border-radius: 24px;
    margin-top: 1.7em;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.commentsTitle{
    font-size: 1rem;
    font-weight: 700;
    line-height: 18px;
    text-align: left;
    color: white;
}

