/*  ============================================================== */
/* publish audio style */

body {
  background: black;
}

.warning__ {
  width: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  gap: 1rem;
}

.no__margin {
  margin: 0 !important;
  font-size: 1rem !important;
}

.publish_audio {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background: black;
  /* display: grid;
  grid-template-columns: 2fr 1fr; */
  /* gap: 1rem; */
}

.publish_audio_2 {
  width: 70%;
  margin: auto;
  height: 100vh;
  background: black;
  display: grid;
  gap: 2rem;
}

.publish_audio__container {
  display: flex;
  flex-direction: column;
  width: calc(100% - 475px);
}

.album_publish {
  width: 100% !important;
}

@media screen and (max-width: 990px) {
  .publish_audio_2 {
    width: 100%;

  }

  .publish_audio__container {
    width: 100%;
  }
}

.step_3 {
  width: 90% !important;
}

.step_3 .publish_audio__container {
  width: 100% !important;
}

@media screen and (max-width: 700px) {

  .step_3 .publish_audio_end_container_col2_container,
  .step_3 .publish_audio_end_container_col2_fond2 {
    display: none;
  }

  .step_3 .publish_audio_end_container {
    grid-template-columns: 1fr;
  }
}

.publish_audio__container_header {
  width: 90%;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.publish_audio__container__header__info {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.publish_audio__container__header__title {
  color: white;
  font-size: 3rem;
}

.publish_audio__container__header__subtitle {
  color: gray;
  font-size: 1rem;
}

.publish_audio__preview {
  background: #FFFFFF14;
  border-left: 1px solid #FF562052;
  position: fixed;
  top: 0;
  right: 0;
  width: 475px;
}

.publish_audio__preview__fond {
  width: 100%;
  height: 100%;
  background: url("../assets/new_images/uploadPreviewFond.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.publish_audio_form {
  width: 95%;
  margin: auto;
  margin: 2rem auto;
  display: flex;
  flex-direction: column;
  background: #141414;
  border-radius: 1rem;
  grid-gap: 0;
  justify-content: space-between;
  /* overflow: hidden; */
}

.publish_audio_form_fields {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

@media screen and (max-width: 768px) {

  .publish_audio_form_fields {
    grid-template-columns: 1fr;
  }

}

@media screen and (min-width: 991px) and (max-width: 1200px) {

  .publish_audio_form_fields {
    grid-template-columns: 1fr;
  }

}

.publish_audio_form_fields_col1 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem;
  height: 100%;
  gap: 2rem;
}

.publish_audio_form_fields_col1__title {
  color: white;
  font-size: 1.5rem;
}

.publish_audio_form_fields_col1__subtitle {
  color: white;
  font-size: 1rem;
}

.overlay_black {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Black overlay with 50% opacity */
  z-index: 1;
  /* Ensure overlay is above the dropzone */
}

.publish_audio_form_fields_col1__dropzone {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: 2rem;
  width: 100%;
  height: -webkit-fill-available;
  height: fill-available;
  height: 100%;
  height: -moz-available;
  background: #1F1F1F;
  border: 0.1rem solid #313131;
  border-radius: 1rem;
  cursor: pointer;
}

.publish_audio_form_fields_col1__dropzone:hover {
  opacity: .7;
}

.publish_audio_form_fields_col1__upload_instructions {
  width: 100%;
  background: #1F1F1F;
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: start;
}

.publish_audio_form_fields_col1__upload_instructions__warming {
  display: flex;
  gap: 1rem;
  width: 100%;
}

.publish_audio_form_fields_col1__upload_instructions__warming__text {
  color: #FFA800;
  font-size: 1rem;
}

.publish_audio_form_fields_col1__upload_instructions__instruction {
  color: gray;
  font-size: 0.8rem;
}

.publish_audio_form_fields_col2__form {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 1rem;
}

.publish_audio_form_fields_col2__form__col {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
}


.publish_audio_form_fields_col2 {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  gap: 0.5rem;
}

.publish_audio_form_fields_col2__subtitle {
  color: gray;
  font-size: 1rem;
}

.publish_audio_form_stepper {
  width: 100%;
  background: #292929;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}

.publish_audio_form_stepper__actions {
  display: flex;
  gap: 1rem;
  width: 100%;
  justify-content: end;
  /* margin-right:auto ; */
}

.publish_audio_form_stepper__actions__cancel {
  color: black;
  font-size: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: white;
  border-radius: 20rem;
  padding: 1rem;
}

.publish_audio_form_stepper__actions__publish {
  color: white;
  font-size: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border-radius: 20rem;
  background: #FF5620;
  padding: 1rem;
}

.publish_audio_form_stepper__actions__publish--disable {
  color: white;
  font-size: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border-radius: 20rem;
  background: #FFFFFF1F;
  padding: 1rem;
}

.publish_audio_form_stepper__actions__canceel {
  color: white;
  font-size: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border-radius: 20rem;
  background: #661913;
  padding: 1rem;
  border: 0.1rem solid #711511;
  align-self: end;
}

.publish_audio__preview__container {
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem;
  align-items: cen;
  justify-content: center;
}

@media screen and (max-width: 990px) {

  .publish_audio__preview__container,
  .publish_audio__preview__fond {
    display: none;
  }
}

.publish_audio__preview__container__title {
  color: white;
  font-size: 1.5rem;
}

.publish_audio__preview__container__img {
  width: 100%;
  height: 40%;
  background: #FFFFFF1F;
  border-radius: 3rem;
  overflow: hidden;
}

.publish_audio__preview__container__img_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.publish_audio__preview__subcontainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.publish_audio__preview__container__subtitle {
  color: white;
  font-size: 1rem;
}

.publish_audio__preview__subcontainer__container {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.publish_audio__preview__subcontainer__img {
  width: 2rem;
  height: 2rem;
  border-radius: 10rem;
  object-fit: cover;
}

.publish_audio__preview__subcontainer__certif {
  width: 1rem;
  height: 1rem;
  border-radius: 5rem;
  object-fit: cover;
}

.publish_audio__preview__subcontainer__text {
  color: white;
  font-size: 0.8rem;
}

.publish_audio__preview__subcontainer__progress1 {
  width: 100%;
  height: 0.5rem;
  border-radius: 6rem;
  background: gray;
}

.publish_audio__preview__subcontainer__progress2 {
  width: 50%;
  height: 0.5rem;
  border-radius: 6rem;
  background: white;
}

.publish_audio__preview_times {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.publish_audio__preview_times_start {
  color: gray;
  font-size: 0.8rem;
}


.publish_audio_control {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
}

.publish_audio_control_elt1 {
  width: 2rem;
  height: 2rem;
}

.publish_audio_control_elt2 {
  width: 4rem;
  height: 4rem;
}

.publish_audio__preview__subcontainer__progress3 {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.publish_audio__preview__subcontainer__progress4 {
  width: 80%;
  height: 0.5rem;
  border-radius: 6rem;
  background: white;
}

.publish_audio_form_medias_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: -webkit-fill-available;
  height: fill-available;
  height: 100%;
  height: -moz-available;
}

.publish_audio_form_medias {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 2rem;
}

.publish_audio_form_medias__title {
  color: white;
  font-size: 1rem;
}

.publish_audio_form_medias__televerser {
  color: #FF5620;
  font-size: 0.9rem;
  cursor: pointer;
}

.publish_audio_form_medias__subtitle {
  padding-left: 2rem;
  margin-top: -1rem;
  color: gray;
  font-size: 1rem;
}


.publish_audio_form_medias__audios {
  width: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow-y: auto;
}

.publish_audio_form_medias__audios__item {
  width: 100%;
  display: flex;
  gap: 1rem;
  align-items: center;
  align-content: center;
  position: relative;
}

@media screen and (max-width: 768px) {
  .publish_audio_form_medias__audios__item .publish_audio_form_medias__audios__item_del {
    position: absolute;
    top: -30px;
    right: 15px;
    width: 25px;
    height: 25px;
  }
}

.publish_audio_form_medias__audios__item_drag {
  width: 1rem;
  height: 1rem;
  cursor: grab;
  display: none;
}

.publish_audio_form_medias__audios__item_del {
  width: 1rem;
  height: 1rem;
  cursor: pointer;
}

.publish_audio_form_medias__audios__item_song {
  width: -webkit-fill-available;
  width: fill-available;
  width: 100%;
  width: -moz-available;
}


.publish_audio_end_container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;
}

@media screen and (max-width: 768px) {
  .publish_audio_end_container {
    grid-template-columns: 1fr;

  }
}

.publish_audio_end_container_col1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
  gap: 1rem;
  align-items: center;
}

.publish_audio_end_container_col1__back {
  padding: 1.5rem;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  border-radius: 0.5rem;
  border: 0.1rem solid white;
  color: white;
  font-size: 1.5rem;
  font-weight: 600;
  cursor: pointer;
}

.publish_audio_end_container_col1_subtitle {
  color: gray;
  font-size: 0.8rem;
  font-weight: 600;
  text-align: center;
}

.publish_audio_end_container_col1_title {
  color: white;
  font-size: 1.5rem;
  font-weight: 600;
}

.publish_audio_end_container_col1_button {
  padding: 0.5rem;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 0.5rem;
  border: 0.1rem solid white;
  color: white;
  text-align: center;
}

.publish_audio_end_container_col1_button_retry {
  padding: 0.5rem;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 0.5rem;
  border: 0.1rem solid #FF5620;
  color: #FF5620;
  text-align: center;

}

.publish_audio_end_container_col2 {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

@media screen and (max-width: 700px) {
  .publish_audio_end_container_col2 {
    display: none;
  }
}

.publish_audio_end_container_col2_fond1 {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background: url("../assets/new_images/endBackImg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.publish_audio_end_container_col2_fond2 {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background: url("../assets/new_images/uploadPreviewFond.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

}

.publish_audio_end_container_col2_container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem;
  z-index: 1;
}

.publish_audio_end_container_col2_container_title {
  color: white;
  font-size: 1.5rem;
  font-weight: 600;
}

.publish_audio_end_container_col2_container_header {
  width: 100%;
  display: grid;
  gap: 2rem;
  grid-template-columns: 2fr 3fr;
  align-items: center;
}

.publish_audio_end_container_col2_container_header__cover_container {
  position: relative;
  width: 13rem;
  height: 13rem;
  background: #FFFFFF1F;
  border-radius: 1rem;
  overflow: hidden;
}

.publish_audio_end_container_col2_container_header__cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.publish_audio_end_container_col2_container_header__cover_text {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  padding: 0.2rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  border-radius: 100rem;
  color: white;
  font-size: 0.6rem;
  background: #FFFFFF29;
  border: 0.45px solid #FFFFFF1F;
}

.publish_audio_end_container_col2_container_hr {
  height: 0.45px;
  border-color: #FFFFFF1F;
}

.publish_audio_end_container_col2_container_header__cover_text_currency {
  font-size: 0.3rem;
  font-weight: 100;
}

.publish_audio_end_container_col2_container_header__content {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.publish_audio_end_container_col2_container_header__artistname {
  color: white;
  font-size: 1.2rem;
  font-weight: 200;
}

.publish_audio_end_container_col2_container_header__songtitle {
  color: white;
  font-size: 1.7rem;
  font-weight: 600;
}

.publish_audio_end_container_col2_container_header__outdate {
  color: white;
  font-size: 0.8rem;
  font-weight: 100;
}

.publish_audio_end_container_col2_container_header__tags {
  width: 100%;
  display: flex;
  gap: 2rem;
}

.publish_audio_end_container_col2_container_header__tag {
  background: #FFFFFF29;
  border: 1px solid #FFFFFF1F;
  color: white;
  margin-top: 0.3rem;
  padding: 0.3rem;
  border-radius: 0.5rem;
  cursor: pointer;
}

.publish_audio_end_container_col2_container_header__tag_text {
  font-size: 1.2rem;
  font-weight: 300;
}

.publish_audio_end_container_col2_container_title1 {
  color: white;
  font-size: 1.6rem;
  font-weight: 600;
}

.publish_audio_end_container_col2_container_list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-height: -webkit-fill-available;
  max-height: fill-available;
  max-height: 100%;
  max-height: -moz-available;
  overflow-y: scroll;
}

/*supress the scrool webkit bar */
.publish_audio_end_container_col2_container_list::-webkit-scrollbar {
  display: none;
}

/* =================================================================== */

.field-container {
  position: relative;
  display: inline-block;
}

.hoverable-term {
  position: relative;
  cursor: pointer;
}

.interrogative-icon {
  margin-left: 5px;
  cursor: help;
}

.tooltip {
  position: absolute;
  top: -10px;
  /* Adjust as needed */
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 5px;
  display: none;
}

.hoverable-term:hover .tooltip {
  display: block;
}


/* Removing the default arrow */
select {
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  background-image: url("../assets/images/baseline_arrow_drop_down_black_24dp.png");
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: calc(100% - 8px) center;
}

.select-wrapper {
  position: relative;
}

.select-wrapper::after {
  content: "▼";
  font-size: 1rem;
  top: 6px;
  right: 10px;
  position: absolute;
}


/*===================*/
iframe {
  display: none;
}

.hc {
  color: #121212;
  background-color: #f3e7ed;
}

.hcl {
  color: #121212;
  background-color: #f3e7ed;
  height: 100vh;
  overflow: hidden;
}

.mhcl {
  color: #121212;
  background-color: #f3e7ed;
  min-height: 100vh;
  height: auto;
  /* overflow: hidden; */
}

.link {
  color: #000;
  text-decoration: none;
  padding: 5px;
}

.linker {
  color: white;
  text-decoration: none;
  padding-right: 20px;
  position: relative;
  margin-bottom: 0.5em;
}

/* Define the color for the icons when active */
/*.linker .sp:hover .ico {*/
/*  filter: invert(1); !* Inverts the color when the parent <li> is active *!*/
/*}*/


.hs {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: auto;
  min-height: 100vh;
  height: auto;
  padding: 0 0 40px 0;
  color: #121212;
}

.hsl {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  width: 100%;
  padding: 12px;
}

.hsl h2 {
  text-align: center;
  font-size: 54px;
  font-weight: 800;
  line-height: 74.52px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  color: #6a0f3e;
}

.hsl h4 {
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  line-height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  color: #6a0f3e;
  margin-top: -20px;
}

.lcca {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  width: 280px;
  border: 2px solid #6a0f3e;
  margin: 32px;
  padding: 20px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease-out, box-shadow 0.2s ease;
}

.lcca:hover {
  background-color: #f1e2ea;
  box-shadow: 10px 10px 12px 3px rgba(0, 0, 0, 0.21);
  -webkit-box-shadow: 10px 10px 12px 3px rgba(0, 0, 0, 0.21);
  -moz-box-shadow: 10px 10px 12px 3px rgba(0, 0, 0, 0.21);
}

.hsl .lcca img {
  width: 132px;
  height: 132px;
  max-width: 100%;
  margin: 16px 0 0 0;
  border-radius: 0%;
  z-index: 2;
}

.hsl .lcca span {
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
  z-index: 2;
  color: #6a0f3e;
}

.hsl .ccl {
  display: flex;
  align-items: center;
  justify-content: center;
}

.hs h2 {
  max-width: 90%;
  text-align: left;
  font-size: 48px;
  font-weight: 800;
  line-height: 66.24px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  color: #6a0f3e;
}

.hcc {
  display: flex;
  align-items: center;
  justify-content: center;
}

.slide:nth-child(1) {
  color: #c91c31;
}

.slide:nth-child(2) {
  color: #b7ce38;
  transition: 1s ease-in;
}

.slide:nth-child(3) {
  color: #85134e;
}

.flc {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: -100;
  filter: grayscale(100%);
  cursor: pointer;
  transition: 0.4s ease-in-out;
}

.flc:hover {
  filter: grayscale(0%);
}

.pi {
  width: 240px;
  height: 240px;
  border-radius: 50%;
  margin: 36px 28px 8px 0;
}

.pi img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
}

.flc span {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
}

.fc {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}

.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 40px;
  background-color: transparent;
  color: #121212;
  overflow: hidden;
  height: 80px;
}

.dnav {
  position: fixed;
  /* Set the navbar to fixed position */
  top: 0;
  /* Position the navbar at the top of the page */
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px 10px 10px;
  overflow: hidden;
  height: 56px;
  width: 100%;
  z-index: 1;
  background-color: #fff;
}

.snv {
  box-shadow: -1px 6px 6px 0px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: -1px 6px 6px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: -1px 6px 6px 0px rgba(0, 0, 0, 0.1);
}

.nav-logo img {
  padding: 8px 12px 0 0;
  max-width: 140px;
  max-height: 140px;
}

.ac,
.dac {
  list-style-type: none;
  display: flex;
  align-items: center;
  margin-left: 5px;
  justify-content: center;
  padding: 10px;
}

.dac {
  width: 400px;
}

.dac li {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  margin-right: 20px;
  /* padding: 12px; */
}

.ac li {
  margin-top: 10px;
}

.ac li a {
  font-size: 16px;
  font-weight: 700;
  border-radius: 20px;
  color: #781146;
  margin: auto;
  cursor: pointer;
}

.dac li a {
  font-size: 16px;
  font-weight: 700;
  color: #781146;
  margin-right: 14px;
  cursor: pointer;
}

.bs {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}

.bsb {
  position: relative;
  display: flex;
  justify-content: center;
  width: 76%;
  height: auto;
  max-height: 650px;
}

.bsbm {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 76%;
  height: 480px;
  max-height: 650px;
}

.bsb .tc {
  display: block;
  width: 55%;
  height: 348px;
  min-width: auto;
  min-height: auto;
}

.tc h3 {
  font-size: 2.25rem;
  /* 36px */
  line-height: 2.5rem;
  /* 40px */
  color: #6a0f3e;
  font-weight: 700;
  letter-spacing: -0.025em;
  margin-bottom: 1rem;
  /* 16px */
}

.sh3 {
  font-size: 2.25rem;
  /* 36px */
  line-height: 2.5rem;
  /* 40px */
  font-weight: 700;
  color: #6a0f3e;
  margin-bottom: 2rem;
}

.cmc {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-items: start;
  justify-content: start;
  height: auto;
  width: 300px;
  padding: 0 20px;
  margin-bottom: 30px;
}

.cmc h4 {
  font-size: 1.875rem;
  /* 30px */
  line-height: 2.25rem;
  /* 36px */
  color: #6a0f3e;
  font-weight: 700;
  margin-top: 2.5rem;
  /* 24px */
  margin-bottom: 1.5rem;
  /* 24px */
}

.cmc p {
  font-size: 1rem;
  line-height: 1.5rem;
  line-height: 1.25rem;
  grid-column-start: 1;
  text-align: left;
  /* max-height: 180px; */
  max-width: 380px;
}

.cmc img {
  width: 248px;
  height: 220px;
  z-index: 2;
}

.tc a {
  font-size: 1.5rem;
  line-height: 2rem;
}

.bsb .img {
  margin-left: 40px;
  width: 437px;
  height: 437px;
  z-index: 2;
}

.imr {
  margin-right: 20px;
}

.hrc {
  width: 220px;
  height: 340px;
  z-index: 2;
}

.hrc img {
  border-radius: 8px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hc1 {
  margin-top: -2px;
  z-index: 2;
}

.hc2 {
  margin-top: -96px;
  z-index: 2;
}

.hc4 {
  position: absolute;
  top: 250px;
  left: 220px;
  z-index: 2;
}

.hc3 {
  margin-top: -2px;
  z-index: 2;
}

.hv {
  position: absolute;
  width: 720px;
  height: 720px;
  z-index: 1;
  transform: rotate(20deg);
  opacity: 1;
  mix-blend-mode: overlay;
}

.hvl {
  position: absolute;
  width: 476px;
  height: 476px;
  z-index: 1;
  transform: rotate(20deg);
  opacity: 1;
  mix-blend-mode: overlay;
  overflow: hidden;
}

.sbt {
  text-align: center;
  font-size: 60px;
  line-height: 73px;
  padding-top: 72px;
  color: #6a0f3e;
}

.lhc {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
}

.rhc {
  position: relative;
  margin-bottom: 56px;
  display: flex;
  flex-direction: row;
}

.fcta {
  max-width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #85134e;
  color: #fff;
  padding: 30px;
  font-size: 30px;
  font-weight: 500;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 2rem;
}

.psdl {
  position: absolute;
  width: 248px;
  height: 220px;
  z-index: 1;
  border: 2px solid #781146;
  right: 44px;
  top: 28px;
}

.psd:nth-child(2) {
  left: 8px;
  top: 12px;
}

.btn-icon {
  filter: invert(100%);
  margin-right: 8px;
}

.hero-cta {
  max-width: 220px;
  padding: 12px;
  font-size: 13pt;
  font-weight: 400;
  color: #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #f2f2f2;
  border-radius: 8px;
  cursor: pointer;
}

.footer {
  width: 100%;
  min-height: 44vmin;
  display: flex;
  align-items: start;
  justify-content: center;
  background-color: #6a0f3e;
  color: #f2f2f2;
  margin-top: 0.25rem;
}

.ftt {
  margin-bottom: 1rem;
  font-size: 1.875rem;
  line-height: 2.25rem;
  font-weight: 700;
  letter-spacing: -0.025em;
}

.ftr-logo {
  font-size: 48px;
  font-weight: 600;
  margin: 16px;
}

.fw {
  margin-bottom: -16px;
}

.ftr-content {
  list-style-type: none;
  font-size: 20px;
  margin: 12px 20px;
}

.ftr-content li {
  margin: 8px;
  cursor: pointer;
}

.create-h3 {
  font-size: 36px;
  font-weight: 700;
  text-align: center;
  color: #6a0f3e;
  max-width: 460px;
  margin-bottom: 3rem;
}

.scta {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 80px 80px;
}

.secta {
  text-align: center;
  width: 180px;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  color: #f5f5f5;
  background-color: #6a0f3e;
  padding: 16px;
  border-radius: 8px;
  margin: 20px;
  cursor: pointer;
}

.clc {
  padding: 0px 40px 28px 40px;
}

.cmcd {
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  height: 100vh;
  background-color: #430a27;
  margin-top: 6px;
  padding: 48px 0px 10px 120px;
}

.imcd {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  background-color: #fff;
  margin-top: 2px;
  padding: 32px 0 0 0;
}

.csign {
  font-size: 14px;
  letter-spacing: 0.14px;
  line-height: 20px;
  font-weight: 600;
  color: #eee;
  position: absolute;
  left: 50%;
  bottom: 0%;
  margin-bottom: -210px;
}

.lgdc {
  position: relative;
  display: block;
  padding: 0 48px 8px 0;
  width: 488px;
  color: #eee;
}

.dit {
  position: absolute;
  top: 20px;
  left: -40px;
}

.lgdc h4 {
  font-size: 12px;
  letter-spacing: 0.12px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 16px;
  color: #c289a7;
}

.lgdc .link {
  text-decoration: none;
  cursor: pointer;
  color: #aa5a83;
}

.lgdc h2 {
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 0.32px;
  line-height: 40px;
  width: 440px;
  margin: 8px 0 20px 0;
}

.lgdc h3 {
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.24px;
  line-height: 30px;
  width: 404px;
  margin: 20px 0;
}

.lgdc span {
  font-size: 14px;
  letter-spacing: 0.14px;
  line-height: 21px;
  font-weight: 600;
  color: #ddd;
}

.lgdc .tlc {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  margin-top: 16px;
}

.lgdc .tlc .tli {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 0;
}

.log-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 440px;
  height: 496px;
  max-width: 550px;
  padding: 24px 32px 12px 32px;
  background-color: #fff;
  border-radius: 4px;
  margin-left: 140px;
  margin-bottom: 12px;
}

.fclo {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: baseline;
  text-align: center;
}

.load-title {
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.18px;
  line-height: 22px;
  color: #781146;
  width: 76%;
}

.load-titler {
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.18px;
  line-height: 22px;
  color: #781146;
  width: 72%;
}

.lgc-title {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: 24px;
  color: #333;
  margin: 0 0 20px 0;
  max-width: 340px;
}

.lgc-form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.lgc-form .form-lb {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: start;
}

.lgc-form .form-bar {
  display: flex;
  align-items: center;
  margin: 8px 0 8px 0;
}

.lgc-form .form-lb label {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.16px;
  line-height: 20px;
  color: #202124;
}

.lgc-form .form-lb input {
  width: 240px;
  height: 36px;
  border-radius: 3px;
  margin: 2px 0 3px 0;
  padding: 8px;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.14px;
  border: none;
  color: #202124;
  background-color: #f2f2f2;
}

.lgc-form .form-icp {
  position: absolute;
  width: 32px;
  height: 16px;
  background-color: transparent;
  right: 15px;
  top: 36px;
  cursor: pointer;
}

.lgc-form .form-lb input:focus,
select:focus {
  border: none;
  outline: none;
}

.lgc-form .form-subcta {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  /* max-width: 432px; */
  margin: 36px 0 0 0;
}

.lgc-form .form-sub {
  padding: 8px;
  width: 248px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #6a0f3e;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  border-radius: 4px;
  cursor: pointer;
  border: none;
}

.lgc-form .form-subdi {
  padding: 8px;
  width: 100%;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #bebebe;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  border-radius: 4px;
  cursor: not-allowed;
  border: none;
}

.lgc-form .corpus {
  margin-top: 20px;
  font-size: 9px;
  letter-spacing: 0.2px;
  line-height: 11px;
  font-weight: 600;
  height: 70px;
  width: 300px;
  display: inline-block;
  background-color: #fff;
  color: #303030;
}

.lgc-form .form-link {
  cursor: pointer;
  font-size: 9px;
  letter-spacing: 0.2px;
  line-height: 11px;
  color: #781146;
}

.bsh {
  box-shadow: rgb(50 50 50 / 10%) 0px 16px 32px, rgb(0 0 0 / 7%) 0px 6px 16px;
}

.spdc {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  padding: 0 48px 12px 48px;
  max-width: 600px;
  color: #eee;
}

.sdit {
  position: absolute;
  top: 32px;
  left: 4px;
}

.spdc h4 {
  font-size: 12px;
  letter-spacing: 0.12px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 16px;
  color: #c289a7;
}

.spdc .link {
  text-decoration: none;
  cursor: pointer;
  color: #aa5a83;
}

.spdc h2 {
  font-size: 2.25rem;
  /* 36px */
  line-height: 2.5rem;
  font-weight: 700;
  letter-spacing: -0.025em;
  color: #85134e;
  margin-left: 12px;
}

/* .spdc .tca {
  width: 552px;
  margin: 20px 0;
} */
.spdc h3 {
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.24px;
  line-height: 30px;
  width: 404px;
}

.spdc span {
  font-size: 14px;
  letter-spacing: 0.14px;
  line-height: 21px;
  font-weight: 600;
  color: #202124;
  text-align: center;
}

.spdc .tlc {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
}

.spdc .tlc .tli {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 0;
}

.sp-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  width: 600px;
  height: auto;
  padding: 15px;
  background-color: #fff;
  border-radius: 4px;
  /* margin-left: 76px; */
}

.spc-title {
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0.22px;
  line-height: 28px;
  color: #333;
  padding-top: 20px;
  margin: 0 0 16px 0;
  text-align: center;
}

.spc-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 18px;
}

.spc-form .form-lb {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: start;
}

.spc-form .form-bar {
  display: flex;
  align-items: center;
  margin: 16px 0 16px 8px;
}

.spc-form .form-lb label {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.16px;
  line-height: 20px;
  color: #202124;
}

.spc-form .form-lb input {
  width: 200px;
  height: 36px;
  border-radius: 3px;
  margin: 2px 0 3px 0;
  padding: 10px;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.14px;
  border: none;
  color: #777;
  background-color: #f2f2f2;
}

.spc-form .form-lb .select {
  width: 204px;
  height: 40px;
  border-radius: 3px;
  margin: 2px 0 3px 0;
  padding: 12px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.14px;
  border: none;
  color: #777;
  background-color: #f2f2f2;
  border: none;
  outline: none;
}

.spc-form .form-icp {
  position: absolute;
  width: 32px;
  height: 16px;
  background-color: transparent;
  right: 15px;
  top: 36px;
  cursor: pointer;
}

.spc-form .form-lb input:focus,
select:focus {
  border: none;
  outline: none;
}

.spc-form .form-subcta {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* max-width: 432px; */
  /* margin: 36px 20px 0 20px; */
}

.spc-form .form-sub {
  padding: 10px;
  width: calc(100% - 15px);
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #6a0f3e;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  border-radius: 4px;
  cursor: pointer;
  border: none;
}

.spc-form .form-subdi {
  padding: 10px;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #bebebe;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  border-radius: 4px;
  cursor: not-allowed;
  border: none;
}

.spc-form .corpus {
  position: relative;
  margin-top: 20px;
  padding-left: 25px;
  font-size: 9px;
  letter-spacing: 0.2px;
  line-height: 11px;
  font-weight: 600;
  height: 32px;
  width: calc(100% - 15px);
  display: inline-block;
  background-color: #fff;
  color: #303030;
}

.spc-form .form-link {
  cursor: pointer;
  font-size: 9px;
  letter-spacing: 0.2px;
  line-height: 11px;
  color: #781146;
}

.form-bloc {
  padding-top: 0px;
}

.clc h2 {
  font-size: 26px;
  font-weight: 700;
  line-height: 40px;
  color: #202124;
  margin-top: -12px;
}

.clc h3 {
  font-size: 18px;
  line-height: 24.84px;
  margin-top: -12px;
  color: #202124;
}

.flex-row {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.form-lb {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: start;
}

.form-bar {
  display: flex;
  align-items: center;
  margin: 8px 0 8px 0;
}

.form-lb label {
  margin-bottom: 8px;
  color: #202124;
}

.form-lb input {
  width: 192px;
  height: 20px;
  border-radius: 3px;
  margin: 2px;
  padding: 6px 8px 6px 8px;
  font-size: 14px;
  line-height: 24px;
  border: 1.8px solid #dcdce4;
  color: #202124;
}

.form-lb select {
  width: 192px;
  height: 32px;
  border-radius: 3px;
  margin: 2px;
  padding: 6px 8px;
  font-size: 14px;
  line-height: 24px;
  border: 1.8px solid #dcdce4;
  color: #202124;
}

.form-lb .li {
  width: 258px;
  height: 20px;
  border-radius: 4px;
  margin: 2px;
  padding: 6px 8px 6px 8px;
  font-size: 16px;
  line-height: 24px;
}

.form-lb .mli {
  margin-left: 4px;
}

.form-lb .mdli {
  margin-left: 24px;
}

.form-subcta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* max-width: 296px; */
  margin: 28px 0 0 0;
  padding-bottom: 20px;
}

.crc {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.crc img {
  width: 396px;
  height: 396px;
  margin-left: 40px;
}

.cta-card {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0px;
}

.rdt {
  max-width: 240px;
  font-size: 11px;
  font-weight: normal;
  margin-left: 12px;
}

.form-sub {
  width: 96px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #85134e;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  border-radius: 4px;
  cursor: pointer;
  border: none;
}

.cancel_btn {
  width: 96px;
  height: 36px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #85134e;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  border-radius: 4px;
  cursor: pointer;
  border: none;
}

.form-subdi {
  width: 100%;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #bebebe;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  border-radius: 4px;
  cursor: not-allowed;
  border: none;
}

.form-link {
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0.15px;
  line-height: 20px;
  color: #781146;
}

.corpus {
  padding-top: 0px;
  position: sticky;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  color: #303030;
}

.lsbar {
  position: sticky;
  height: 97vh;
  width: 221px;
  float: left;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  z-index: 2;
  background-color: #0A0A0A;
  margin: 1em;
  /*background: linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12)), linear-gradient(0deg, rgba(255, 255, 255, 0.04), rgba(255, 255, 255, 0.04));*/
  border: 2px solid #FFFFFF1F;
  border-radius: 32px;
  overflow: hidden;
}

@media screen and (max-width:990px) {
  .lsbar {
    display: none;
  }
}

.fond12 {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: url("../assets/new_images/sidebarfond.svg");
  background-position: left;
  background-repeat: no-repeat;
}

.dh {
  width: 207px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0 20px 0;
}

.hr {
  display: flex;
  width: 92%;
  height: 1.77px;
  background-color: #eaeaea;
  margin-left: 8px;
}

.ic {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  width: 42px;
  border-radius: 4px;
  margin-left: 3px;
}

.dhico {
  margin: 3px 3.6px 0 0;
  height: 40px;
  width: 40px;
}

.dht {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  padding: 0 0 0 8px;
}

.dht a {
  margin-top: 8px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;
}

.dht p {
  margin-top: 2.8px;
  font-size: 12px;
  line-height: 15.96px;
}

.rsco {
  width: calc(100% - 631px);
  background-color: black;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  overflow-x: auto;
}

@media screen and (max-width:990px) {
  .rsco {
    width: 100%;
  }
}

/* Hide the scrollbar */
.rsco::-webkit-scrollbar {
  display: none;
  /* Hide the scrollbar for WebKit browsers (Chrome, Safari, etc.) */
}

.dmco {
  width: 100%;
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.rsco-h h2 {
  font-size: 36px;
  font-weight: 400;
  letter-spacing: -0.41px;
  margin-left: -480px;
  margin-top: -68px;
  color: #202124;
}

.prco {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dpa {
  width: 132px;
  height: 132px;
  border-radius: 50%;
}

.prco span {
  font-size: 28px;
  line-height: 44.16px;
  letter-spacing: -0.15px;
  font-weight: 700;
  margin-top: 12px;
}

.sbop {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: space-between;
  width: 240px;
  height: 100%;
  margin-left: 1em;
}

.sbop .logout {
  margin-top: auto;
}

.mtion {
  margin-top: 2.8px;
}

.mtion2 {
  margin: 6px 0 0 0;
  padding-left: 28px;
}

.sbop ul {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  width: 203px;
  list-style-type: none;
}

.sbop ul .li {
  font-size: 11px;
  font-weight: 600;
  line-height: 15px;
  margin-bottom: 2px;
  text-transform: uppercase;
}

.sbop ul li {
  width: 190px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: start;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  padding: 10px 0 10px 0;
  cursor: pointer;
  /*color: #202124;*/
  position: relative;
}

.sbop ul li img {
  fill: gray;
}

.linker:hover {
  color: #781146;
}

.nam {
  color: gray;
  font-weight: 100;
}

.ico {
  margin-left: 1em;
  opacity: 0.5;
}

.ico .active {
  opacity: 1;
}

.sbop ul .sp,
.active {
  transition: all ease-out 0.3s;
}

.sbop ul .sp:hover {
  background: #FFFFFF0A;
  color: white;
  border-radius: 15px;
}

.sbop ul .sp:hover .ico {
  opacity: 1;
}

.sbop ul .sp:hover span {
  color: white;
  font-weight: 600;

}

.sbop ul .sp.active .ico {
  opacity: 1;
}

.sbop ul .active {
  color: #85134e;
  fill: #85134e;
  background: #FFFFFF0A;
  border-radius: 15px;
}

.sbop ul .active span {
  font-weight: 600;
  color: white;
}

.sbop ul .active img {
  fill: white;
}

.sbop ul .ered {
  position: relative;
  color: #FF0000;
  background: #270909;
  overflow: hidden;
  width: 100vw;
  margin-left: -2em;
  padding-left: 2em;
}

.sbop ul .ered:hover {
  background: #270909;
}

.lgelt {
  position: relative;
  cursor: pointer;
  color: gray;
}

.nocontent__container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
}

/*==================================================*/

.custom-phone-input {
  width: 100% !important;
  border: none !important;
  outline: none !important;
  background-color: transparent !important;
  border-radius: 1rem !important;
  object-fit: contain !important;
}

.custom-phone-input .form-control {
  width: calc(100% - 3rem) !important;
  background-color: transparent !important;
  outline: none !important;
  border: 1px solid #FFFFFF1F !important;
  border-radius: 1rem !important;
  padding-top: 0.7rem !important;
  padding-bottom: 0.7rem !important;
  padding-left: 3rem !important;
  /* Adjust padding-left to account for flag dropdown */
  margin-right: 1rem !important;
}

.custom-phone-input .flag-dropdown {
  background-color: transparent !important;
  border-radius: 1rem !important;
}

.custom-phone-input .flag-dropdown:hover {
  background-color: transparent !important;
}

.custom-phone-input .selected-flag {
  background-color: transparent !important;
  border-radius: 1rem !important;
  padding-bottom: 1rem !important;
}

.custom-phone-input .selected-flag:hover {
  background-color: transparent !important;
}

.custom-phone-input .selected-flag.open {
  background-color: transparent !important;
}

.custom-phone-input .country-list {
  color: white !important;
  background-color: #313131 !important;
  border-radius: 1rem !important;
}

.custom-phone-input .country-list::-webkit-scrollbar {
  display: none !important;
}

.custom-phone-input .country-list .country:hover {
  background-color: rgba(19, 19, 19, 1) !important;
}

.custom-phone-input .country-list .country.highlight {
  background-color: rgba(19, 19, 19, 1) !important;
}

/*==========================================================*/

.nocontent__img {
  width: 10rem;
  height: 10rem;
  object-fit: cover;
}

.nocontent__text {
  color: white;
  font-size: 1.5rem;
}

.nocontent__link {
  padding: 1rem 1.5rem;
  color: #c91c31;
  background: #FF00001F;
  border-radius: 0.5rem;
  font-size: 1.5rem;
}

.sbop ul li span {
  margin-left: 1em;
}

.esi {
  max-width: 280px;
  max-height: 280px;
  margin-bottom: 12px;
}

.est {
  font-size: 24px;
  line-height: 33.12px;
  letter-spacing: -0.15px;
  font-weight: 400;
  color: #202124;
  margin-bottom: 20px;
}

.escta {
  width: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #85134e;
  color: #fff;
  padding: 10px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 8px;
  cursor: pointer;
}

.ptt {
  font-size: 26px;
  font-weight: 700;
  letter-spacing: -0.41px;
  color: #202124;
}

.form-icp {
  position: absolute;
  width: 36px;
  height: 20px;
  background-color: #fff;
  right: 15px;
  top: 60px;
  cursor: pointer;
}

ol,
ul,
menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

.lsdro {
  border: none;
  background-color: transparent;
  font-size: 16px;
  font-weight: 700;
  color: #6a0f3e;
  outline: none;
  padding: 8px;
}

.emt {
  font-size: 10.4px;
  line-height: 12px;
  font-weight: 500;
  color: #c91c31;
}

.emti {
  font-size: 11px;
  font-weight: 700;
  color: #781146;
  cursor: pointer;
}

.myImage {
  display: none;
}

.myAudio {
  display: none;
}

.flex-row-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mli {
  margin-left: 24px;
}

.ml-4 {
  margin-left: 4px;
}

.ml-8 {
  margin-left: 8px;
}

.ml-12 {
  margin-left: 12px;
}

.mr-12 {
  margin-right: 12px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-24 {
  margin-left: 24px;
}

.ml-28 {
  margin-left: 28px;
}

.ml-32 {
  margin-left: 32px;
}

.ml-36 {
  margin-left: 36px;
}

.ml-40 {
  margin-left: 40px;
}

.ml-48 {
  margin-left: 48px;
}

.ml-72 {
  margin-left: 72px;
}

.ml-96 {
  margin-left: 96px;
}

.ml-100 {
  margin-left: 100px;
}

.ml-120 {
  margin-left: 120px;
}

.ml-160 {
  margin-left: 160px;
}

.ml-180 {
  margin-left: 180px;
}

.mlr-12 {
  margin: 0 12px 0 12px;
}

.mlr-20 {
  margin: 0 20px 0 20px;
}

.mlr-24 {
  margin: 0 24px 0 24px;
}

.mtb-4 {
  margin: 4px 0 4px 0;
}

.mtb-8 {
  margin: 8px 0 8px 0;
}

.mtb-12 {
  margin: 12px 0 12px 0;
}

.mtb-16 {
  margin: 16px 0 16px 0;
}

.mt-12 {
  margin: 12px 0 0 0;
}

.mt-10 {
  margin: 4px 0 0 1px;
}

.mt-16 {
  margin: 16px 0 0 0;
}

.mt-20 {
  margin: 20px 0 0 0;
}

.mb-12 {
  margin: 0 0 12px 0;
}

.mb-16 {
  margin: 0 0 16px 0;
}

.mb-20 {
  margin: 0 0 20px 0;
}

.mb-28 {
  margin: 0 0 28px 0;
}

.mb-32 {
  margin: 0 0 32px 0;
}

.p-12 {
  padding: 12px;
}

.mt--12 {
  margin-top: -128px;
}

.main-co {
  background-color: #f2f2f2;
}

.bxs {
  box-shadow: rgba(33, 33, 52, 0.1) 0px 1px 4px 0px;
}

.cre {
  flex: 1 1 auto;
  min-width: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5pt;
  margin: 20px 0 0 0;
  cursor: pointer;
}

.cred {
  flex: 1 1 auto;
  min-width: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5pt;
  margin: -3px 0 0 0;
  cursor: pointer;
}

/*  ===============================================================================================================   */
/*login page design */

.loginpage {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: black;
}

/* @media screen and (max-width: 1024px) {
  .loginpage{
    height: 100vh;
  }
} */
.loginfond {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("../assets/new_images/loginFond.svg") left/cover no-repeat;
}

.logincontent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* position: absolute;
  top: 0;
  left: 0; */
  width: 100%;
  height: 100%;
  min-height: 100vh;
  margin: auto;
}

.firstside {
  width: 450px;
  height: 652px;
  display: flex;
  justify-content: center;
  align-items: end;
  flex-direction: column;
  gap: 3rem;
  margin: auto;
  /* background-color: green; */
}

.firstside11 {
  width: auto;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: end;
  flex-direction: column;
  gap: 3rem;
  padding: 5rem;
  /* margin-right: 3rem; */
  /* background-color: green; */
}

@media screen and (max-width: 600px) {
  .firstside11 {
    width: 100%;
    min-height: 100vh;
    padding: 25px 0;
    background: url("../assets/illustrations/sign_bg.png") center/cover no-repeat;
    /* background-color: #161616; */
  }
}

.firstside2 {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  gap: 2rem;
  /* background-color: orange; */
}

.secondside {
  width: 50%;
  height: 100%;
  min-height: 100vh;
  padding: 10rem 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    linear-gradient(0deg, #FF5620, #FF5620);
}

@media screen and (max-width: 990px) {
  .secondside {
    display: none;
  }
}

.secondsidetitle {
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: center;
  color: #FFFFFF;
  width: 80%;
}

.secondsidetext {
  /* margin-top: 2rem; */
  color: #FFFFFFCC;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  width: 85%;
}

.secondsidefond {
  margin-top: 2rem;
  width: 100%;
  height: 614.24px;
  background: url("../assets/new_images/macbook.png") center/cover no-repeat;
  margin-bottom: 2rem;
}

.secondsidecopyright {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px;
  gap: 8px;
  color: #FFFFFFA3;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}

.secondsideterms {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  text-decoration: underline;
}

.firstsidecard {
  width: 426px;
  height: 100%;
  padding: 3rem;
  border-radius: 32px;
  border: 1px;
  gap: 15px;
  background: #FFFFFF0A;
  border: 1px solid #FFFFFF1F;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
}

@media screen and (max-width: 600px) {
  .loginfond {
    width: 100%;
    height: 100%;
    background-color: #161616;
  }

  .firstside {
    width: 100%;
    height: 100vh;
    padding: 25px 0;
    background: url("../assets/illustrations/sign_bg.png") center/cover no-repeat;
  }

  .firstsideimage {
    margin: 0 auto;
  }

  .firstsidecard {
    width: 95%;
    margin: auto;
    background: transparent;
    border: 0;
  }
}

.firstsidecard1 {
  width: auto;
  padding: 3rem;
  border-radius: 32px;
  gap: 2rem;
  background: #FFFFFF0A;
  border: 1px solid #FFFFFF1F;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: center;
  color: white;
}

@media screen and (max-width: 600px) {
  .firstsidecard1 {
    width: 95%;
    margin: auto;
    padding: 1.5rem;
    background: transparent;
    border: 0;
  }
}

@media screen and (max-width: 550px) {
  .firstside11 {
    padding: 2.5rem;
  }

  .firstsidecard1 {
    padding: 0.5rem;
  }

  .firstsidecard1 div {
    grid-column: span 2;
  }
}

.firstsidetitle {
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
}

.firstsidetitle11 {
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  grid-column: span 2;
}

.checkmarkcircle {
  grid-column: span 2;
  align-self: center;
  justify-self: center;
}

.phoneInput {
  width: 100%;
  background: transparent;
  outline: none !important;
}

.firstsidepasswordforgot {
  color: #FF5620;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: start;
}

.firstsideconfidentialitetext {
  color: #FFFFFFCC;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: start;
}

.firstsideconfidentialitetext11 {
  color: #FFFFFFCC;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: justify;
}

.firstsideconfidentialite {
  text-decoration: underline;
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: start;
}

.firstsidebutton {
  width: 100%;
  margin: auto;
  background: #FF5620;
  color: white;
  font-weight: 100;
  font-size: 16px;
  padding: 1rem;
  border-radius: 30px;
  cursor: pointer;
}

.firstsidebutton-off {
  width: 100%;
  margin: auto;
  background: gray;
  color: white;
  font-weight: 100;
  font-size: 16px;
  padding: 1rem;
  border-radius: 30px;
}

.firstsidenoaccount {
  color: #FFFFFFCC;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: start;
}

.firstsidenoaccount11 {
  color: #FFFFFFCC;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  grid-column: span 2;
  text-align: center;
}

.firstsidecreate {
  color: #FF5620;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: start;
  cursor: pointer;
}

.firstside-spinner {
  margin: auto;
  margin-top: 0;
  margin-bottom: 0;
}

.emt {
  margin-top: -1rem;
}

/*  ===============================================================================================================   */

.main-dash {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

@media screen and (max-width:990px) {
  .main-dash {
    /* padding: 15px; */
    background-color: #161616;
  }
}

.main-dash::-webkit-scrollbar {
  display: none;
}

.search-utils {
  width: 100%;
  margin-top: 1em;
  padding-left: 1em;
  padding-right: 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search {
  width: 50%;
  border-radius: 16px;
  height: 48px;
  background: #FFFFFF1F;
  padding: 12px;
  position: relative;
}

.search-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFFA3;
  position: absolute;
  top: 5%;
  text-decoration: none;
  border: none !important;
  box-shadow: none !important;
}

.search-ico {
  width: 24px;
  height: 24px;
  position: absolute;
  right: 2%;
  top: 20%;
}

.utils {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5em;
  width: 350px;
  position: fixed;
  right: 2em;
}

.notif {
  width: 50px;
  height: 50px;
  position: relative;
  border-radius: 80px;
  background: #1F1F1F;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  cursor: pointer;
}

.notif-img {
  width: 24px;
  height: 24px;
}

.notif-num {
  position: absolute;
  right: 1em;
  top: 0.1em;
  width: 15px;
  height: 15px;
  font-size: 8px;
  font-weight: 600;
  color: white;
  background: #FB5828;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.telev {
  width: 150px;
  color: black;
  background: white;
  border-radius: 50px;
  display: flex;
  justify-content: space-evenly;
  align-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  padding: 0.4em;
}

.televimg {
  width: 30px;
  height: 30px;
}


.greed {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  align-content: center;
  padding-left: 1em;
  padding-right: 1em;
  margin-top: 2em;
}

.greed1 {
  display: flex;
  justify-content: start;
  width: 100%;
  gap: 1em;
  flex-direction: column;
}

.greed-art1 {
  font-size: 45px;
  color: white;
  width: 95%;
  font-weight: 700;
}

.greed-art2 {
  font-size: 16px;
  color: #FFFFFFCC;
  width: 99%;
  font-weight: 200;

}

.greed2 {
  display: flex;
  flex-direction: column;
  width: 95%;
  gap: 1em;
  height: fit-content;
  margin-top: 1em;
}

.greed2elt1 {
  display: flex;
  align-content: center;
  justify-content: start;
  align-items: center;
  height: 48px;
  background: #1F1F1F;
  padding: 8px;
  opacity: 0.4;
  border-radius: 80px;
  gap: 16px;
}

.greed2img {
  width: 32px;
  height: 32px;
}

.greed2text1 {
  font-size: 14px;
  width: 282px;
  line-height: 17.5px;
  color: #FFFFFF;
  font-weight: 100;
}

.greed2text2 {
  font-weight: 500;
  font-size: 14px;
  width: 282px;
  line-height: 17.5px;
  color: #FF5620;
}

.greed2elt2 {
  display: flex;
  align-content: center;
  justify-content: start;
  align-items: center;
  height: 48px;
  padding: 8px;
  border-radius: 80px;
  gap: 16px;
  background: #FFFFFF1F;
}

@media screen and (min-width:991px) {
  .mob-top-bar {
    display: none;
  }

}

.datas {
  width: 100%;
  margin-top: 2em;
  /* padding-right: 2rem; */
  /* padding-left: 2rem; */
  display: flex;
  justify-content: space-between;
  position: relative;
}

@media screen and (max-width: 990px) {

  .datas {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}

.main-side {
  width: 100%;
  /*overflow-y: scroll;*/
}

.main-side-title {
  font-size: 24px;
  font-weight: 400;
  color: white;
}

.monit {
  display: flex;
  justify-content: space-between;
  /* put it in space-evently on responsive */
  align-items: start;
  width: 100%;
  margin-top: 1em;
  flex-wrap: wrap;
  /* background-color: red; */
}

.monit-elt {
  min-width: 200px;
  min-height: 169px;
  border-radius: 24px;
  margin: 1em 0.25em;
  border: 1px solid #FFFFFF3F;
  display: flex;
  flex-direction: column;
  background: #0A0A0A;
  gap: 5px;
  padding: 24px;
  overflow: hidden;
  justify-content: center;
  transition: 0.2s linear;
  flex: 1;
}

@media screen and (max-width: 555px) {
  .monit-elt {
    width: 100%;
  }
}

@media screen and (min-width: 991px) and (max-width: 1188px) {
  .monit-elt {
    width: 100%;
  }
}

.monit-elt:first-child {
  border: 1px solid #FF5620;
  background: #280E06;
  transition: 0.1s linear;
}

.monit-elt:first-child:hover {
  border: 1px solid #FFFFFF3F;
  background: #0A0A0A;
}

.monit-elt:hover {
  border: 1px solid #FF5620;
  background: #280E06;
  transition: 0.1s linear;
}

/*.monit-elt:hover .monit-main{*/
/*  color: #FF5620;*/
/*}*/

.monit-elt-top {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

.monit-title {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -0.15px;
  color: #FFFFFFB2;
  min-width: 100px;
}

.monit-elt-ico {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.monit-main {
  font-weight: 700;
  font-size: 43.24px;
  color: white;
}

.monit-foot {
  display: flex;
  height: 2rem;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  align-content: center;
  font-size: 14px;
  font-weight: 500;
  color: #FFFFFFB2;
}

.monit-ico {
  width: 0.7rem;
  height: 0.7rem;
  object-fit: cover;
}

.side-panel {
  /*width: 25%;*/
  width: 95%;
  background: #141414;
  border: 1px solid #FFFFFF1F;
  padding: 1em;
  border-radius: 24px;
  margin-top: 1.7em;
}

.dropz {
  width: 95%;
  margin: auto;
  outline: 1px dotted #FF56209E;
  /* Use outline instead of border */
  border-radius: 15px;
  background: #1D1614;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 2em;
}

.dropz--success {
  width: 95%;
  margin: auto;
  outline: 1px dotted green;
  /* Use outline instead of border */
  border-radius: 15px;
  background: #021802;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 2em;
}

.dropz--error {
  width: 95%;
  margin: auto;
  outline: 1px dotted red;
  /* Use outline instead of border */
  border-radius: 15px;
  background: #3b0000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 2em;
}


.dropzimg {
  width: 32px;
  height: 32px;
  padding: 4px;
  fill: #FF5620;
  border-radius: 12px;
  background: #FF56201F;
}

.dropztitle {
  font-weight: 300;
  font-size: 14px;
  color: #FFFFFFA3;
  line-height: 19px;
  width: 90%;
}

.dropztitle-success {
  font-weight: 300;
  font-size: 14px;
  color: green;
  line-height: 19px;
  width: 90%;
}

.dropztitle-error {
  font-weight: 300;
  font-size: 14px;
  color: red;
  line-height: 19px;
  width: 90%;
}

.trtl {
  width: 95%;
  background: #313131;
  margin: auto;
  margin-top: 1em;
  border-radius: 16px;
  padding: 0.3em;
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
}

.trtl-ac {
  width: 45%;
  background: #4A4A4A;
  font-weight: 200;
  font-size: 16px;
  color: white;
  padding: 0.5em;
  display: flex;
  justify-content: center;
  border-radius: 12px;
  cursor: pointer;
}

.trtl-inac {
  width: 45%;
  color: #B5B5B5;
  font-weight: 200;
  font-size: 16px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.inst-data {
  width: 95%;
  margin: auto;
  margin-top: 1em;
}

.insta-data-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #FFFFFF;
}

.inst-data-subtitle {
  display: flex;
  align-items: center;
  margin-top: 0.5em;
  padding-bottom: 1em;
  gap: 0.5em;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #FFFFFFB2;
}

.telev-button {
  width: 100%;
  background: #FF5620;
  border-radius: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.telev-text {
  color: white;
  font-size: 1rem;
}

.telev-img {
  width: 1.5rem;
  height: 1.5rem;
}

.inst-data-dot {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background: #008000;
}

.last_upload {
  width: 100%;
  border: 0.1rem solid gray;
  background: #1D1D1D;
  padding: 0.8rem;
  border-radius: 1rem;
}

.last_upload_elt {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
}

.last_upload_side {
  display: flex;
  gap: 1em;
  align-content: center;
  align-items: center;
  color: white;
}

.last_upload_sidee {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.2em;
  color: white;
  font-size: 1.5rem;
}

.last_upload_title {
  font-size: 1rem;
  color: white;
}

.last_upload_users {
  font-size: 1rem;
  color: gray;
}

.last_upload_img {
  width: 4rem;
  height: 4rem;
  border-radius: 0.5rem;
}

.last_upload_user_type {
  width: fit-content;
  padding: 0.2rem;
  border: 0.1rem solid #FF5620;
  background: #38241D;
  font-size: 0.5rem;
  letter-spacing: 0.2em;
  border-radius: 0.3rem;
  color: #FF5620;
}

.last_upload_more {
  width: 2rem;
  height: 2rem;
  position: relative;
  color: gray;
}

.last_upload_menu {
  position: absolute;
  width: 10rem;
  top: 2.5rem;
  right: 0;
  background: #444444;
  border: 0.1rem solid gray;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  cursor: pointer;
  border-radius: 1rem;
  z-index: 2;
}

.last_upload_menu_elt {
  padding: 1rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  color: white;
  font-size: 1rem;
}

.divv-menu {
  width: 100%;
  border: none;
  /* Remove default border */
  border-top: 0.1rem solid gray;
}

.divv {
  margin-top: 0.1em;
  border: none;
  /* Remove default border */
  border-top: 0.5px solid #262626;
  /* Set border-top to create the line */
}

.divv2 {
  width: 100%;
  margin-top: 0.8em;
  margin-bottom: 0.8em;
  border: none;
  /* Remove default border */
  border-top: 1px solid #262626;
  /* Set border-top to create the line */
  position: relative;
}

.subdivvv2 {
  width: 75px;
  position: absolute;
  top: -1px;
  left: 0;
  border-top: 2px solid white;
  transition: all 0.3s ease;
}

.subdivvv22 {
  width: 75px;
  position: absolute;
  top: -1px;
  left: 90px;
  border-top: 2px solid white;
  transition: all 0.3s ease;
}

.inst-data-values {
  display: flex;
  gap: 3em;
}

.inst-data-elt {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.2em;
  justify-content: space-between;
}

.inst-data-elt-title {
  font-weight: 200;
  font-size: 14px;
  line-height: 19px;
  color: #FFFFFFB2;
}

.inst-data-elet-val {
  font-weight: 600;
  font-size: 1.5rem;
  color: white;
}

.lect {
  display: flex;
  width: 100%;
  gap: 1em;
  margin-top: 1em;
  align-items: center;
}

.lect-img {
  border-radius: 16rem;
  width: 3.2rem;
  height: 3.2rem;
  overflow: hidden;
}

.lect-side {
  display: flex;
  width: 80%;
  flex-direction: column;
  justify-content: space-evenly;
  color: #5E5E5E;
  font-weight: 100;
}

.lect-up-artisname {
  font-size: 0.6rem;
}

.lect-up {
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-weight: 300;
  color: white;
}

.lect-up-title {
  max-width: 80%;
  max-lines: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1rem;
}

.lect-up1 {
  font-weight: 100;
  color: #5E5E5E;
  font-size: 1rem;
}

.cpright {
  display: flex;
  flex-direction: column;
  width: 85%;
  /* margin: auto;
  margin-top: -0.5em; */
}

.copyr {
  color: #FFFFFFA3;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}

.termss {
  width: 65%;
  display: flex;
  justify-content: space-between;
  color: #FFFFFFA3;
  font-weight: 400;
  font-size: 12px;
  text-decoration: underline;
}


.langgs {
  width: 2em;
  height: 2em;
  flex-shrink: 0;
  margin-left: 0.5em;
  display: flex;
  border-radius: 1000px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
}

.langgimgs {
  width: 60px;
  height: 60px;
}


.languagess {
  display: flex;
  align-items: center;
  gap: 1em;
  padding: 0.1em;
  border-radius: 1000px;
  background: rgba(255, 255, 255, 0.12);
}

.languagess select {
  /* -webkit-appearance : menulist-button; */
  /*appearance: none;*/
  background: transparent;
}

.languagess img {
  max-width: none;
}

.languagess span {
  color: #FFFFFF;
  leading-trim: both;
  text-edge: cap;
  /*font-family: "Satoshi Variable";*/
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  /* 24px */
}

.listdrop1 {
  border: none;
  display: block;
  background-color: transparent;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  width: 85px;
  margin-right: 0.5em;
  color: white;
  outline: none;
  padding: 0;
  cursor: pointer;
}

.listdrop_container {
  display: flex;
  border-radius: 100px;
  padding-right: 1em;
  border: none;
  background: #1f2024;
  color: white;
  appearance: menulist-button;
  min-width: 150px;
}

.listdrop2 {
  border: none;
  appearance: auto;
  display: block;
  background-color: #FFFFFF1F;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  width: 22%;
  margin-right: 0.5em;
  color: white;
  outline: none;
  padding: 10px;
  border-radius: 30px;
  background-image: none;
}

.topfan {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid #FFFFFF1F;
  border-radius: 24px;
  padding: 1em;
  margin-top: 1em;
}

.topfan-top {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.topfan-titleee {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.topfan-titleee aside {
  display: flex;
  gap: 1rem;
  
}
.topfan-titleee .search-cont {
  padding-bottom: 0;
}
.topfan-title {
  font-weight: 600;
  font-size: 24px;
  color: white;
}

.topfan-title2 {
  font-weight: 600;
  font-size: 24px;
  color: gray;
  cursor: pointer;
}

.topfan-title2:hover {
  color: white;
}

.topfan-top-right {
  width: 58%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  font-weight: 100;
}


.search2 {
  width: 58%;
  border-radius: 50px;
  background: #FFFFFF1F;
  padding: 1.5em;
  position: relative;
}

.search2-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFFA3;
  position: absolute;
  top: 5%;
  text-decoration: none;
  border: none !important;
  box-shadow: none !important;
}

.search2-ico {
  width: 24px;
  height: 24px;
  position: absolute;
  right: 5%;
  top: 20%;
}

.clss {
  width: 15%;
  text-align: center;
}

.main-side-elt {
  width: 100%;
  margin-top: 5rem;
}

.albums-data {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  flex-wrap: wrap;
  margin-top: 23px;
}

.albums-data div {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 200px;
  gap: 12px;
}

.albums-data div img {
  display: flex;
  min-width: 200px;
  height: 150px;
  object-fit: cover;
  border-radius: 18px;
}

.albums-data .view-all {
  margin-top: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}

.albums-data .view-all:hover {
  transform: scale(1.1);
}

.albums-data .view-all img {
  width: 91px !important;
  height: 85px;
}

.topfan-titles {
  display: grid;
  grid-template-columns: 3fr 1fr 1fr 1fr;
  /* Divide the screen into 5 parts with the first element taking 2 parts */
  align-items: center;
  margin-top: 2em;
}

.load-more {
  padding: 2rem;
  display: flex;
  justify-content: center;
  gap: 1.5rem;
}

.load-more-title {
  cursor: pointer;
  padding: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-radius: 0.5rem;
  color: white;
  border: 1px solid white;
}

.topfan-r {
  display: flex;
  align-content: center;
  align-items: center;
  color: white;
  font-weight: 700;
  font-size: 12px;
}

.topfan-titlee {
  font-size: 16px;
  color: gray;
  font-weight: 600;
  padding-left: 10px;
}

.topfan-titlee2 {
  font-size: 20px;
  color: gray;
  font-weight: 200;
}

.topfan-elt-img {
  width: 60px;
  height: 60px;
  border-radius: 100px;
  margin-left: 1em;
}

.topfan-r1 {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  color: white;
  margin-left: 1em;
  font-size: 14px;
  font-weight: 500;
}

.topfan-r2 {
  display: flex;
  gap: 1em;
  color: gray;
}

.toppfan-elt-img2__container {
  width: 60px;
  height: 60px;
  border-radius: 10px;
  margin-left: 1em;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  overflow: hidden;
}

.topfan-elt-img2 {
  width: 60px;
  height: 60px;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  transition: 0.2s ease;
  object-fit: cover;
}

.cover-image {
  object-fit: cover;
  width: 48px;
  height: 48px;
  border-radius: 10px;
}

.topfan-elt-img3 {
  width: 60px;
  height: 60px;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.2s ease;
}

.topfan-elt-img2__hover {
  display: none !important;
}

.topfan-elt-img2:hover {
  transition: 0.2s ease;
  opacity: 0.8;
}

.toppfan-elt-img2__container:hover .topfan-elt-img2__hover {
  display: flex !important;
  position: absolute !important;
  justify-content: center;
  width: 2rem !important;
  height: 2rem !important;
  color: white !important;
  cursor: pointer !important;
  background: gray !important;
  padding: 0.2rem !important;
  border-radius: 100rem !important;
  transition: 0.5s ease !important;
}

.two-bloc {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.two-bloc-elt {
  width: 48%;
}

.topsing {
  width: 100%;
  border: 2px solid #FFFFFF1F;
  border-radius: 30px;
  padding: 2em;
  margin-top: 2em;
  display: flex;
  flex-direction: column;
}

.topsingtitle {
  width: 100%;
  font-weight: 600;
  font-size: 20px;
  color: gray;
  display: flex;
  gap: 0.5em;
}

.topsingval {
  color: white;
  font-weight: 600;
  font-size: 70px;
}

.topsing-top {
  margin-top: 2em;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.topsing-title {
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.topsing-span1 {
  font-size: 22px;
  color: white;
  font-weight: 600;
  border-bottom: 2px solid white;
}

.topsing-span2 {
  font-size: 22px;
  font-weight: 200;
  color: gray;
}

.topsing-top-right {
  width: 32%;
}

.listdrop3 {
  width: 100%;
  display: block;
  border-radius: 100px;
  padding-left: 1em;
  border: none;
  background: #1f2024;
  color: white;
  appearance: menulist-button;
  outline: none;
  cursor: pointer;
}

.topsing-titles {
  margin-top: 2em;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

.topsing-r {
  display: flex;
  gap: 1em;
  align-items: center;
  align-content: center;
  color: gray;
  font-size: 18px;
  font-weight: 500;
}

.topsing-rr {
  display: flex;
  gap: 0.3em;
  align-items: center;
  align-content: center;
  flex-direction: column;
  color: gray;
}

.topsing-re {
  display: flex;
  gap: 0.2em;
  color: white;
  font-weight: 500;
}

.topsing-elt-img {
  border-radius: 15px;
  width: 65px;
  height: 65px;
}

.topsing-r1 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.1em;
  color: white;
  font-weight: 600;
  font-size: 20px;
}

.topsing-r2 {
  display: flex;
  gap: 0.5em;
  color: gray;
  font-weight: 400;
  font-size: 20px;
}

.divvvv {
  width: 110%;
  margin-left: -5%;
  border: none;
  border-top: 0.1px solid gray;
}

.botmbtn {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 1em;
}

.botmtext {
  color: white;
  font-weight: 600;
  font-size: 24px;
}

.botmimg {
  width: 45px;
  height: 45px;
}

.orgprog {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 0.8em;
}

.orgprogleft {
  display: flex;
  flex-direction: column;
  margin-bottom: 2em;
}

.orgprogleft-top {
  display: flex;
  gap: 0.5em;
}

.orgprog-top-right {
  width: 20%;
  font-size: 16px;
}

.orgprog-flag {
  width: 20px;
  height: 20px;
}

.orgprog-title {
  display: flex;
  align-content: center;
  align-items: center;
  gap: 0.5em;
}

.audi {
  width: 100%;
}

.audii {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  margin-top: -1em;
}

.audi-l {
  display: flex;
  margin-bottom: 2em;
  gap: 0.5em;
  /* min-width: 500px; */
  align-items: center;
}

.audi-s {
  width: 15%;
  height: fit-content;
  font-size: 16px;
  background: #1f2024;
  min-width: 150px;
  border-radius: 10rem;
}

.playpreview {
  width: 90%;
  margin-bottom: 1rem;
  display: flex;
  gap: 1rem;
  align-items: center;
}

.playpreview_musiccover {
  width: 3rem;
  height: 3rem;
  border-radius: 0.6rem;
  align-items: center;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.playpreview_content {
  width: -webkit-fill-available;
  width: fill-available;
  width: 85%;
  width: -moz-available;
  display: flex;
  gap: 1rem;
  align-items: center;
}

.playpreview_container {
  width: -webkit-fill-available;
  width: fill-available;
  width: 90%;
  width: -moz-available;
  display: flex;
  flex-direction: column;
  gap: 0.5rem
}

.playpreview_title {
  font-weight: 600;
  font-size: 1rem;
  color: white;
  max-width: 14rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

}

.playpreview_progressbar {
  width: 100%;
  height: 0.1rem;
  background: gray;
  border-radius: 100rem;
  display: flex;
}

.playpreview_progressbar_fill {
  width: 50%;
  height: 0.1rem;
  background: white;
  border-radius: 100rem;
}

.playpreview_playpause {
  width: 2rem !important;
  height: 2rem !important;
  color: white;
  cursor: pointer;
  background: gray;
  padding: 0.2rem;
  border-radius: 100rem;
}

/*  ===============================================================================================================   */
/*  Your Music section */

.topsingvalimg {
  margin-left: 0.2rem;
  Width: 15px;
  Height: 15px;
}

.topsingvaldescp {
  min-width: 100px;
}

.main-side-h-list {
  display: grid;
  grid-template-columns: 2fr 2fr 2fr 0.8fr;
  gap: 2rem;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-top: 1.5em;
}

.main-side-h-item {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.main-side-h-img {
  width: 100%;
  height: 15rem;
  border-radius: 20px;
  object-fit: cover;
  object-position: top;
}

.main-side-h-title {
  font-weight: 600;
  font-size: 18px;
  color: gray;
}

.main-side-h-subtitle {
  font-weight: 400;
  font-size: 16px;
  color: gray;
}

.main-side-h-see-more {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 0.5em;
  font-weight: 300;
  font-size: 16px;
  width: 100%;
  padding-top: 2rem;
  padding-bottom: 2rem;
  background: #1F1F1F;
  border-radius: 20px;
  color: white;
  text-align: center;
}

.main-side-h-see-more-img {
  width: 2rem;
  height: 2rem;
}

.noinfo {
  width: 100%;
  font-size: 0.8rem;
  color: white;
  display: block;
  text-align: center;
  padding-top: 1rem;
}

/*  ===============================================================================================================   */
/*  royalities */

.royalities-menu {
  font-size: 1.5rem;
  font-weight: 400;
  color: white;
}

.royalities-elt {
  width: 100%;
  padding-left: 1em;
  display: flex;
  flex-direction: column;
}

.royalities-title1 {
  font-size: 2rem;
  font-weight: 400;
  color: white;
  margin-top: 8rem;
}

.royalities-title {
  font-size: 2rem;
  font-weight: 400;
  color: white;
  margin-top: 2rem;
}

.royalities-subtitle {
  font-size: 1rem;
  font-weight: 400;
  color: gray;
  width: 38%;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}

.royalities-cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  justify-content: space-between;
  margin-top: 1.5em;
  grid-gap: 1rem;
  gap: 1rem;
}

.royalities-card {
  height: 200px;
  border-radius: 24px;
  margin-top: 1em;
  border: 1px solid #FFFFFF3F;
  display: flex;
  flex-direction: column;
  background: #0A0A0A;
  gap: 1rem;
  padding: 1em;
  overflow: hidden;
  justify-content: center;
  transition: 0.2s linear;
}

.royalities-card:hover {
  transform: scale(1.05);
}

.royalities-card-title {
  font-weight: 500;
  font-size: 1rem;
  letter-spacing: -0.01rem;
  color: #FFFFFFB2;
}


.royalities-card-main {
  font-weight: 700;
  font-size: 4rem;
  color: white;
}

.royalities-card-foot {
  display: flex;
  height: 2rem;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  align-content: center;
  font-size: 1rem;
  font-weight: 500;
  color: #FFFFFFB2;
}

.royalities-card-ico {
  width: 12px;
  height: 11px;
}

/*  ===============================================================================================================   */

.pro {
  flex: 1 1 auto;
  margin: 0 25px 0 8px;
  font-weight: 700;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #781146;
  max-width: 122px;
}

.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  font-weight: 500;
  text-transform: uppercase;
  background-color: #85134e;
  color: white;
}

.avatar>img {
  width: 100%;
  height: 100%;
}

.date-block {
  width: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f3e7ed;
  border-radius: 4px;
  padding: 10px 5px;
}

.date-block span {
  margin-left: 4px;
}

.iumain {
  padding: 24px 0 0 0;
}

.iucon {
  display: flex;
  align-items: center;
  justify-content: start;
  width: 880px;
  height: 320px;
  max-width: 98%;
  border-radius: 4px;
  border: 0px solid #eaeaea;
  margin: 28px 0 0 0;
}

.pun {
  font-size: 28pt;
  font-weight: 700;
  color: #781146;
  line-height: 20pt;
}

.sba {
  width: 98%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

.pre-co {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 68pt;
  font-weight: 500;
  letter-spacing: -3%;
  color: #ea7d1d;
}

.parent {
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  justify-content: start;
}

.box {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  align-content: baseline;
  margin: 16px;
}

.fesico {
  width: 132px;
  height: 132px;
  margin: 0 0 12px 0;
  cursor: pointer;
}

.fesico img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.mmt-3 {
  margin-top: 0.75rem;
}

.mmy-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.mmy-3 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.mmb-6 {
  margin-bottom: 1.5rem;
}

.moverflow-y-hide {
  overflow-y: hidden;
}

.rdc {
  width: 100%;
  height: 300px;
}

.siderLeft {
  background-color: #31031d;
}

.brel {
  color: #85134e;
  background-color: #f3e7ed;
}

.brti {
  color: #85134e;
}

.cudc {
  width: 532px;
}

@media screen and (max-width: 1200px) {
  .hc4 {
    top: 192px;
    left: 180px;
  }
}

@media screen and (max-width: 767px) {
  .hc4 {
    top: 110px;
    left: 150px;
  }

  .rhc {
    margin-top: 56px;
  }
}

@media screen and (max-width: 500px) {
  .hc4 {
    top: 60px;
    left: 100px;
  }
}

.white-50 {
  color: rgba(255, 255, 255, 0.50);
}

.white-64 {
  color: rgba(255, 255, 255, 0.64);
}


.search-cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  padding-left: 15px;
}

@media screen and (max-width: 750px) {
  .search-cont {
    flex-wrap: wrap;
  }

}
@media screen and (min-width: 990px) and (max-width: 1300px) {
  .search-cont {
    flex-wrap: wrap;
  }

}

.search-cont span.order {
  min-width: 60px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16.2px;
  color: #808080;
}

.search-cont .input-container {
  height: auto;
  width: auto;
  min-width: 150px;
  min-height: 42px;
  padding: 8px 16px;
  border-radius: 16px;
  border: 0;  
}
.search-cont .input-container .selected-option-placeholder {
  color: white;
}
.search-cont .input-container ul li{
    color: white;
}
