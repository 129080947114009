.mobileMenuContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 400px;
  height: 100vh;
  background-color: #fff;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  padding: 20px;
  transition: transform 0.3s ease-in-out;
}

.mobileMenuAnimate {
  transform: translateX(-100%);
}

.mobileMenuFirst {
  position: relative;
  width: 100%;
  height: auto;
}

.mobileMenuLogo {
  width: 120px;
  margin-top: -10px;
}

.mobileMenuClose {
  position: absolute;
  top: 5px;
  right: 0;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobileMenuContent {
  width: 100%;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.mobileMenuContent > a {
  text-decoration: none;
  font-size: 18px;
  font-weight: 800;
  margin-bottom: 20px;
  cursor: pointer;
}

.mobileMenuContent > a:hover {
  color: #731d49;
}

.mobileMenuBg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 30;
}

@media screen and (max-width: 576px) {
  .mobileMenuContainer {
    width: calc(100% - 80px);
  }
}

@media screen and (max-width: 400px) {
  .mobileMenuContainer {
    width: calc(100% - 40px);
  }
}
