/* Area chart */

.areaChartContainer {
  height: auto;
  width: 100%;
}

.areaChartFilterContainer {
  width: 100%;
  padding: 10px 20px;
  margin-bottom: 30px;
}

.areaChartSelect {
  margin-left: 20px;
  width: 180px;
  padding: 5px;
  padding-right: 10px;
  border-radius: 5px;
  background-color: #f5f5f5;
  border: 0;
}

.areaChartSelect:focus {
  outline: none;
}