.stepper-container{
    width: 50%;
    display: flex;
    gap: 1rem;
    align-items: center;
}

.stepper-step{
    display: flex;
    gap: 0.5rem;
    align-items: center;
}


.stepper-step-number{
    font-size: 1rem;
    font-weight: 500;
    color: white;
    padding: 1rem;
    border-radius: 50%;
    background-color: gray;
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;
    cursor: pointer;
}


.stepper-step-number.active{
    background-color: #FF5620;
    width: 3rem;
    height: 3rem;
    transition: all 0.3s ease;
}

.stepper-step-number.complete{
    background-color: #00BB13;
    color: #00BB13;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;
}

.stepper-step-title{
    font-size: 1rem;
    font-weight: 500;
    color: gray;
    display: none;
    transition: all 0.3s ease;
}

.stepper-step-title.active{
    display: block;
    transition: all 0.3s ease;
}

.stepper-step-title.complete{
    display: none;
    transition: all 0.3s ease;
}