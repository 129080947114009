.input-container{
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 58px;
    padding: 0.8rem;
    border-radius: 1rem;
    border: 1px solid #FFFFFF1F;
    gap: 0.2rem;
    position: relative;
}


.labelSelect{
    font-size: 0.8rem;
    margin-bottom: -0.2rem;
}

.input-label{
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #8A8A86;
}

.input-field-container{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    height: 1.5rem;
}

.input-required{
    color: #FF5620;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
}

.input-field {
    width: 100%;
    font-size: 16px;
    line-height: 27px;
    letter-spacing: 0em;
    font-weight: 300;
    text-align: left;
    color: white;
    text-decoration: none;
    border:none !important;
    outline: none;
    box-shadow: none !important;
    padding-bottom: -1rem;

}

.input-field:-webkit-autofill,
.input-field:-webkit-autofill:hover,
.input-field:-webkit-autofill:focus,
.input-field:-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffffff;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #23232329;
}


.input-field::-webkit-calendar-picker-indicator {
    color: rgba(0, 0, 0, 0);
    opacity: 1;
    display: block;
    background: url("../../assets/new_images/calendar.png") no-repeat;
    background-size: contain;
    cursor: pointer;
    width: 15px;
    height: 15px;
    border-width: thin;
}

.input-end-icon{
    width: 1.5rem;
    height: 1.5rem;
}

.encrypt{
    width: 1.2rem !important;
    height: 1.2rem !important;
    color: gray;
}