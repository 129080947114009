.btnAddAlbum {
  position: absolute;
  top: 0;
  right: 30px;
  color: #fff;
  background-color: #85134e;
  padding: 5px 10px;
  border-radius: 3px;
}

.album_main {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  width: 100%;
  height: 100%;
  padding: 2rem;
}

.top{
  display: grid;
  grid-template-columns: 3fr 9fr;
  gap: 2rem;
  width: 100%;
  height: 40%;
  align-items: center;
  justify-items: start;
}

.card{
  position: relative;
  width: 22rem;
  height: 22rem;
}
.topfan-elt-img2__hover{
  display: none !important;
}

.album_img_container{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 2rem;
  overflow: hidden;
}
.toppfan-elt-img2__container{
  width: 60px;
  height: 60px;
  border-radius: 10px;
  margin-left: 0.7em;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.toppfan-elt-img2__container:hover .topfan-elt-img2__hover{
  display: flex !important;
  position: absolute !important;
  justify-content: center;
  width: 2rem !important;
  height: 2rem !important;
  color: white !important;
  cursor: pointer !important;
  background: gray !important;
  padding: 0.2rem !important;
  border-radius: 100rem !important;
  transition: 0.5s ease !important;
}

.card1{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #FFFFFF29;
  border-radius: 2rem;
  transform: rotate(5deg);
}

.card2{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #FFFFFF29;
  border-radius: 2rem;
  transform: rotate(-5deg);
}

.album_img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.playpreview_playpause{
  width: 1rem;
  height: 1rem;
  cursor: pointer;
  color: white;
  cursor: pointer;
  background: gray;
  border-radius: 100rem;
}
.album_info{
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.price{
  position: absolute;
  top: 1rem;
  right: 1rem;
  border: 0.99px solid #FFFFFF1F;
  background: #FFFFFF29;
  padding: 0.8rem;
  padding-bottom: 0.4rem;
  padding-top: 0.4rem;
  color: #FFFFFF;;
  border-radius: 1rem;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: -0.14972922205924988px;
  text-align: left;
}

.currency{
  font-size: 0.6rem;
  font-weight: 400;
  letter-spacing: -0.14972922205924988px;
  text-align: left;
}

.album_author{
  font-size: 1rem;
  font-weight: 400;
  text-align: left;
  color: #FFFFFFA3;
}

.album_title{
  font-size: 2rem;
  font-weight: 700;
  text-align: left;
  color: #FFFFFF;
}

.publication_date{
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  color: #FFFFFFA3;
}

.tags{
  display: flex;
  gap: 1rem;
  width: 100%;
}

.tags_elt{
  border: 1px solid #FFFFFF1F;
  background: #FFFFFF29;
  font-size: 1rem;
  padding: 0.8rem;
  padding-bottom: 0.4rem;
  padding-top: 0.4rem;
  border-radius: 1rem;
  font-weight: 400;
  letter-spacing: -0.15134704113006592px;
  text-align: left;
  color: #FFFFFFCC;
}

.singles{
  width: 100%;
  border: 1px solid #FFFFFF1F;
  border-radius: 1rem;
  padding-top: 1rem;
}

.singles_header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
}

.singles_header_elt{
  font-size: 1rem;
  font-weight: 700;
  text-align: left;
  color: #808080;
}

.singles_list{
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.singles_elt{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.singles_elt_left{
  display: flex;
  align-items: center;
  gap: 1rem;
}

.singles_elt_right{
  display: flex;
  align-items: center;
  gap: 1rem;
}

.singles_elt_index{
  font-size: 1rem;
  font-weight: 700;
  text-align: left;
  color: #808080;
}

.singles_elt_title{
  width: 25rem;
  font-size: 1rem;
  font-weight: 400;
  text-align: left;
  color: #FFFFFF;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.singles_elt_duration{
  font-size: 0.8rem;
  font-weight: 400;
  text-align: left;
  color: #FFFFFFA3;
}

.singles_elt_add{
  width: 1rem;
  height: 1rem;
  cursor: pointer;
}

.singles_elt_more{
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
}

.singles_info{
  width: 100%;
  display: flex;
  gap: 2rem;
  row-gap: 0.2rem;
  flex-wrap: wrap;
  margin-top: -2rem;
}

.fields{
  color: white;
  font-size: 1rem;
  font-weight: 800;
}

.album_stat{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: -2rem;
}

.album_stat_title{
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: -0.15134704113006592px;
  text-align: left;
  color: #FFFFFFA3;
}

.album_stats{
  display: flex;
  gap: 2rem;
  align-items: center;
  width: 100%;
}

.album_stats_cover{
  height: 5rem;
  width: 5rem;
}


.album_stat_cover_container{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 0.2rem;
  overflow: hidden;
}

.album_stat_cover_card1{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #FFFFFF29;
  border-radius: 0.2rem;
  transform: rotate(5deg);
}

.album_stat_cover_card2{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #FFFFFF29;
  border-radius: 0.2rem;
  transform: rotate(-5deg);
}

.album_img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.album_stats_elt{
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
  height: 100%;
}

.album_stats_info1{
  font-size: 2.5rem;
  font-weight: 700;
  text-align: left;
  color: white;
}

.album_stats_info2{
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: -0.15134704113006592px;
  text-align: left;
  color: #FF5620;
}

.editCover{
  position: absolute;
  top: 1.5rem;
  left: 1.5rem;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  border: 0.99px solid #FFFFFF1F;
  background: #FFFFFF29;
  color: white;
  border-radius: 2rem;
  padding: 0.2rem;
}

.editTitle{
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  border: 0.99px solid #FFFFFF1F;
  background: #FFFFFF29;
  color: white;
  padding: 0.2rem;
  border-radius: 2rem;
}