.radio-container{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.radio-option {
    display: flex;
    align-items: center;
    /* margin-bottom: 5px; */
    cursor: pointer;
}

.radio-button {
    width: 1.3rem;
    height: 1.3rem;
    border: 0.15rem solid gray;
    border-radius: 50%;
    margin-right: 0.5rem;
    transition: background-color 0.3s ease;
}

.radio-button.selected {
    background-color: gray;
}

.radio-label{
    font-size: 1rem;
    color: gray;
}