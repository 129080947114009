.formContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.imagePreview {
  max-width: 140px;  /* Set the maximum width of the image */
  max-height: 140px;  /* Set the maximum height of the image */
  object-fit: cover;  /* Maintain aspect ratio and cover the container */
}

.formLabel {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.formInput {
  font-size: 14px;
  color: #555;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  height: 40px;
  padding-left: 10px;
}

.formBtnContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
}

.formBtn {
  width: auto;
  padding: 5px 10px;
  border-radius: 3px;
  background-color: #85134e;
  color: #fff;
}