.character-count{
    font-size: 0.8rem;
    color: gray;
    float: right;
    align-self: end;
    margin-top: -2rem;
}

/* styles/TextAreaInput.css */
textarea {
    margin: 0!important;
    resize: none;
    overflow: auto;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
}

textarea::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Edge */
}


/* ESSI's */
.text-area-holder{
    height: 7rem!important;
    justify-content: start!important;
}