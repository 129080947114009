.advitisement_card {
    border-radius: 16px;
    padding: 24px;
    gap: 32px;
    background-color: rgba(255, 255, 255, 0.12);
    color: white;
    margin: 24px 0;

    .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 16px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.12);

        @media screen and (max-width: 600px) {
            flex-wrap: wrap;
        }
        @media screen and (min-width: 991px) and (max-width: 1200px) {
            flex-wrap: wrap;
        }
        .artist {
            display: flex;

            div {
                margin-left: 15px;
                gap: 12px;


                p {
                    font-size: 16px;
                    // line-height: 18px;
                    font-weight: 400;
                }

                p:nth-child(1) {
                    font-weight: 500;
                }
            }
        }

        .status {
            font-size: 16px;
            line-height: 18px;
            font-weight: 400;
            display: flex;
            align-items: center;
            @media screen and (max-width: 600px) {
                margin-top: 20px;
                flex-wrap: wrap;
            }
            @media screen and (min-width: 991px) and (max-width: 1200px) {
                margin-top: 20px;
                flex-wrap: wrap;
            }

            span {
                margin-left: 10px;
            }

            div {
                width: 25px;
                height: 25px;
                background-color: #FF5620;
            }

        }
    }

    .middle {
        margin-top: 25px;

        aside {
            margin-top: 24px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: 10px;

            div {
                width: 24%;
                height: 100px;
                background-color: rgba(128, 128, 128, 0.562);
                padding: 16px;
                border-radius: 8px;
                overflow: hidden;

                @media screen and (max-width: 500px) {
                    width: 100%;

                }

                @media screen and (min-width: 500px) and (max-width: 702px) {
                    width: 45%;

                }

                @media screen and (min-width: 702px) and (max-width: 750px) {
                    width: 23.7%;

                }

                @media screen and (min-width: 750px) and (max-width: 807px) {
                    width: 23.8%;

                }

                @media screen and (min-width: 807px) and (max-width: 876px) {
                    width: 23.9%;

                }

                @media screen and (min-width: 990px) and (max-width: 1150px) {
                    width: 100%;

                }

                @media screen and (min-width: 1150px) and (max-width: 1288px) {
                    width: 45%;

                }

                @media screen and (min-width: 1288px) and (max-width: 1322px) {
                    width: 23.5%;

                }

                @media screen and (min-width: 1323px) and (max-width: 1365px) {
                    width: 23.6%;

                }

                @media screen and (min-width: 1365px) and (max-width: 1412px) {
                    width: 23.7%;

                }

                @media screen and (min-width: 1413px) and (max-width: 1470px) {
                    width: 23.8%;

                }

                @media screen and (min-width: 1471px) and (max-width: 1536px) {
                    width: 23.9%;

                }

                p {
                    font-size: 16px;
                    line-height: 18px;
                    font-weight: 900;
                }

                p:nth-child(2) {
                    font-size: 16px;
                    line-height: 25.6px;
                    font-weight: 400;
                    color: rgba(255, 255, 255, 0.64);
                }
            }
        }
    }

    .bottom {
        margin-top: 30px;
        display: flex;
        gap: 32px;

        @media screen and (max-width: 400px) {
            flex-direction: column;
            gap: 10px;
        }
        @media screen and (min-width: 990px) and (max-width: 1060px) {
            flex-direction: column;
            gap: 10px;
        }
    }
}