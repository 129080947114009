.uploadContainer {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image {
  width: 100%;
  height: auto;
  max-height: 350px;
  object-fit: cover;
  object-position: center;
}

.controls {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: auto;
  padding: 10px 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #000;
  border-radius: 10px;
  opacity: .8;
  z-index: 20;
}

.controlItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.controlItem:not(:last-child) {
  margin-right: 20px;
}

.controlItem > span {
  color: #fff;
  font-size: 14px;
  font-weight: bold;
}

.progressBarContainer {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 250px;
  min-height: 80px;
  height: auto;
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 15px 20px;
  z-index: 40;
}

.progressBarTitle {
  font-size: 16px;
  color: #333;
}

.progressBars {
  width: 100%;
  height: auto;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: end;
}

.progressBarItem {
  display: block;
  width: 100%;
  height: 5px;
  border-radius: 20px;
  background-color: #f3e7ed;
  overflow: hidden;
}

.progressBarContent {
  display: block;
  height: 100%;
  background-color: #85134e;
}

.progressBarPercentage {
  font-size: 12px;
  color: #333;
  margin-top: 5px;
}