.posts{
    width: 100%;
    height: 100%;
    background: black;
    display: flex;
    flex-direction: column;
    padding: 1rem;
}

.searchcontainer{
    width: 70%;
    /* min-width: 50rem; */
}

@media screen and (max-width: 990px){
    .searchcontainer{
        width: 100%;
    }
}

.maintitle{
    width: 50%;
    /* min-width: 50rem; */
    font-size: 2rem;
    font-weight: 600;
    text-align: left;
    color: white;
    margin-top: 3rem;
    padding-left: 1rem;
}
@media screen and (max-width: 990px){
    .maintitle{
        width: 100%;
    }
}
