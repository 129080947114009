.income_datas {
    margin-bottom: 50px;
    h2 {
        color: rgba(255, 255, 255, 0.8);
        width: 80%;
        margin-top: 5px;
    }

    .income {
        .income_container {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            margin: 20px 0;
            gap: 15px;
        }
    }

    .mobile-left-bar-data-5 {
        display: none;

        @media screen and (max-width: 990px) {
            display: flex;
        }
    }

    .table {
        background-color: transparent;
        width: 100%;

        h4{
            width: 95%;
            margin: 0 auto;

        }
        .table_container {
            display: flex;
            flex-direction: column;
            border: 1px solid rgba(255, 255, 255, 0.12);
            border-radius: 24px;
            padding: 24px;
            gap: 48px;
            width: 95%;
            margin: 20px auto 40px auto;

            div.head {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                border-bottom: 1px solid rgba(255, 255, 255, 0.12);

                h3 {
                    padding-bottom: 25px;

                    border-bottom: 1px solid rgba(255, 255, 255);
                }

                div.search-cont {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding-bottom: 20px;
                    margin-left: 15px;

                    @media screen and (max-width: 990px) {
                        flex-wrap: wrap;
                        margin-left: 25px;
                        
                    }
                    span.date {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        min-width: 100px;
                        border-radius: 16px;
                        padding: 8px;
                        gap: 10px;
                        background-color: rgba(255, 255, 255, 0.12);
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 18.2px;
                        color: white;
                        margin-left: 20px;
                    }
                }
            }

            table {
                color: #808080;

                tbody {
                    tr {
                        td {
                            span {
                                border: 1px solid rgba(255, 255, 255, 0.12);
                                background-color: rgba(255, 255, 255, 0.18);
                                padding: 2px 4px;
                                border-radius: 4px;
                                font-size: 10px;
                                line-height: 13.5px;
                            }
                        }
                    }
                }
            }
        }

        div.stats {
            display: flex;
            flex-wrap: wrap;
            gap: 32px;

            aside.group {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                color: white;
                gap: 32px;
                flex: 1;

                h4{
                    width: 95%;
                    margin: 0 auto;

                }
                aside {
                    border: 1px solid rgba(255, 255, 255, 0.12);
                    border-radius: 24px;
                    padding: 24px;
                    gap: 48px;
                    width: 100%;
@media screen and (max-width: 990px) {
    width: 95%;
    margin: 0 auto;
    
}
                    div.head {
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-start;
                        width: 100%;

                        div {
                            display: flex;
                            flex-direction: column;

                            p.stats {
                                font-size: 13.7px;
                                line-height: 17.5px;
                                font-weight: 400;
                                color: rgba(255, 255, 255, 0.7);
                                display: flex;
                                justify-content: flex-end;
                                text-align: right;
                            }
                        }

                        span.date {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            min-width: 100px;
                            border-radius: 16px;
                            padding: 8px;
                            gap: 10px;
                            background-color: rgba(255, 255, 255, 0.12);
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 18.2px;
                            color: white;
                            margin-left: 20px;
                        }
                    }
                    section.main-body{
                        margin-top: 35px;
                        border: 0;
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        gap: 24px;
                        section{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 100%;
                            gap: 12px;
                        }
                    }
                    section.tail{
                        margin-top: 24px;
                        border-top: 1px solid rgba(255, 255, 255, 0.12);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 10px;
                        padding-top: 20px;
                    }
                }
            }
        }
    }
}