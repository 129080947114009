.summary_card {
    width: 100%;
    min-width: 200px;
    min-height: 169px;
    border-radius: 24px;
    border: 1px solid rgba(255, 255, 255, 0.12);
    background-color: #0A0A0A;
    margin: 0 0 24px 0;
    padding: 24px;
    color: white;
    flex: 1;
    // @media screen and (min-width: 420px) and (max-width: 599px) {
    //     width: 48%;
    // }
    // @media screen and (min-width: 600px) and (max-width: 749px) {
    //     width: 31%;
    // }
    // @media screen and (min-width: 750px) and (max-width: 989px) {
    //     width: 23%;
    // }
    // @media screen and (min-width: 990px) and (max-width: 1064px) {
    //     width: 100%;
    // }
    // @media screen and (min-width: 1065px) and (max-width: 1199px) {
    //     width: 48%;
    // }
    // @media screen and (min-width: 1200px) and (max-width: 1399px) {
    //     width: 31%;
    // }
    // @media screen and (min-width: 1400px) {
    //     width: 23%;
    // }

    div {
        display: flex;

        &:nth-child(1) {
            justify-content: space-between;
            align-items: center;
        }

        span {

            font-weight: 500;
            font-size: 13.76px;
            line-height: 18.57px;
            color: rgba(255, 255, 255, 0.7);
        }
    }
    aside{
        margin: 10px 0;
        aside{
            margin-bottom: 10px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            padding: 0;
            p{
                text-align: left;
                margin: 0;
                &:nth-child(1){
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 24.3px;

                }
                &:nth-child(2){
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 16.2px;
                    color: rgba(255, 255, 255, 0.8);

                }
            }
        }

    }
    p {
        font-weight: 700;
        font-size: 43.24px;
        line-height: 58.38px;
        margin: 10px 0;
    }
}