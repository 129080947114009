.leftbar{
    position: sticky;
    height: 97vh;
    width: 370px;
    float: right;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
    z-index: 2;
    margin: auto;
    overflow: scroll;
}
@media screen and (max-width: 990px) {
    .leftbar{
        display: none;
    }
}
.leftbar::-webkit-scrollbar{
    display: none;
}

.leftbar-top{
    width: 95%;
    display: flex;
    justify-content: space-between;
}