.join-us {
    background-color: red;
    background: url('../../assets/illustrations/join_bg.png') center/cover no-repeat;
    gap: 14px;

    @media screen and (max-width: 768px) {
        background: url('../../assets/illustrations/join_bg.png') left/cover no-repeat;
    }

    h2 {
        font-weight: 700;
        font-size: 64px;
        line-height: 80px;
        color: white;
        margin-top: 6rem;
        text-align: center;

        @media screen and (max-width: 768px) {

            font-size: 32px;
            line-height: 43.2px;
        }
    }

    p {
        width: 50%;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        color: #CCCCCC;
        text-align: center;
        @media screen and (max-width: 768px) {
            width: 90%;
            font-size: 20px;
            line-height: 30px;
        }
    }

    a {
        margin-top: 3rem;
        @media screen and (max-width: 768px) {
            margin-top: 2rem;
        }
    }

    img.join{
        width: 100%;
        object-fit: fill;

    }
}