.audio_section {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.audio_section::-webkit-scrollbar{
  display: none;
}


.load_more{
  padding: 2rem;
}