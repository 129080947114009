.mobileMenuTrigger {
  display: none;
  z-index: 10;
}

.mobileMenuContainer {
  display: none;
}

.bigMenuLogo > img {
  width: 140px;
}

@media screen and (max-width: 1100px) {
  /* Big Navigation */

  .mobileMenuBody {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }

  li.linkItemBigMenu {
    display: none;
    /*z-index: 5;*/
  }

  .bigMenuLogo {
    margin-left: 5%;
    padding-top: 1em;
    display: flex;
    justify-items: center;
    align-items: baseline;
  }

  .bigMenuLogo img{
    width: 100px;

  }

  .mobileMenuTrigger {
    position: absolute;
    top: 2em;
    left: 90%;
    display: block;
    cursor: pointer;
  }

  /* Mobile Navigation */

  .mobileMenuContainer {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    display: block;
    width: 400px;
    height: 100vh;
    background-color: #fff;
    opacity: .95;
    box-shadow: 0 1px 3px #ccc;
    padding: 20px;
    z-index: 20000;
    transform: translateX(-100%);
    transition: transform .3s;
  }
  
  .mobileMenuContainerVisible {
    transform: translateX(0);
  }
  
  .mobileMenuContainerBackground {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
    opacity: .4;
    z-index: 15;
  }
  
  .mobileMenuHeader {
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: start;
  }
  
  .mobileMenuLogo {
    width: 100px;
    height: auto;
    margin-top: 1em;
    margin-left: 1em;
  }
  
  .mobileMenuClose {
    position: absolute;
    right: 10px;
    font-size: 30px;
    cursor: pointer;
  }
  
  .mobileMenuBody {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    margin-top: 30px;
  }
  
  .mobileMenuItemContainer {
    text-decoration: none;
    color: #222;
  }
  
  .mobileMenuItem {
    width: 100%;
    height: auto;
    padding-block: 15px;
    padding-left: 20px;
    transition: all .3s;
    margin-bottom: 3px;
  }
  
  .mobileMenuItem:hover {
    background-color: #ffecce;
    cursor: pointer;
  }
  
  .mobileMenuItem > span {
    font-size: 18px;
    transform: translateX(0);
    transition: all .3s;
  }
  
  .mobileMenuItemActive {
    background-color: #ffecce;
    border-left: 7px solid #FF2447;
  }
  
  .mobileMenuItemActive > span {
    transform: translateX(15px);
  }
}

@media screen and (max-width: 500px) {

  .bigMenuLogo{
    margin-left: 1em;
  }


  .mobileMenuTrigger{
    position: relative;
    top: -1.2em;
    left: 99%;
  }

  .mobileMenuContainer {
    width: 100%;
  }

  .listMenu {
    right: 0;
    padding-top: 12px;
  }

  .listMenu > li {
    margin-bottom: 10px;
  }

  .bigMenuLogo > img {
    width: 100px;
    margin-top: 10px;
  }
}

@media screen and (max-width: 390px) {
  .mobileMenuTrigger{
    position: relative;
    top: -1.5em;
    left: 99%;
  }
}